import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import { BigNumber, Contract } from 'ethers'
import { useEffect, useMemo } from 'react'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import { captureException } from '@sentry/react'

export function useVaultRepoTokenHoldings(
  chainId: string,
  strategyAddress: Address,
  repoTokenHoldingsWithoutValue:
    | { repoToken: Address; presentValue: BigNumber | undefined }[]
    | null
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  repoTokenHoldingsWithValue:
    | { repoToken: Address; presentValue: BigNumber }[]
    | null
    | undefined
} {
  const strategyContract = useMemo(() => {
    return new Contract(
      strategyAddress,
      TermVaultStrategyABI,
      provider
    ) as TermVaultStrategy
  }, [strategyAddress, provider])

  const vaultContractCalls: MultichainCalls = {}

  vaultContractCalls[chainId] = useMemo(
    () =>
      repoTokenHoldingsWithoutValue
        ? repoTokenHoldingsWithoutValue.map((repoToken) => ({
            contract: strategyContract,
            method: 'getRepoTokenHoldingValue',
            args: [repoToken.repoToken],
          }))
        : [],
    [repoTokenHoldingsWithoutValue, strategyContract]
  )

  const blockchainResponse = useMultichainCalls(vaultContractCalls)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(blockchainResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [blockchainResponse])

  const blockchainErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResponse) {
      Object.entries(blockchainResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResponse])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const repoTokenHoldingsWithValue = useMemo(() => {
    if (!blockchainResults || !repoTokenHoldingsWithoutValue) return undefined

    const updatedHoldings = repoTokenHoldingsWithoutValue.map(
      (holding, index) => {
        const tokenValue = Object.values(blockchainResults)?.[0]?.[index]?.[0]
        const presentValue = BigNumber.from(tokenValue ?? 0)
        return {
          ...holding,
          presentValue,
        }
      }
    )

    return updatedHoldings
  }, [blockchainResults, repoTokenHoldingsWithoutValue])

  return {
    repoTokenHoldingsWithValue,
  }
}
