import { Box, Button, Flex, Heading, HStack } from '@chakra-ui/react'
import { BigNumber, FixedNumber } from 'ethers'
import MetaVaultPageLoading from './elements/MetaVaultPageLoading'
import VaultDepositWithdraw from '../Vault/elements/VaultDepositWithdraw'
import MetaVaultStrategyAllocation from '../Vault/elements/MetaVaultStrategyAllocation'
import { Trans } from '@lingui/macro'
import VaultCard from '../Vaults/elements/VaultCard'
import VaultsTableEmpty from '../Vaults/elements/VaultsTableEmpty'
import MetaVaultBanner from './elements/MetaVaultBanner'
import { MetaVaultData, VaultData } from '../../models/vaults'
import {
  HistoricalPerformanceDataChart,
  MetaVaultPageParams,
} from '../../models/metaVault'
import HistoricalPerformanceChart from './elements/HistoricalPerformanceChart'
import {
  Address,
  Currency,
  NativeCurrency,
  VaultPricePerShareMap,
  MetaVaultStrategyAllocations,
} from '../../data/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

export default function MetaVaultPage({
  isDataLoaded,
  account,
  chainId,
  metaVaultDetails,
  individualVaultDetails,
  metaVaultStrategyAllocations,
  totalDeposited,
  connectedWalletDeposits,
  singleVaultPricePerShareMap,
  metaVaultPricePerShare,
  metaVaultConvertToAssetsRatio,
  metaVaultCurrentYield,
  connectedWalletAssetAllowance,
  metaVaultAssetPrice,
  metaVaultReceiptCurrency,
  connectedWalletMetaVaultReceiptBalance,
  connectedWalletAssetBalance,
  gasTokenBalance,
  gasTokenCurrency,
  availableDepositLimit,
  availableWithdrawLimit,
  isShutdown,
  hasHistoricalPerformance,
  historicalPerformanceDataChart,
  setSelectedTime,
  selectedTime,
  onConnect,
  onKytCheck,
  onApproveMetaVaultAsset,
  onMetaVaultDeposit,
  onMetaVaultWithdraw,
  onWrapGasToken,
  onViewSingleVault,
  onViewVaults,
}: MetaVaultPageParams) {
  if (
    !isDataLoaded ||
    metaVaultDetails === undefined ||
    individualVaultDetails === undefined ||
    metaVaultStrategyAllocations === undefined ||
    totalDeposited === undefined ||
    connectedWalletDeposits === undefined ||
    singleVaultPricePerShareMap === undefined ||
    metaVaultPricePerShare === undefined ||
    metaVaultConvertToAssetsRatio === undefined ||
    metaVaultCurrentYield === undefined ||
    connectedWalletAssetAllowance === undefined ||
    connectedWalletMetaVaultReceiptBalance === undefined ||
    connectedWalletAssetBalance === undefined ||
    connectedWalletAssetAllowance === undefined ||
    metaVaultAssetPrice === undefined ||
    metaVaultReceiptCurrency === undefined ||
    hasHistoricalPerformance === undefined ||
    historicalPerformanceDataChart === undefined ||
    onApproveMetaVaultAsset === undefined ||
    onMetaVaultDeposit === undefined ||
    onMetaVaultWithdraw === undefined ||
    onViewSingleVault === undefined
  ) {
    return <MetaVaultPageLoading onViewVaults={onViewVaults} />
  }

  return (
    <ParameterizedMetaVaultPage
      account={account}
      chainId={chainId}
      metaVaultDetails={metaVaultDetails}
      individualVaultDetails={individualVaultDetails}
      metaVaultStrategyAllocations={metaVaultStrategyAllocations}
      totalDeposited={totalDeposited}
      connectedWalletDeposits={connectedWalletDeposits}
      singleVaultPricePerShareMap={singleVaultPricePerShareMap}
      metaVaultPricePerShare={metaVaultPricePerShare}
      metaVaultConvertToAssetRatio={metaVaultConvertToAssetsRatio}
      metaVaultCurrentYield={metaVaultCurrentYield}
      connectedWalletAssetAllowance={connectedWalletAssetAllowance}
      metaVaultAssetPrice={metaVaultAssetPrice}
      metaVaultReceiptCurrency={metaVaultReceiptCurrency}
      connectedWalletMetaVaultReceiptBalance={
        connectedWalletMetaVaultReceiptBalance
      }
      connectedWalletAssetBalance={connectedWalletAssetBalance}
      gasTokenBalance={gasTokenBalance}
      gasTokenCurrency={gasTokenCurrency}
      availableDepositLimit={availableDepositLimit}
      availableWithdrawLimit={availableWithdrawLimit}
      isShutdown={isShutdown}
      hasHistoricalPerformance={hasHistoricalPerformance}
      historicalPerformanceDataChart={historicalPerformanceDataChart}
      selectedTime={selectedTime}
      setSelectedTime={setSelectedTime}
      onConnect={onConnect}
      onKytCheck={onKytCheck}
      onApproveMetaVaultAsset={onApproveMetaVaultAsset}
      onMetaVaultDeposit={onMetaVaultDeposit}
      onMetaVaultWithdraw={onMetaVaultWithdraw}
      onWrapGasToken={onWrapGasToken}
      onViewSingleVault={onViewSingleVault}
      onViewVaults={onViewVaults}
    />
  )
}

export function ParameterizedMetaVaultPage({
  account,
  chainId,
  metaVaultDetails,
  individualVaultDetails,
  metaVaultStrategyAllocations,
  totalDeposited,
  connectedWalletDeposits,
  singleVaultPricePerShareMap,
  metaVaultPricePerShare,
  metaVaultConvertToAssetRatio,
  metaVaultCurrentYield,
  connectedWalletAssetAllowance,
  metaVaultAssetPrice,
  metaVaultReceiptCurrency,
  connectedWalletMetaVaultReceiptBalance,
  connectedWalletAssetBalance,
  gasTokenBalance,
  gasTokenCurrency,
  availableDepositLimit,
  availableWithdrawLimit,
  isShutdown,
  hasHistoricalPerformance,
  historicalPerformanceDataChart,
  setSelectedTime,
  selectedTime,
  onConnect,
  onKytCheck,
  onApproveMetaVaultAsset,
  onMetaVaultDeposit,
  onMetaVaultWithdraw,
  onWrapGasToken,
  onViewSingleVault,
  onViewVaults,
}: {
  account?: Address
  chainId: string

  // Vault Details
  metaVaultDetails: MetaVaultData
  individualVaultDetails: VaultData[]
  metaVaultStrategyAllocations: MetaVaultStrategyAllocations

  // Pricing and Performance
  totalDeposited: FixedNumber
  connectedWalletDeposits: FixedNumber
  singleVaultPricePerShareMap: {
    [chainId: string]: VaultPricePerShareMap
  }
  metaVaultPricePerShare: FixedNumber
  metaVaultConvertToAssetRatio: FixedNumber
  metaVaultCurrentYield: FixedNumber
  // Wallet and Asset Information
  connectedWalletAssetAllowance: BigNumber
  metaVaultAssetPrice: FixedNumber
  metaVaultReceiptCurrency: Currency
  connectedWalletMetaVaultReceiptBalance: FixedNumber
  connectedWalletAssetBalance: FixedNumber
  gasTokenBalance?: FixedNumber
  gasTokenCurrency: NativeCurrency
  availableDepositLimit: FixedNumber
  availableWithdrawLimit: FixedNumber

  // User Interaction Flags
  isShutdown: boolean

  // Historical Performance (Optional)
  hasHistoricalPerformance: boolean
  historicalPerformanceDataChart: HistoricalPerformanceDataChart[]
  selectedTime: number | undefined
  setSelectedTime: (value: number) => void | undefined

  // Actions
  onConnect: () => void
  onKytCheck: () => Promise<boolean>
  onMetaVaultDeposit: (amount: string) => Promise<void>
  onMetaVaultWithdraw: (amount: string) => Promise<void>
  onWrapGasToken: (value: BigNumber) => Promise<void>
  onApproveMetaVaultAsset: (amount?: string) => Promise<void>
  onViewSingleVault: (vaultAddress: string, chainId: string) => void
  onViewVaults: () => void
}) {
  const navigate = useNavigate()
  return (
    <Box maxW="1400px" px={{ base: 8, xl: 10 }} pt={4} mx="auto">
      <HStack mb={4}>
        <Button
          variant="body-xs/medium"
          height="auto"
          p={0}
          rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
          onClick={onViewVaults}
        >
          <Trans>Vaults</Trans>
        </Button>
        <Button variant="body-xs/medium" height="auto" p={0} color="gray.6">
          {' '}
          {metaVaultDetails.vaultName}{' '}
        </Button>
      </HStack>
      <MetaVaultBanner
        chainId={chainId}
        metaVaultAddress={metaVaultDetails.address}
        metaVaultName={metaVaultDetails.vaultName}
        currentYield={metaVaultCurrentYield}
        pricePerShare={metaVaultPricePerShare}
        totalDeposited={totalDeposited}
        connectedWalletDeposits={connectedWalletDeposits}
        vaultAssetCurrency={metaVaultDetails.purchaseCurrency}
        vaultReceiptCurrency={metaVaultReceiptCurrency}
        vaultReceiptBalance={connectedWalletMetaVaultReceiptBalance}
        metaVaultIcon=""
      />

      <HStack
        mt={6}
        spacing={6}
        alignItems="start"
        maxW="full"
        overflow="hidden"
      >
        {hasHistoricalPerformance && (
          <HistoricalPerformanceChart
            metaVaultAssetCurrencySymbol={
              metaVaultDetails.purchaseCurrency.symbol
            }
            selectedTime={selectedTime}
            dataChart={historicalPerformanceDataChart || []}
            setSelectedTime={setSelectedTime}
            currentYield={metaVaultCurrentYield ?? FixedNumber.fromString('0')}
          />
        )}
        <Box
          borderRadius={'8px'}
          p={6}
          bg="#fff"
          h="330px"
          w={hasHistoricalPerformance ? '33.33%' : '50%'}
          flexBasis={hasHistoricalPerformance ? '1/3' : '50%'}
        >
          <MetaVaultStrategyAllocation
            holdings={metaVaultStrategyAllocations}
          />
        </Box>
        <Box
          borderRadius={'8px'}
          p={6}
          minHeight="330px"
          bg="#fff"
          w={hasHistoricalPerformance ? 'auto' : '50%'}
          flexBasis={hasHistoricalPerformance ? '1/3' : '50%'}
        >
          <VaultDepositWithdraw
            isDepositPaused={false}
            account={account}
            convertToAssetsRatio={metaVaultConvertToAssetRatio}
            availableDepositLimit={availableDepositLimit}
            availableWithdrawLimit={availableWithdrawLimit}
            vaultAssetCurrency={metaVaultDetails.purchaseCurrency}
            vaultAssetBalance={connectedWalletAssetBalance}
            vaultReceiptCurrency={metaVaultReceiptCurrency}
            vaultReceiptBalance={connectedWalletMetaVaultReceiptBalance}
            vaultAssetAllowance={connectedWalletAssetAllowance}
            vaultAssetPrice={metaVaultAssetPrice}
            gasTokenBalance={
              gasTokenBalance ?? FixedNumber.fromString('0', `fixed128x18`)
            }
            gasTokenCurrency={gasTokenCurrency}
            onConnect={onConnect}
            onWrapGasToken={onWrapGasToken}
            onVaultAssetApprove={onApproveMetaVaultAsset}
            onKytCheck={onKytCheck}
            onDeposit={onMetaVaultDeposit}
            onWithdraw={onMetaVaultWithdraw}
          />
        </Box>
      </HStack>
      <Heading
        variant="heading/h1"
        pt={6}
        mt={6}
        borderTop="1px solid #0000001A"
      >
        <Trans>Strategies</Trans>
      </Heading>

      <Flex direction="column" gap={5} mt={6}>
        {individualVaultDetails.length > 0 ? (
          individualVaultDetails.map((vault: VaultData) => (
            <VaultCard
              isMetaVaultView
              key={vault.address}
              vault={vault}
              onViewVault={onViewSingleVault}
            />
          ))
        ) : (
          <Flex pt={10}>
            <VaultsTableEmpty />
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
