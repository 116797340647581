import { HStack, Box, Text, Progress } from '@chakra-ui/react'

type Props = {
  token: string
  holdingPercent: number
  color?: string
}

export default function CollateralHoldingBar({
  token,
  holdingPercent,
  color,
}: Props) {
  return (
    <Box w="full" px="24px">
      <HStack spacing="0" w="full">
        <Text w="full" variant="body-sm/normal" color="blue.9">
          {token}
        </Text>
        <Text variant="body-xs/medium" color="gray.6">
          {holdingPercent.toFixed(2)}%
        </Text>
      </HStack>
      <Progress
        size="xs"
        borderRadius="50px"
        mt="10px"
        w="full"
        h="4px"
        bg="gray.3"
        value={holdingPercent}
        sx={{
          '& > div': {
            backgroundColor: color,
          },
        }}
      />
    </Box>
  )
}
