import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import { Contract, FixedNumber, Signer } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import { captureException } from '@sentry/react'
import { TransactionStatus, useSendTransaction } from '@usedapp/core'
import { bigToFixedNumber, fixedToBigNumber } from '../../helpers/conversions'
import { waitForStatus } from '../../helpers/wait'

export function useVaults(
  account: Address | undefined,
  singleStrategyAddressesByChain: { [chainId: string]: Address[] } | undefined,
  assetDecimals: { [chainId: string]: number[] | undefined } | undefined,
  signer: Signer | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  readFromSubgraph: () => void
): {
  // current price per share
  currentPricePerShare: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  }
  // total asset value per vault
  totalAssetValue: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  }
  // deposit limit per vault for current user
  availableDepositLimit: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  }
  // withdraw limit per vault for current user
  availableWithdrawLimit: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  }
  // deposit into vault
  deposit: (
    chainId: string,
    strategyAddress: Address,
    amount: FixedNumber
  ) => Promise<void>
  // withdraw from vault
  withdraw: (
    chainId: string,
    strategyAddress: Address,
    amount: FixedNumber,
    maxLoss?: FixedNumber
  ) => Promise<void>
} {
  const validateActiveNetwork = useCallback(
    async (chainId: number) => {
      const activeNetwork = await signer?.provider?.getNetwork()
      return (
        activeNetwork?.chainId &&
        activeNetwork.chainId.toString() === chainId.toString()
      )
    },
    [signer]
  )

  const transactionStates = useMemo(
    () =>
      ({}) as {
        deposit: TransactionStatus | undefined
        withdraw: TransactionStatus | undefined
      },
    []
  )
  const {
    sendTransaction: depositTransaction,
    state: depositState,
    resetState: resetDepositState,
  } = useSendTransaction()

  const {
    sendTransaction: withdrawTransaction,
    state: withdrawState,
    resetState: resetWithdrawState,
  } = useSendTransaction()

  useEffect(() => {
    transactionStates.deposit = depositState
  }, [depositState, transactionStates])
  useEffect(() => {
    transactionStates.withdraw = withdrawState
  }, [withdrawState, transactionStates])

  const strategyContracts = useMemo(() => {
    const contracts: {
      [chainId: string]: {
        [strategyAddress: Address]: TermVaultStrategy
      }
    } = {}

    if (singleStrategyAddressesByChain) {
      for (const [chainId, strategyAddressses] of Object.entries(
        singleStrategyAddressesByChain
      )) {
        if (!strategyAddressses) continue

        contracts[chainId] = {}

        for (const strategyAddress of strategyAddressses) {
          if (!strategyAddress || contracts[chainId][strategyAddress]) {
            continue
          }

          contracts[chainId][strategyAddress] = new Contract(
            strategyAddress,
            TermVaultStrategyABI,
            provider
          ) as TermVaultStrategy
        }
      }
    }

    return contracts
  }, [singleStrategyAddressesByChain, provider])

  // Read from blockchain

  // #region

  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (strategyContracts) {
      Object.entries(strategyContracts).forEach(
        ([chainId, contractsByAddress]) => {
          const contracts = Object.values(contractsByAddress) // Use contracts directly from (A)

          if (contracts.length > 0) {
            const pricePerShareCalls = contracts.map((contract) => ({
              contract,
              method: 'pricePerShare',
              args: [],
            }))
            const totalAssetValueCalls = contracts.map((contract) => ({
              contract,
              method: 'totalAssetValue',
              args: [],
            }))

            const availableDepositLimitCalls = account
              ? contracts.map((contract) => ({
                  contract,
                  method: 'availableDepositLimit',
                  args: [account],
                }))
              : []

            const availableWithdrawLimitCalls = account
              ? contracts.map((contract) => ({
                  contract,
                  method: 'availableWithdrawLimit',
                  args: [account],
                }))
              : []

            calls[chainId] = [
              ...pricePerShareCalls,
              ...totalAssetValueCalls,
              ...availableDepositLimitCalls,
              ...availableWithdrawLimitCalls,
            ]
          }
        }
      )
    }

    return calls
  }, [strategyContracts, account])

  const blockchainResponse = useMultichainCalls(contractCalls)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(blockchainResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [blockchainResponse])

  const blockchainErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResponse) {
      Object.entries(blockchainResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResponse])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const currentPricePerShare = useMemo(() => {
    const result: {
      [chainId: string]: {
        [strategyAddress: Address]: FixedNumber | undefined | null
      }
    } = {}

    if (blockchainResults && singleStrategyAddressesByChain) {
      Object.entries(blockchainResults).forEach(([chainId, values]) => {
        result[chainId] = {}

        const pricePerShareValues = values.slice(
          0,
          Object.values(singleStrategyAddressesByChain[chainId]).length
        )
        pricePerShareValues.forEach((value, index) => {
          const strategyAddress =
            singleStrategyAddressesByChain?.[chainId]?.[index]
          const decimals = assetDecimals?.[chainId]?.[index]

          if (strategyAddress) {
            result[chainId][strategyAddress] = value
              ? bigToFixedNumber(value?.[0], decimals ?? 18)
              : null
          }
        })
      })
    }

    return result
  }, [blockchainResults, singleStrategyAddressesByChain, assetDecimals])

  const totalAssetValue = useMemo(() => {
    const result: {
      [chainId: string]: {
        [strategyAddress: Address]: FixedNumber | undefined | null
      }
    } = {}

    if (blockchainResults && singleStrategyAddressesByChain) {
      Object.entries(blockchainResults).forEach(([chainId, values]) => {
        result[chainId] = {}

        const totalAssetValueValues = values.slice(
          Object.values(singleStrategyAddressesByChain[chainId]).length,
          Object.values(singleStrategyAddressesByChain[chainId]).length * 2
        )
        totalAssetValueValues.forEach((value, index) => {
          const strategyAddress =
            singleStrategyAddressesByChain?.[chainId]?.[index]
          const decimals = assetDecimals?.[chainId]?.[index]

          if (strategyAddress) {
            result[chainId][strategyAddress] = value
              ? bigToFixedNumber(value?.[0], decimals ?? 18)
              : null
          }
        })
      })
    }

    return result
  }, [blockchainResults, singleStrategyAddressesByChain, assetDecimals])

  const availableDepositLimit = useMemo(() => {
    const result: {
      [chainId: string]: {
        [strategyAddress: Address]: FixedNumber | undefined | null
      }
    } = {}

    if (blockchainResults && singleStrategyAddressesByChain) {
      Object.entries(blockchainResults).forEach(([chainId, values]) => {
        result[chainId] = {}

        const availableDepositLimitValues = account
          ? values.slice(
              Object.values(singleStrategyAddressesByChain[chainId]).length * 2,
              Object.values(singleStrategyAddressesByChain[chainId]).length * 3
            )
          : []
        availableDepositLimitValues.forEach((value, index) => {
          const strategyAddress =
            singleStrategyAddressesByChain?.[chainId]?.[index]
          const decimals = assetDecimals?.[chainId]?.[index]

          if (strategyAddress) {
            result[chainId][strategyAddress] = value
              ? bigToFixedNumber(value?.[0], decimals ?? 18)
              : null
          }
        })
      })
    }

    return result
  }, [
    blockchainResults,
    singleStrategyAddressesByChain,
    account,
    assetDecimals,
  ])

  const availableWithdrawLimit = useMemo(() => {
    const result: {
      [chainId: string]: {
        [strategyAddress: Address]: FixedNumber | undefined | null
      }
    } = {}

    if (blockchainResults && singleStrategyAddressesByChain) {
      Object.entries(blockchainResults).forEach(([chainId, values]) => {
        result[chainId] = {}

        const availableWithdrawLimitValues = account
          ? values.slice(
              Object.values(singleStrategyAddressesByChain[chainId]).length * 3,
              Object.values(singleStrategyAddressesByChain[chainId]).length * 4
            )
          : []
        availableWithdrawLimitValues.forEach((value, index) => {
          const strategyAddress =
            singleStrategyAddressesByChain?.[chainId]?.[index]
          const decimals = assetDecimals?.[chainId]?.[index]

          if (strategyAddress) {
            result[chainId][strategyAddress] = value
              ? bigToFixedNumber(value?.[0], decimals ?? 18)
              : null
          }
        })
      })
    }

    return result
  }, [
    blockchainResults,
    singleStrategyAddressesByChain,
    account,
    assetDecimals,
  ])

  // #endregion

  // Write to blockchain

  // #region

  const depositIntoVault = useCallback(
    async (chainId: string, strategyAddress: Address, amount: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (!strategyContracts?.[chainId]?.[strategyAddress]) {
        throw new Error(`strategy contract not found for ${strategyAddress}`)
      }

      const strategyContract = strategyContracts[chainId][strategyAddress]

      console.info(
        'depositing %o tokens into single strategy vault %o',
        amount.toString(),
        strategyAddress
      )

      const data = strategyContract.interface.encodeFunctionData('deposit', [
        fixedToBigNumber(amount),
        account,
      ])

      await depositTransaction({
        chainId: parsedChainId,
        to: strategyAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.deposit,
        resetDepositState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyContracts,
      depositTransaction,
      resetDepositState,
      readFromSubgraph,
      transactionStates.deposit,
    ]
  )

  const withdrawFromVault = useCallback(
    async (
      chainId: string,
      strategyAddress: Address,
      amount: FixedNumber,
      maxLoss?: FixedNumber
    ) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (!strategyContracts?.[chainId]?.[strategyAddress]) {
        throw new Error(`strategy contract not found for ${strategyAddress}`)
      }

      const strategyContract = strategyContracts[chainId][strategyAddress]

      console.info(
        'withdrawing %o shares from single strategy vault %o',
        amount.toString(),
        strategyAddress
      )

      let data: string
      if (maxLoss) {
        console.info('maxLoss set to be %o', maxLoss.toString())
        data = strategyContract.interface.encodeFunctionData(
          'withdraw(uint256,address,address,uint256)',
          [
            fixedToBigNumber(amount),
            account,
            account,
            fixedToBigNumber(maxLoss),
          ]
        )
      } else {
        data = strategyContract.interface.encodeFunctionData(
          'withdraw(uint256,address,address)',
          [fixedToBigNumber(amount), account, account]
        )
      }

      await withdrawTransaction({
        chainId: parsedChainId,
        to: strategyAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.withdraw,
        resetWithdrawState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      withdrawTransaction,
      resetWithdrawState,
      readFromSubgraph,
      strategyContracts,
      transactionStates.withdraw,
    ]
  )

  // #endregion

  return {
    currentPricePerShare,
    totalAssetValue,
    availableDepositLimit,
    availableWithdrawLimit,
    deposit: depositIntoVault,
    withdraw: withdrawFromVault,
  }
}
