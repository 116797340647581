import { Image, Flex, Box, Text } from '@chakra-ui/react'

import etherfiLogo from '../../../assets/icons/projects/etherfi.svg'
import renzoLogo from '../../../assets/icons/projects/renzo.svg'
import ethenaLogo from '../../../assets/icons/projects/ethena.svg'
import elixirLogo from '../../../assets/icons/projects/elixir.svg'
import treehouseLogo from '../../../assets/icons/projects/treehouse-nuts.svg'
import lombardLogo from '../../../assets/icons/projects/lombard.svg'

import { ExternalProject } from '../../../data/model'

type ProjectInfo = {
  icon: any
  border: string
  bg: string
  points: string
  reward?: string
  width?: number
}

const CONFIG: Record<ExternalProject, ProjectInfo> = {
  renzo: {
    icon: renzoLogo,
    border: '1px solid #C0FD62',
    bg: '#E8F2E8',
    points: '1x Renzo',
  },
  etherfi: {
    icon: etherfiLogo,
    border: '1px solid #B35BFA',
    bg: '#fff',
    points: '1x EtherFi',
  },
  ethena: {
    icon: ethenaLogo,
    border: '1px solid #CAD5E4',
    bg: '#fff',
    points: '1x Ethena',
    reward: 'sats',
    width: 208,
  },
  elixir: {
    icon: elixirLogo,
    border: '1px solid #CAD5E4',
    bg: '#fff',
    points: '1.7x Elixir',
    reward: 'potions',
    width: 225,
  },
  treehouse: {
    icon: treehouseLogo,
    border: '1px solid #1AE5BE',
    bg: '#fff',
    points: '10x Treehouse Nuts',
    reward: 'per ETH per day',
    width: 330,
  },
  lombard: {
    icon: lombardLogo,
    border: '1px solid #63C9B9',
    bg: '#fff',
    points: '2x Lombard LUX',
    width: 250,
  },
}

type Props = {
  project: ExternalProject
}

const ExternalProjectPoints = ({ project }: Props) => {
  const config = CONFIG[project]

  const w = config?.width ?? 215

  return (
    <Box role="group" w={`${w}px`}>
      <Box
        h="32px"
        w={`${w}px`}
        border="1px solid transparent"
        position="relative"
        cursor="pointer"
        padding="4px 8px 4px 4px"
        borderRadius="28px"
        _groupHover={{ bg: config.bg, border: config.border }}
      >
        <Flex
          h="32px"
          w="32px"
          bg="#F2F2F2"
          border="1px solid gray.2"
          borderRadius="28px"
          position="absolute"
          right={0}
          bottom={'-1px'}
          transition="transform 0.3s, border 0.3s"
          transform="translateX(0)"
          _groupHover={{
            transform: `translateX(-${w - 34}px)`,
            border: 'none',
            bg: 'transparent',
          }}
        >
          <Image
            src={config.icon}
            m="auto"
            h="24px"
            opacity={0.5}
            transition="opacity 0.3s"
            _groupHover={{ opacity: 1 }}
          />
        </Flex>
        <Text
          variant="body-xs/medium"
          color="gray.5.5"
          opacity={0}
          lineHeight="22px"
          textAlign="right"
          transition="opacity 0.3s"
          _groupHover={{ opacity: 1 }}
        >
          <Text as="span">Lenders earn</Text>{' '}
          <Text as="span" color="blue.9">
            {config.points}
          </Text>{' '}
          <Text as="span">{config.reward ?? 'points'}</Text>
        </Text>
      </Box>
    </Box>
  )
}

export default ExternalProjectPoints
