import { Heading, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

export const DescriptionDepositWithdraw = () => {
  return (
    <VStack maxW="532px" flexBasis="50%" spacing={8} alignItems="flex-start">
      <Heading variant="heading/h1">
        <Trans>Make a deposit or withdrawal</Trans>
      </Heading>
      <Text fontSize="20px" lineHeight="24.2px">
        <Trans>
          Connect your wallet and deposit funds into Meta Vaults or individual
          Strategy Vaults. Different vaults are based on different assets. To
          learn more about our different vaults see the tables below.
        </Trans>
      </Text>
    </VStack>
  )
}
