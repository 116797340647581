import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { BigNumber, FixedNumber } from 'ethers'
import {
  TermRepoTokenHoldingsQuery,
  VaultOpenOffersQuery,
} from '../../gql/graphql'
import { PageVaultQuery } from '../../gql/vaults/graphql'
import { VaultHolding } from '../../models/vault'
import { Address, Currency } from '../model'
import { useMemo } from 'react'
import { useVaultRepoTokenHoldings } from './use-vault-repo-token-holdings'
import { useBalances } from './use-balances'
import { add, divide, fixedCompare, multiply } from '../../helpers/math'
import { bigToFixedNumber } from '../../helpers/conversions'

// TODO: add unit test for this hook

export function useVaultHoldings(
  chainId: string,
  strategyAddress: Address,
  auctionOffersSubgraphData: VaultOpenOffersQuery | undefined,
  vaultSubgraphData: PageVaultQuery | undefined,
  termRepoHoldingsSubgraphData: TermRepoTokenHoldingsQuery | undefined,
  repoTokenHoldingsWithoutValue:
    | {
        repoToken: Address
        presentValue: BigNumber | undefined
      }[]
    | null
    | undefined,
  vaultAssetPrice: FixedNumber | undefined,
  totalAssetValue: FixedNumber | null | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  repoTokenHoldings?: VaultHolding[]
  openAuctionOffers?: VaultHolding[]
  largestHolding: FixedNumber
} {
  const repoTokenCurrenciesByChain = useMemo(() => {
    if (!termRepoHoldingsSubgraphData) {
      return undefined
    }
    const mappedRepoTokenCurrencies =
      termRepoHoldingsSubgraphData?.termRepos.reduce(
        (acc, repo) => {
          acc[repo.termRepoToken] = {
            address: repo.termRepoToken,
            symbol: repo.termRepoTokenMeta?.symbol ?? '',
            decimals: repo.termRepoTokenMeta?.decimals ?? 18,
            isRepoToken: true,
          }
          return acc
        },
        {} as { [address: string]: Currency }
      )
    return {
      [chainId]: mappedRepoTokenCurrencies,
    }
  }, [chainId, termRepoHoldingsSubgraphData])

  const { repoTokenHoldingsWithValue } = useVaultRepoTokenHoldings(
    chainId,
    strategyAddress,
    repoTokenHoldingsWithoutValue,
    provider
  )

  const repoTokenBalancesData = useBalances(
    strategyAddress,
    repoTokenCurrenciesByChain,
    provider
  )

  const { repoTokenHoldings, openAuctionOffers, largestHolding } =
    useMemo(() => {
      // Early return if required data is missing
      if (
        !auctionOffersSubgraphData ||
        !repoTokenHoldingsWithValue ||
        !vaultSubgraphData?.termVaultStrategy ||
        !termRepoHoldingsSubgraphData?.termRepos ||
        !vaultAssetPrice ||
        !repoTokenBalancesData ||
        !totalAssetValue
      ) {
        return {
          repoTokenHoldings: undefined,
          openAuctionOffers: undefined,
          largestHolding: FixedNumber.fromString('0', `fixed128x18`),
        }
      }

      // group open auction offers by repo token address
      const openOffersMap: Record<string, VaultHolding> = {}

      auctionOffersSubgraphData.termOffers?.forEach((offer) => {
        const repoTokenId = offer.auction.term.termRepoTokenMeta?.id ?? ''
        if (!repoTokenId) return

        if (!openOffersMap[repoTokenId]) {
          openOffersMap[repoTokenId] = {
            isOpenOffer: true,
            repoToken: repoTokenId,
            repoTokenCurrency: {
              address: repoTokenId,
              symbol: offer.auction.term.termRepoTokenMeta?.symbol ?? '',
              decimals: offer.auction.term.termRepoTokenMeta?.decimals ?? 18,
              isRepoToken: true,
            },
            faceValue: FixedNumber.fromString('0', `fixed128x18`),
            presentValue: FixedNumber.fromString('0', `fixed128x18`),
            presentValueUsd: FixedNumber.fromString('0', `fixed128x18`),
            repurchaseTimestamp: offer.auction.term.repurchaseTimestamp,
            redemptionTimestamp: offer.auction.term.redemptionTimestamp,
            collateralTokens:
              offer.auction.term.collateralTokensMeta?.map((c) => ({
                address: c.id,
                symbol: c.symbol,
                decimals: c.decimals,
                isRepoToken: false,
              })) ?? [],
            auctionClearingPrice: FixedNumber.fromString('0', `fixed128x18`),
            distributionRatio: FixedNumber.fromString('0', `fixed128x18`),
          }
        }

        const decimals = offer.auction.term.purchaseTokenMeta?.decimals ?? 18
        openOffersMap[repoTokenId].presentValue = add(
          openOffersMap[repoTokenId].presentValue,
          bigToFixedNumber(offer.amount, decimals)
        )
        openOffersMap[repoTokenId].faceValue = add(
          openOffersMap[repoTokenId].faceValue,
          bigToFixedNumber(offer.amount, decimals)
        )
      })

      // create holding map for repo tokens
      const holdingsMap: Record<string, VaultHolding> = {}

      // create lookup for termRepos
      const termRepoMap = termRepoHoldingsSubgraphData.termRepos.reduce(
        (acc, repo) => {
          acc[repo.termRepoToken.toLowerCase()] = repo
          return acc
        },
        {} as Record<string, TermRepoTokenHoldingsQuery['termRepos'][number]>
      )

      repoTokenHoldingsWithValue.forEach((rawHolding, idx) => {
        const tokenAddress = rawHolding.repoToken.toLowerCase()
        const termRepo = termRepoMap[tokenAddress]

        const decimals = termRepo?.termRepoTokenMeta?.decimals ?? 18

        holdingsMap[tokenAddress] = {
          isOpenOffer: false,
          repoToken: tokenAddress,
          repoTokenCurrency: {
            address: tokenAddress,
            symbol: termRepo?.termRepoTokenMeta?.symbol ?? '',
            decimals,
            isRepoToken: true,
          },
          presentValue: bigToFixedNumber(rawHolding.presentValue, decimals),
          faceValue: FixedNumber.fromString('0', `fixed128x${decimals}`), // updated below if we have on-chain balance
          presentValueUsd: FixedNumber.fromString('0', `fixed128x18`),
          repurchaseTimestamp: Number(termRepo?.repurchaseTimestamp),
          redemptionTimestamp: Number(termRepo?.redemptionTimestamp),
          collateralTokens:
            termRepo?.collateralTokensMeta?.map((c) => ({
              address: c.id,
              symbol: c.symbol,
              decimals: c.decimals,
              isRepoToken: false,
            })) ?? [],
          auctionClearingPrice: bigToFixedNumber(
            termRepo?.completedAuctions?.[0]?.auctionClearingPrice ?? '0',
            18
          ),
          distributionRatio: FixedNumber.fromString('0', `fixed128x18`),
        }

        const onChainBalance = repoTokenBalancesData?.[chainId]?.[idx]?.balance
        if (onChainBalance) {
          holdingsMap[tokenAddress].faceValue = onChainBalance
        }
      })

      // if vault holds repo token, include offer under the repo token holding
      const mergedOpenAuctionOffers: VaultHolding[] = []

      Object.values(openOffersMap).forEach((offer) => {
        const key = offer.repoToken.toLowerCase()
        const matchedHolding = holdingsMap[key]
        if (matchedHolding) {
          matchedHolding.faceValue = add(
            matchedHolding.faceValue,
            offer.faceValue,
            matchedHolding.repoTokenCurrency.decimals
          )
        } else {
          // if vault does not already hold this repoToken, treat it as an open auction offer
          mergedOpenAuctionOffers.push(offer)
        }
      })

      // transform holdingsMap to an array and add prices + largest holding
      let largestDistributionRatio = FixedNumber.fromString('0', `fixed128x18`)

      const finalRepoTokenHoldings: VaultHolding[] = Object.values(
        holdingsMap
      ).map((h) => {
        const distributionRatio =
          !h.presentValue.isZero() && !totalAssetValue.isZero()
            ? divide(h.presentValue, totalAssetValue)
            : FixedNumber.fromString('0', `fixed128x18`)

        if (fixedCompare(distributionRatio, 'gt', largestDistributionRatio)) {
          largestDistributionRatio = distributionRatio
        }

        const presentValueUsd = multiply(
          h.presentValue,
          vaultAssetPrice,
          vaultAssetPrice.format.decimals
        )

        return {
          ...h,
          presentValueUsd,
          distributionRatio,
        }
      })

      // add prices to open auction offers
      mergedOpenAuctionOffers.forEach((offer) => {
        offer.presentValueUsd = multiply(
          offer.presentValue,
          vaultAssetPrice,
          vaultAssetPrice.format.decimals
        )
        const distributionRatio = !totalAssetValue.isZero()
          ? divide(offer.presentValue, totalAssetValue)
          : FixedNumber.fromString('0', `fixed128x18`)

        offer.distributionRatio = distributionRatio

        if (fixedCompare(distributionRatio, 'gt', largestDistributionRatio)) {
          largestDistributionRatio = distributionRatio
        }
      })

      return {
        repoTokenHoldings: finalRepoTokenHoldings,
        openAuctionOffers: mergedOpenAuctionOffers,
        largestHolding: largestDistributionRatio,
      }
    }, [
      auctionOffersSubgraphData,
      repoTokenHoldingsWithValue,
      vaultSubgraphData?.termVaultStrategy,
      termRepoHoldingsSubgraphData?.termRepos,
      vaultAssetPrice,
      repoTokenBalancesData,
      totalAssetValue,
      chainId,
    ])

  // The hook returns an object with final, aggregated data
  return {
    repoTokenHoldings,
    openAuctionOffers,
    largestHolding,
  }
}
