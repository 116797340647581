import { Text } from '@chakra-ui/react'

export default function SummaryFootnote({
  text,
  warning,
}: {
  text: string
  warning?: boolean
}) {
  return (
    <Text
      color={warning ? 'orange.5' : 'gray.5'}
      variant="body-xs/normal"
      textAlign="left"
      width="full"
    >
      {text}
    </Text>
  )
}
