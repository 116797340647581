import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import { useEffect, useMemo } from 'react'
import { Contract, FixedNumber } from 'ethers'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import { bigToFixedNumber, fixedToBigNumber } from '../../helpers/conversions'
import { captureException } from '@sentry/react'

interface UseVaultConstraintsResult {
  weightedMaturity?: number
  repoTokenConcentrationRatio?: FixedNumber
  liquidityRatio?: FixedNumber
}

export function useVaultConstraints(
  strategyAddress: Address | undefined,
  chainId: string,
  repoTokenAddress?: Address,
  amount?: FixedNumber,
  provider?: JsonRpcProvider | FallbackProvider
): UseVaultConstraintsResult | null | undefined {
  // Vault contract calls

  const canFetch = useMemo(() => {
    return !!strategyAddress && !!provider && !!repoTokenAddress && !!amount
  }, [strategyAddress, provider, repoTokenAddress, amount])

  // #region

  const strategyContract = useMemo(() => {
    if (!strategyAddress || !provider) return undefined
    return new Contract(
      strategyAddress,
      TermVaultStrategyABI,
      provider
    ) as TermVaultStrategy
  }, [strategyAddress, provider])

  const simulateTransactionCall: MultichainCalls = useMemo(() => {
    if (!canFetch || !strategyContract || !repoTokenAddress || !amount) {
      return {}
    }
    const calls = [
      {
        contract: strategyContract,
        method: 'simulateTransaction',
        args: [repoTokenAddress, fixedToBigNumber(amount)],
      },
    ]
    return {
      [chainId]: calls,
    }
  }, [canFetch, amount, chainId, repoTokenAddress, strategyContract])

  const blockchainResponse = useMultichainCalls(simulateTransactionCall)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(blockchainResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [blockchainResponse])

  const blockchainErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResponse) {
      Object.entries(blockchainResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResponse])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const {
    newWeightedMaturity,
    newRepoTokenConcentrationRatio,
    newLiquidityRatio,
  } = useMemo(() => {
    if (!canFetch || !blockchainResults) return {}

    let newWeightedMaturity: number | undefined
    let newRepoTokenConcentrationRatio: FixedNumber | undefined
    let newLiquidityRatio: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const simulateTransactionResultValue = values.slice(0, 1)?.[0]
      if (strategyAddress && simulateTransactionResultValue) {
        newWeightedMaturity = simulateTransactionResultValue?.[0]
        newRepoTokenConcentrationRatio = bigToFixedNumber(
          simulateTransactionResultValue?.[1],
          18
        )
        newLiquidityRatio = bigToFixedNumber(
          simulateTransactionResultValue?.[2],
          18
        )
      }
    })
    return {
      newWeightedMaturity,
      newRepoTokenConcentrationRatio,
      newLiquidityRatio,
    }
  }, [canFetch, blockchainResults, strategyAddress])

  // #endregion

  if (!canFetch) {
    return undefined
  }

  return {
    weightedMaturity: newWeightedMaturity,
    repoTokenConcentrationRatio: newRepoTokenConcentrationRatio,
    liquidityRatio: newLiquidityRatio,
  }
}
