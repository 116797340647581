import { graphql } from '../gql'

export const TermRepoTokenHoldingsDocument = graphql(`
  query TermRepoTokenHoldings($termRepoTokens: [Bytes!]!) {
    termRepos(where: { termRepoToken_in: $termRepoTokens, delisted: false }) {
      id
      version
      collateralTokensMeta {
        id
        decimals
        symbol
        name
      }
      termRepoToken
      termRepoTokenMeta {
        decimals
        symbol
        name
      }
      repurchaseTimestamp
      redemptionTimestamp
      completedAuctions {
        auctionClearingPrice
      }
    }
  }
`)
