import { Box, Flex, Grid, Skeleton } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import RepoCardButton from '../../../Lend/elements/RepoCardButton'
import RepoCardHeading from '../../../Lend/elements/RepoCardHeading'

export default function RepoCardLoading({
  isMetaVaultView,
}: {
  isMetaVaultView?: boolean
}) {
  return (
    <>
      <Flex
        flexDir="column"
        minW={{
          base: '922px',
        }}
        position="relative"
        borderRadius="6px"
        overflow="visible"
        boxShadow={'0px 4px 4px rgba(49, 130, 206, 0.03)'}
      >
        {/* First Row */}
        <Flex
          justifyContent="space-between"
          flexDir={{
            base: 'row',
          }}
          p={3}
          background="white"
          transition="all .25s ease-in-out"
        >
          <Flex
            gap={{
              base: 7,
              '1xl': 12,
            }}
            flexDirection={{
              base: 'row',
            }}
            py={2.5}
            mr="auto"
          >
            {/* Current Yield */}
            <Box
              h="100%"
              w="130px"
              borderRight={{
                base: '1px solid',
              }}
              borderRightColor={{
                base: 'gray.3',
              }}
              pr={2}
            >
              <RepoCardHeading title="Current Yield" mb={2} />
              <HStack>
                <Skeleton h="32px" w="80px" />
              </HStack>
            </Box>
            <Grid
              gridTemplateColumns={{
                base: isMetaVaultView
                  ? '100px 80px 80px 100px'
                  : '150px 100px 100px',
              }}
              gap={{
                base: isMetaVaultView ? '24px' : '38px',
                '1xl': '89px',
                '3xl': 36,
              }}
            >
              {/* Vault */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Vault" mb={2} />
                <HStack>
                  <Skeleton h="32px" w={isMetaVaultView ? '100px' : '150px'} />
                </HStack>
              </Flex>
              {/* Collateral */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Collateral" mb={2} />
                <HStack>
                  <Skeleton h="32px" w="80px" />
                </HStack>
              </Flex>

              {isMetaVaultView && (
                <Flex
                  flexDirection="column"
                  align="start"
                  verticalAlign="top"
                  px={0}
                >
                  <RepoCardHeading title="Target/Actual" mb={2} />
                  <HStack>
                    <Skeleton h="32px" w="80px" />
                  </HStack>
                </Flex>
              )}
              {/* Curator */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Curator" mb={2} />
                <HStack>
                  <Skeleton h="32px" w={isMetaVaultView ? '100px' : '110px'} />
                </HStack>
              </Flex>
            </Grid>
          </Flex>
          <Flex
            flexDirection="column"
            align="start"
            verticalAlign="top"
            mr="60px"
            py={2.5}
          >
            <RepoCardHeading title="Total Asset Value" mb={2} />
            <HStack>
              <Skeleton h="32px" w="120px" />
            </HStack>
          </Flex>
          {/* Button */}
          <Flex
            flexDirection="column"
            textAlign="right"
            justifyContent="center"
            minW={{
              base: 'max-content',
            }}
            px={{
              base: 0,
            }}
            gap={1}
          >
            <HStack spacing={0.5} justifyContent="right" h="16px"></HStack>
            <RepoCardButton
              isActive={false}
              isHovered={false}
              handleClick={() => {}}
            >
              View
            </RepoCardButton>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
