import { useState } from 'react'
import VaultModalBase from '..'
import { Address } from '../../../../../data/model'
import { termToastMessages } from '../../../../../helpers/toasts'
import { useTermToast } from '../../../../../hooks/toasts'

export const DisableRepoTokenModal = ({
  onSetRepoTokenBlacklist,
  isOpen,
  onClose,
}: {
  onSetRepoTokenBlacklist: (address: Address) => Promise<void>
  isOpen: boolean
  onClose: () => void
}) => {
  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)
  return (
    <VaultModalBase
      isOpen={isOpen}
      title="Disable Repo Token"
      description="Enter the address of the Repo Token to block it from being sold into the vault."
      titleInput="Repo Token"
      placeholder="Enter the contract address"
      textButton="Disable"
      contentCheckbox="I confirm the Repo Token address above is correct. Please contact the team if you are unsure."
      onClose={onClose}
      actionButton={async (address) => {
        setSubmitting(true)
        termToast.pending(termToastMessages.setRepoTokenBlacklist.pending())
        try {
          await onSetRepoTokenBlacklist(address)
          termToast.success(
            termToastMessages.setRepoTokenBlacklist.success(address)
          )
        } catch (error) {
          if ((error as Error).message.includes('user rejected transaction')) {
            termToast.dismissed()
          } else {
            termToast.failure(
              termToastMessages.setRepoTokenBlacklist.failure(address)
            )
          }
        } finally {
          setSubmitting(false)
        }
      }}
      isAddressInput
      submitting={submitting}
    />
  )
}

export default DisableRepoTokenModal
