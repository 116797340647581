import { graphql } from '../gql'

export const PagePortfolioDocument = graphql(`
  query PagePortfolio(
    $wallet: Bytes!
    $recordsToFetch: Int!
    $lastAuctionsId: ID!
    $lastReposId: ID!
    $lastPurchasesId: ID!
    $lastRepoExposuresId: ID!
    $lastRepoCollateralsId: ID!
    $lastBidsId: ID!
    $lastOffersId: ID!
    $lastRepoTokenRedemptionsId: ID!
    $lastRolloverInstructionsId: ID!
  ) {
    termAuctions(
      first: $recordsToFetch
      # where: { id_gt: $lastAuctionsId, delisted: false, term_: { repurchaseTimestamp_lt: 1705597200  } }
      where: { id_gt: $lastAuctionsId, delisted: false }
    ) {
      id
      version
      term {
        id
        version
        repurchaseTimestamp
        servicingFee

        purchaseToken
        collateralTokens
        purchaseTokenMeta {
          id
          decimals
          symbol
          name
        }
        collateralTokensMeta {
          id
          decimals
          symbol
          name
        }

        collateralRatios {
          maintenanceRatio
          initialRatio
        }

        liquidatedDamagesSchedule {
          collateralToken
          liquidatedDamages
        }

        termRepoServicer
        termRepoCollateralManager
        termRepoLocker
        termRepoRolloverManager
        termRepoToken
      }
      auction
      auctionBidLocker
      auctionOfferLocker
      auctionCancelled
      auctionCancelledForWithdrawal
      auctionComplete
      auctionClearingPrice
      auctionStartTime
      revealTime
      auctionEndTime
      dayCountFractionMantissa
      auctionMaxBidPrice
      auctionMinBidAmount
      auctionMaxOfferPrice
      auctionMinOfferAmount
    }
    termRepos(
      first: $recordsToFetch
      # where: { id_gt: $lastReposId, delisted: false, repurchaseTimestamp_lt: 1705597200 }
      where: { id_gt: $lastReposId, delisted: false }
    ) {
      id
      version
      termRepoCollateralManager
      collateralTokens
      collateralTokensMeta {
        decimals
        symbol
        name
      }
      termRepoServicer
      termRepoRolloverManager
      termRepoLocker
      termRepoToken
      termRepoTokenMeta {
        decimals
        symbol
        name
      }
      purchaseToken
      purchaseTokenMeta {
        decimals
        symbol
        name
      }
      repurchaseTimestamp
      redemptionTimestamp
      endOfRepurchaseWindow
      approvedRolloverTermAuctions {
        id
        version
        term {
          id
          repurchaseTimestamp
          endOfRepurchaseWindow

          purchaseToken
          purchaseTokenMeta {
            decimals
            symbol
            name
          }
          collateralTokens
          collateralTokensMeta {
            decimals
            symbol
            name
          }

          collateralRatios {
            maintenanceRatio
            initialRatio
          }
          servicingFee
          termRepoServicer
          termRepoCollateralManager
          termRepoLocker
        }
        dayCountFractionMantissa
        auction
        auctionBidLocker
        auctionOfferLocker
        auctionCancelled
        auctionComplete
        auctionClearingPrice
        auctionStartTime
        revealTime
        auctionEndTime
      }
      collateralRatios {
        maintenanceRatio
      }
      completedAuctions {
        id
        version
        auction
        auctionBidLocker
        auctionOfferLocker
        auctionStartTime
        auctionEndTime
        auctionComplete
        auctionCancelled
        auctionClearingPrice
      }
    }
    termPurchases(
      first: $recordsToFetch
      where: {
        id_gt: $lastPurchasesId
        lender: $wallet
        auction_: {
          delisted: false
          # auctionEndTime_lt: 1703523600
        }
      }
    ) {
      id
      auction {
        id
        version
        auction
        auctionBidLocker
        auctionOfferLocker
        auctionEndTime
        auctionClearingPrice
        term {
          id
          version
          termRepoServicer
          redemptionTimestamp
          repurchaseTimestamp
          termRepoToken
          collateralTokens
          purchaseToken
          termRepoTokenMeta {
            decimals
            symbol
            name
          }
        }
      }
      lender
      repurchasePrice
      purchasePrice
    }
    termRepoExposures(
      first: $recordsToFetch
      where: {
        id_gt: $lastRepoExposuresId
        borrower: $wallet
        # term_: { delisted: false, repurchaseTimestamp_lt: 1705597200 }
        term_: { delisted: false }
      } # orderBy: term__repurchaseTimestamp
    ) # orderDirection: asc
    {
      id
      borrower
      term {
        id
        version
        repurchaseTimestamp
        endOfRepurchaseWindow
        redemptionTimestamp
        termRepoCollateralManager
        termRepoServicer
        termRepoToken
        termRepoLocker
        termRepoRolloverManager
        purchaseToken
        collateralTokens
        collateralRatios {
          maintenanceRatio
        }
        completedAuctions {
          id
          version
          auction
          auctionBidLocker
          auctionOfferLocker
          auctionStartTime
          auctionEndTime
          auctionComplete
          auctionCancelled
          auctionClearingPrice
        }
      }
      amountCoveredInLiquidation
      liquidations {
        transactionHash
        timestamp
        amountLiquidated
        coverAmount
        repoExposureAfterLiquidation
        defaultLiquidation
      }
      purchasePrice
      repurchasePrice
      amountCollected
      repoExposure
      servicingFees
    }
    termRepoCollaterals(
      first: $recordsToFetch
      # where: { id_gt: $lastRepoCollateralsId, term_: { delisted: false, repurchaseTimestamp_lt: 1705597200 } }
      where: { id_gt: $lastRepoCollateralsId, term_: { delisted: false } } # orderBy: term__repurchaseTimestamp
    ) # orderDirection: asc
    {
      id
      term {
        id
        version
        termRepoToken
      }
      collateralToken
      repoExposure {
        id
        amountCollected
        amountCoveredInLiquidation
        repoExposure
      }
      amountLocked
    }
    termBids(
      first: $recordsToFetch
      where: {
        id_gt: $lastBidsId
        bidder: $wallet
        auction_: { delisted: false }
        locked: true
      }
    ) {
      id
      bidder
      locked
      amount
      unrevealedBidPrice
      revealedBidPrice

      auction {
        id
        version
        auction
        auctionCancelled
        auctionEndTime

        term {
          version
          purchaseToken
        }
      }

      bidCollateral {
        collateralToken
        amount
      }

      assignedAmount

      lastTransaction
    }
    termOffers(
      first: $recordsToFetch
      where: {
        id_gt: $lastOffersId
        offeror: $wallet
        auction_: { auctionCancelled: false, delisted: false }
        locked: true
      }
    ) {
      id
      offeror
      locked
      amount
      unrevealedOfferPrice
      revealedOfferPrice

      auction {
        id
        version
        auction
        auctionCancelled
        auctionEndTime

        term {
          version
          purchaseToken
        }
      }

      assignedAmount

      lastTransaction
    }
    repoTokenRedemptions(
      first: $recordsToFetch
      where: {
        id_gt: $lastRepoTokenRedemptionsId
        redeemer: $wallet
        # term_: { delisted: false, repurchaseTimestamp_lt: 1705597200  }
        term_: { delisted: false }
      }
    ) {
      id
      term {
        id
        version
      }
      redeemer
      amountRedeemed
    }
    termRolloverInstructions(
      first: $recordsToFetch
      where: {
        id_gt: $lastRolloverInstructionsId
        borrower: $wallet
        oldTerm_: { delisted: false }
        newTerm_: { delisted: false }
      }
    ) {
      id

      oldTerm {
        id
        version
        purchaseToken
      }
      newTerm {
        id
        version
      }

      borrower
      rolloverAmount
      rolloverAuction
      rolloverBidPriceHash

      cancelled
      locked
      fulfilled

      amountFulfilled
    }
  }
`)
