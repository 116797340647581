import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useContext,
} from 'react'
import { AuctionStatus } from '../data/model'
import { produce } from 'immer'

export type TermFilter =
  | 'one'
  | 'two'
  | 'four'
  | 'six'
  | 'eight'
  | 'thirteen'
  | 'twenty-six'
  | 'fifty-two'

export interface AuctionFilters {
  term: Record<TermFilter, boolean>
  lending: Record<string, boolean>
  collateral: Record<string, boolean>
  status: Record<AuctionStatus, boolean>
  chain: Record<string, boolean>
}

export type AuctionFilterGroupName = keyof AuctionFilters
export type AuctionFilterGroup<G extends AuctionFilterGroupName> =
  AuctionFilters[G]
export type AuctionFilter<G extends AuctionFilterGroupName> =
  keyof AuctionFilterGroup<G>

type FiltersContextType = {
  filters: AuctionFilters
  updateFilters: <G extends AuctionFilterGroupName>(
    filter: G,
    value: AuctionFilterGroup<G>
  ) => void
  clearFilters: () => void
}

export const defaultFilters: AuctionFilters = {
  term: {} as any,
  lending: {},
  collateral: {},
  status: {} as any,
  chain: {},
}

const FiltersContext = createContext<FiltersContextType>(
  {} as FiltersContextType
)

const FiltersConsumer = FiltersContext.Consumer

const FiltersProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useState<AuctionFilters>(defaultFilters)

  const updateFilters = useCallback(
    <G extends AuctionFilterGroupName>(
      filter: G,
      value: AuctionFilterGroup<G>
    ) => {
      setFilters(
        produce((draftState) => {
          draftState[filter] = value
        })
      )
    },
    [setFilters]
  )

  const clearFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [setFilters])

  return (
    <FiltersContext.Provider value={{ filters, updateFilters, clearFilters }}>
      {children}
    </FiltersContext.Provider>
  )
}

export const useFilters = () => {
  const filtersContext = useContext(FiltersContext)
  if (filtersContext === undefined) {
    throw new Error('useFilters must be used within a FiltersProvider')
  }
  return {
    filters: filtersContext.filters,
  }
}

export { FiltersConsumer, FiltersContext, FiltersProvider }
