import { Divider, Flex, Grid, Skeleton, Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { useState } from 'react'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'

// curators
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { HStack, VStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'
import EditableStat from './EditableStat'
import { multiply } from '../../../../helpers/math'
import VaultCuratorIcon from '../../../../components/elements/VaultCuratorIcon'

function StatStack({
  label,
  value,
}: {
  label: React.ReactNode
  value: React.ReactNode
}) {
  return (
    <VStack spacing="9px" align="start" w="full">
      <Text
        as="span"
        color="gray.6"
        variant="body-md/normal"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
      <Text as="span" color="blue.9" variant="body-md/semibold">
        {value}
      </Text>
    </VStack>
  )
}

type Props = {
  vaultCurator: string
  curatorIcon?: string
  WAL: number
  WALCap: number
  liquidityReserve: FixedNumber
  largestHolding: FixedNumber
  requiredReserve: FixedNumber
  concentrationCap: FixedNumber
  performanceFee: FixedNumber
  managementFee: FixedNumber
  isGovernor: boolean
  isDataLoaded?: boolean
  onSetRequiredReserveRatio: (ratio: string) => Promise<void>
  onSetRepoTokenConcentrationLimit: (limit: string) => Promise<void>
  onSetTimeToMaturityThreshold: (threshold: string) => Promise<void>
}

export default function VaultStats({
  vaultCurator,
  curatorIcon,
  WAL,
  WALCap,
  liquidityReserve,
  largestHolding,
  requiredReserve,
  concentrationCap,
  performanceFee,
  managementFee,
  isGovernor,
  isDataLoaded = true,
  onSetRequiredReserveRatio,
  onSetRepoTokenConcentrationLimit,
  onSetTimeToMaturityThreshold,
}: Props) {
  const [editableStats, setEditableStats] = useState({
    'WAL Cap': WALCap,
    'Required Reserve': requiredReserve,
    'Concentration Cap': concentrationCap,
  })

  const formattedLiquidityReserve = fixedToFormattedPercentage(
    liquidityReserve,
    2,
    false,
    true
  )
  const formattedLargestHolding = fixedToFormattedPercentage(
    largestHolding,
    2,
    false,
    true
  )

  const formattedRequiredReserve = multiply(
    requiredReserve,
    FixedNumber.fromString('100', 18)
  )
  const formattedConcentrationCap = multiply(
    concentrationCap,
    FixedNumber.fromString('100', 18)
  )

  const formattedPerformanceFee = fixedToFormattedPercentage(
    performanceFee,
    2,
    false,
    true
  )
  const formattedManagementFee = fixedToFormattedPercentage(
    managementFee,
    2,
    false,
    true
  )

  return (
    <Flex
      flexDir="column"
      w="100%"
      maxW="584px"
      bg="white"
      overflowX="auto"
      overflowY="hidden"
    >
      <Grid
        gridTemplateColumns={{
          base: 'repeat(3, minmax(0, 1fr))',
        }}
        gap={{
          base: 4,
        }}
        rowGap={{
          base: 6,
        }}
        mb={6}
      >
        <StatStack
          label={
            <HStack spacing="4px">
              <Text as="span">WAL</Text>
              <Tooltip
                label="Average length of time to the repayment of principal."
                placement="top-start"
                w="304px"
                offset={[-5, 6]}
              >
                <InfoOutlineIcon color="gray.4" />
              </Tooltip>
            </HStack>
          }
          value={
            <Skeleton w="180px" isLoaded={isDataLoaded}>
              <Tooltip
                noDelay
                label={WAL < 1 ? `${WAL} days` : undefined}
                offset={[-5, 6]}
              >
                <Text as="span">{`${WAL < 1 ? ' < 1' : WAL} ${WAL <= 1 ? 'day' : 'days'}`}</Text>
              </Tooltip>
            </Skeleton>
          }
        />
        <StatStack
          label="Liquidity Reserve"
          value={
            <Skeleton w="180px" isLoaded={isDataLoaded}>
              <Text as="span">
                {formattedLiquidityReserve.formattedPercentage}
              </Text>
            </Skeleton>
          }
        />
        <StatStack
          label="Largest Holding"
          value={
            <Skeleton w="180px" isLoaded={isDataLoaded}>
              <Text as="span">
                {formattedLargestHolding.formattedPercentage}
              </Text>
            </Skeleton>
          }
        />
        {/* WAL CAP */}
        <EditableStat
          key={'WALCap'}
          label={'WAL Cap'}
          type={'date'}
          initialValue={WALCap}
          isGovernor={isGovernor}
          tooltip={'The maximum WAL of the vault.'}
          onSave={onSetTimeToMaturityThreshold}
          isDataLoaded={isDataLoaded}
        />
        {/* Required Reserve */}
        <EditableStat
          key={'RequiredReserve'}
          label={'Required Reserve'}
          type={'percentage'}
          initialValue={formattedRequiredReserve}
          isGovernor={isGovernor}
          onSave={onSetRequiredReserveRatio}
          isDataLoaded={isDataLoaded}
        />
        {/* Concentration Cap */}
        <EditableStat
          key={'ConcentrationCap'}
          label={'Concentration Cap'}
          type={'percentage'}
          initialValue={formattedConcentrationCap}
          isGovernor={isGovernor}
          onSave={onSetRepoTokenConcentrationLimit}
          isDataLoaded={isDataLoaded}
        />
      </Grid>
      <Divider color="blue.9" mb={6} />
      <Grid
        gridTemplateColumns={{
          base: 'repeat(3, minmax(0, 1fr))',
        }}
        gap={{
          base: 4,
        }}
        rowGap={{
          base: 6,
        }}
      >
        <StatStack
          label="Curator"
          value={
            <Skeleton w="180px" isLoaded={isDataLoaded}>
              <HStack>
                <VaultCuratorIcon
                  vaultName={curatorIcon ?? 'unknown'}
                  boxSize="24px"
                />
                <Text as="span">{vaultCurator}</Text>
              </HStack>
            </Skeleton>
          }
        />
        <StatStack
          label="Performance Fee"
          value={
            <Skeleton w="180px" isLoaded={isDataLoaded}>
              <Text as="span">
                {formattedPerformanceFee.formattedPercentage}
              </Text>
            </Skeleton>
          }
        />
        <StatStack
          label="Management Fee"
          value={
            <Skeleton w="180px" isLoaded={isDataLoaded}>
              <Text as="span">
                {formattedManagementFee.formattedPercentage}
              </Text>
            </Skeleton>
          }
        />
      </Grid>
    </Flex>
  )
}
