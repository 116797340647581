import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
  Button,
  HStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import TokenIconLabel from '../../../../../components/elements/TokenLabelIcon'
import { FixedNumber } from 'ethers'
import { CollateralTokenPairing } from '../../../../../models/vault'
import { useTermToast } from '../../../../../hooks/toasts'
import { useState } from 'react'
import { termToastMessages } from '../../../../../helpers/toasts'
import { fixedToFormattedPercentage } from '../../../../../helpers/conversions'

export const EditCollateralModal = ({
  isOpen,
  onClose,
  collateralTokenSelected,
  valueRatio,
  onEditCollateral,
}: {
  isOpen: boolean
  onClose: () => void
  collateralTokenSelected: CollateralTokenPairing
  valueRatio: FixedNumber
  onEditCollateral: () => Promise<void>
}) => {
  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)

  const handleConfirm = async () => {
    setSubmitting(true)
    termToast.pending(termToastMessages.editCollateral.pending())
    const valueRatioPercentage =
      fixedToFormattedPercentage(valueRatio).formattedPercentage
    try {
      await onEditCollateral()
      termToast.success(
        termToastMessages.editCollateral.success(
          collateralTokenSelected.symbol,
          valueRatioPercentage
        )
      )
    } catch (error) {
      if ((error as Error).message.includes('user rejected transaction')) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.editCollateral.failure())
      }
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />

      <ModalContent p={1}>
        <ModalHeader>
          <Text variant="body-2xl/semibold">
            <Trans>Save edits?</Trans>
          </Text>
          <ModalCloseButton m={2} />
        </ModalHeader>

        <ModalBody>
          <Text variant="body-sm/normal">
            <Trans>
              Are you sure you want to update the minimum maintenance ratios?
              This won’t affect current holdings but will impact future
              purchases.
            </Trans>
          </Text>

          <HStack
            border="1px solid"
            borderColor="gray.2"
            borderRadius="6px"
            bg="blue.0"
            py={2}
            px={3}
            mt={7}
            justifyContent="space-between"
          >
            <TokenIconLabel
              isSmall
              token={collateralTokenSelected.symbol}
              boxSize="20px"
              customVariant="body-sm/medium"
              colorSymbol="gray.6"
              isMedium
            />

            <HStack gap={1} alignItems="center">
              <Text variant="body-sm/semibold" color="gray.4">
                {Number(collateralTokenSelected.minMaintenanceMargin) * 100}%
              </Text>

              <Text variant="body-sm/normal" color="gray.4" mb={1}>
                →
              </Text>

              <Text variant="body-sm/normal" color="blue.9">
                {Number(valueRatio)}%
              </Text>
            </HStack>
          </HStack>
        </ModalBody>

        <ModalFooter pt={-2} mb={2.5}>
          <Button
            variant="secondary"
            width="100%"
            _disabled={{
              opacity: 0.5,
              cursor: 'not-allowed',
            }}
            isDisabled={submitting}
            onClick={handleConfirm}
          >
            <Trans>Confirm</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditCollateralModal
