import { Image, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import termIcon from '../../../../assets/icons/projects/term-default.svg'
import { HStack, VStack } from '../../../../components/elements/Stack'
import TokenIcon from '../../../../components/elements/TokenIcon'

const AnimatedPath = ({
  d,
  variants,
  duration,
  ...props
}: {
  d: string
  [key: string]: any
  variants: {}
  duration: number
}) => (
  <motion.path
    d={d}
    stroke="url(#commonGradient)"
    strokeWidth="17.3"
    initial="hidden"
    {...props}
    variants={variants}
    transition={{
      duration: duration,
      repeat: Infinity,
      repeatType: 'reverse',
    }}
  />
)

const RectWithForeignObject = ({
  x,
  y,
  width,
  icon,
  vault,
  rate,
  isStrategyVault,
  animation,
  variants,
  duration,
  ...props
}: {
  x: string
  y: string
  width: string
  icon: string
  vault: string
  rate: number
  isStrategyVault?: boolean
  animation?: string
  variants: {}
  duration: number
  [key: string]: any
}) => (
  <>
    <rect x={x} y={y} width={width} height="36px" rx="6" fill="white" />
    <motion.foreignObject
      x={x}
      y={y}
      width={width}
      height="36px"
      initial="hidden"
      {...props}
      variants={variants}
      transition={{
        duration: duration,
        repeat: Infinity,
        repeatType: 'reverse',
      }}
    >
      <HStack padding={1.5} h="36px" gap={3} animation={animation}>
        <TokenIcon token={icon} boxSize="24px" />
        <Text
          variant={`${isStrategyVault ? 'body-sm' : 'body-xs'}/bold`}
          color="black"
        >
          {vault}{' '}
          <Text
            variant={`${isStrategyVault ? 'body-sm' : 'body-xs'}/normal`}
            as="span"
          >
            {rate}%
          </Text>
        </Text>
      </HStack>
    </motion.foreignObject>
  </>
)

export default function MetaVaultSvg({
  lowOpacity = 0.3,
  highOpacity = 1,
  duration = 5,
}: {
  lowOpacity?: number
  highOpacity?: number
  duration?: number
}) {
  const [animatedPathIndex, setAnimatedPathIndex] = useState(0)
  const adjustedDuration = (duration * 1000) / 2

  const MfadeInOut = {
    hidden: { opacity: lowOpacity },
    visible: { opacity: [lowOpacity, highOpacity, lowOpacity] },
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedPathIndex((prevIndex) => (prevIndex + 1) % 3)
    }, adjustedDuration)

    return () => clearInterval(interval)
  }, [])

  return (
    <svg
      width="755"
      height="312"
      viewBox="0 0 755 312"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="column1">
        <rect y="115" width="181" height="52" rx="6" fill="transparent" />
        <foreignObject x="0" y="110" width="181" height="24">
          <HStack>
            <Text variant="body-sm/bold" color="white">
              Meta Vaults
            </Text>
          </HStack>
        </foreignObject>
        <rect y="149" width="181" height="52" rx="6" fill="white" />
        <foreignObject x="0" y="149" width="181" height="52">
          <HStack padding={1.5} h="52px" gap={3}>
            <Image width="26px" height="26px" src={termIcon} />
            <VStack align="start" gap={0}>
              <Text variant="body-sm/bold" lineHeight="14px" color="black">
                Term Meta Vaults
              </Text>
              <Text variant="body-sm/medium" lineHeight="14px" color="black">
                100%
              </Text>
            </VStack>
          </HStack>
        </foreignObject>
      </g>
      <g id="column2">
        <AnimatedPath
          d="M181 163C216.819 162.865 240.051 116.028 296 116"
          animate={animatedPathIndex === 0 ? 'visible' : 'hidden'}
          variants={MfadeInOut}
          duration={2}
        />
        <AnimatedPath
          d="M181.009 175.103L295.997 175.355"
          animate={animatedPathIndex === 1 ? 'visible' : 'hidden'}
          variants={MfadeInOut}
          duration={2}
        />
        <AnimatedPath
          d="M181 188.011C226.64 187.574 252.412 235.014 296 234.998"
          animate={animatedPathIndex === 2 ? 'visible' : 'hidden'}
          variants={MfadeInOut}
          duration={2}
        />
      </g>
      <g id="column3">
        <foreignObject x="296" y="60" width="170" height="24">
          <HStack>
            <Text variant="body-sm/bold" color="white">
              Strategy Vaults
            </Text>
          </HStack>
        </foreignObject>
        {/* top vault*/}
        <RectWithForeignObject
          x="296"
          y="99"
          width="170"
          icon="USDC"
          vault="K3 USDC"
          rate={33}
          animate={animatedPathIndex === 0 ? 'visible' : 'hidden'}
          variants={MfadeInOut}
          duration={3}
          isStrategyVault
        />
        {/* middle vault */}
        <RectWithForeignObject
          x="296"
          y="159"
          width="170"
          icon="USDC"
          vault="RE7 USDC"
          rate={33}
          animate={animatedPathIndex === 1 ? 'visible' : 'hidden'}
          variants={MfadeInOut}
          duration={3}
          isStrategyVault
        />
        {/* bottom vault */}
        <RectWithForeignObject
          x="296"
          y="219"
          width="170"
          icon="USDC"
          vault="Edge USDC"
          rate={33}
          animate={animatedPathIndex === 2 ? 'visible' : 'hidden'}
          variants={MfadeInOut}
          duration={3}
          isStrategyVault
        />
      </g>
      <g id="column4">
        {/* top vault*/}
        <g>
          <AnimatedPath
            d="M466 109.505C499.608 109.27 521.505 54.1529 574 53.9999"
            animate={animatedPathIndex === 0 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={4}
          />
          <AnimatedPath
            d="M466 122.5C508.951 122.5 532.992 247.728 574 246.993"
            animate={animatedPathIndex === 0 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={4}
          />
          <AnimatedPath
            d="M466 115.008C508.827 114.766 533.097 149.933 574 149.998"
            animate={animatedPathIndex === 0 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={4}
          />
        </g>
        {/* middle vault */}
        <g>
          <AnimatedPath
            d="M466.496 169.959C500.07 169.604 522.055 102.251 574.496 101.959"
            animate={animatedPathIndex === 1 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={4}
          />
        </g>
        {/* bottom vault*/}
        <g>
          <AnimatedPath
            d="M466.496 233.901C500.16 233.862 521.913 198.883 574.496 198.959"
            animate={animatedPathIndex === 2 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={4}
          />
          <AnimatedPath
            d="M466.496 239.959C510.355 240.026 534.04 296.402 574.496 295.95"
            animate={animatedPathIndex === 2 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={4}
          />
        </g>
      </g>
      <g id="column5">
        <foreignObject x="574" y="-3" width="181" height="24">
          <HStack>
            <Text variant="body-sm/bold" color="white">
              Repo Tokens
            </Text>
          </HStack>
        </foreignObject>
        {/* top vault */}
        <g>
          <RectWithForeignObject
            x="574"
            y="36"
            width="181"
            icon="WBTC"
            vault="Nov. 1 wBTC"
            rate={7.75}
            animate={animatedPathIndex === 0 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={duration}
          />
          <RectWithForeignObject
            x="574"
            y="132"
            width="181"
            icon="WBTC"
            vault="Nov. 15 wBTC"
            rate={7.75}
            animate={animatedPathIndex === 0 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={duration}
          />
          <RectWithForeignObject
            x="574"
            y="228"
            width="181"
            icon="WBTC"
            vault="Dec 1 wBTC"
            rate={7.75}
            animate={animatedPathIndex === 0 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={duration}
          />
        </g>
        {/* middle vault */}
        <g>
          <RectWithForeignObject
            x="574"
            y="84"
            width="181"
            icon="ETH"
            vault="Nov. 1 wstETH"
            rate={7.5}
            animate={animatedPathIndex === 1 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={duration}
          />
        </g>
        {/* bottom vault */}
        <g>
          <RectWithForeignObject
            x="574"
            y="180"
            width="181"
            icon="ETH"
            vault="Nov. 15 wstETH"
            rate={7.75}
            animate={animatedPathIndex === 2 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={duration}
          />

          <RectWithForeignObject
            x="574"
            y="276"
            width="181"
            icon="ETH"
            vault="Dec.15 wstETH"
            rate={7.75}
            animate={animatedPathIndex === 2 ? 'visible' : 'hidden'}
            variants={MfadeInOut}
            duration={duration}
          />
        </g>
      </g>
      <defs>
        <linearGradient id="commonGradient" x1="0.5" y1="0" x2="1" y2="0">
          <stop offset="0" stopColor="rgba(0, 153, 255, 1)" />
          <stop offset="100%" stopColor="rgba(147, 117, 255, 1)" />
        </linearGradient>
      </defs>
    </svg>
  )
}
