import aave from '../assets/icons/projects/aave.svg'
import morpho from '../assets/icons/projects/morpho.svg'
import spark from '../assets/icons/projects/spark.svg'
import yearn from '../assets/icons/projects/yearn.svg'
import compound from '../assets/icons/projects/compound.svg'

import unknown from '../assets/icons/error.svg'

const vaultIcons: {
  [vaultName: string]: string
} = {
  AAVE: aave,
  MORPHO: morpho,
  SPARK: spark,
  YEARN: yearn,
  COMPOUND: compound,

  unknown: unknown,
}

export default vaultIcons
