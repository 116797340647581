import { Skeleton, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OverflowBox from '../../../../components/elements/OverflowBox'

const ArrowText = ({
  startText,
  endText,
  warning,
}: {
  startText: string
  endText: string
  warning?: boolean
}) => {
  return (
    <Text
      as="span"
      color={warning ? 'orange.5' : 'blue.9'}
      variant="body-xs/normal"
      whiteSpace="nowrap"
    >
      <Text
        as="span"
        color="gray.4"
        variant="body-xs/normal"
        whiteSpace="nowrap"
      >
        {startText}{' '}
        <FontAwesomeIcon
          icon={['far', 'arrow-right']}
          width="16px"
          height="16px"
        />
      </Text>{' '}
      {endText}
    </Text>
  )
}

export default function SummaryDetails({
  startText,
  endText,
  warning,
  isDataLoading,
}: {
  startText: string
  endText: string
  warning?: boolean
  isDataLoading?: boolean
}) {
  return (
    <OverflowBox maxW="170px" w="full" textAlign="right" lineHeight={1}>
      {isDataLoading ? (
        <Skeleton w="100px" h={4} />
      ) : (
        <ArrowText warning={warning} startText={startText} endText={endText} />
      )}
    </OverflowBox>
  )
}
