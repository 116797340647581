import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import { BigNumber, Contract, FixedNumber, Signer } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'
import { CallObject, MultichainCalls, useMultichainCalls } from './helper-hooks'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import { captureException } from '@sentry/react'
import { bigToFixedNumber, fixedToBigNumber } from '../../helpers/conversions'
import { waitForStatus } from '../../helpers/wait'
import { TransactionStatus, useSendTransaction } from '@usedapp/core'
import { divide } from '../../helpers/math'

export function useMetaVault(
  account: Address | undefined,
  multiStrategyAddress: Address,
  chainId: string,
  signer: Signer | undefined,
  assetDecimals: number | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  readFromSubgraph: () => void
):
  | {
      // current price per share
      currentPricePerShare: FixedNumber | null | undefined
      // total asset value
      totalAssetValue: FixedNumber | null | undefined
      // is vault shutdown
      isShutdown: boolean | null | undefined
      // conversion ratio
      convertToAssetsRatio: FixedNumber | null | undefined
      // available deposit limit
      availableDepositLimit: FixedNumber | null | undefined
      // available withdraw limit
      availableWithdrawLimit: FixedNumber | null | undefined
      // deposit into meta vault
      deposit: (chainId: string, amount: FixedNumber) => Promise<void>
      // withdraw from meta vault
      withdraw: (
        chainId: string,
        amount: FixedNumber,
        maxLoss?: FixedNumber
      ) => Promise<void>
    }
  | null
  | undefined {
  const multiStrategyContract = useMemo(() => {
    return new Contract(
      multiStrategyAddress,
      TermVaultStrategyABI,
      provider
    ) as TermVaultStrategy
  }, [multiStrategyAddress, provider])
  const validateActiveNetwork = useCallback(
    async (chainId: number) => {
      const activeNetwork = await signer?.provider?.getNetwork()
      return (
        activeNetwork?.chainId &&
        activeNetwork.chainId.toString() === chainId.toString()
      )
    },
    [signer]
  )

  // Read contract calls

  // #region

  const vaultContractCalls: MultichainCalls = useMemo(() => {
    const calls: CallObject[] = [
      {
        contract: multiStrategyContract,
        method: 'pricePerShare',
        args: [],
      },
      {
        contract: multiStrategyContract,
        method: 'totalAssets',
        args: [],
      },
      {
        contract: multiStrategyContract,
        method: 'isShutdown',
        args: [],
      },
      {
        contract: multiStrategyContract,
        method: 'convertToAssets',
        args: [BigNumber.from(1000000)],
      },
    ]

    if (account) {
      calls.push(
        {
          contract: multiStrategyContract,
          method: 'maxDeposit',
          args: [account],
        },
        {
          contract: multiStrategyContract,
          method: 'maxWithdraw',
          args: [account],
        }
      )
    }

    return {
      [chainId]: calls,
    }
  }, [account, chainId, multiStrategyContract])

  const blockchainResponse = useMultichainCalls(vaultContractCalls)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(blockchainResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [blockchainResponse])

  const blockchainErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResponse) {
      Object.entries(blockchainResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResponse])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const currentPricePerShare = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const pricePerShareValue = values.slice(0, 1)?.[0]

      if (multiStrategyAddress && pricePerShareValue) {
        const decimals = assetDecimals ?? 18
        value = FixedNumber.fromValue(pricePerShareValue?.[0], decimals)
      }
    })
    return value
  }, [blockchainResults, multiStrategyAddress, assetDecimals])

  const totalAssetValue = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const totalAssetValueValue = values.slice(1, 2)?.[0]

      if (multiStrategyAddress && totalAssetValueValue) {
        const decimals = assetDecimals ?? 18
        value = FixedNumber.fromValue(totalAssetValueValue?.[0], decimals)
      }
    })

    return value
  }, [blockchainResults, multiStrategyAddress, assetDecimals])

  const isShutdown = useMemo(() => {
    if (!blockchainResults) return null

    let value: boolean | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const isShutdownValue = values.slice(2, 3)?.[0]

      if (multiStrategyAddress && isShutdownValue) {
        value = isShutdownValue?.[0]
      }
    })

    return value
  }, [blockchainResults, multiStrategyAddress])

  const convertToAssetsRatio = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const convertToAssetsValue = values.slice(3, 4)?.[0]

      if (multiStrategyAddress && convertToAssetsValue && assetDecimals) {
        value = divide(
          bigToFixedNumber(convertToAssetsValue?.[0], 18),
          bigToFixedNumber(BigNumber.from(1000000), 18)
        )
      }
    })

    return value
  }, [blockchainResults, multiStrategyAddress, assetDecimals])

  const availableDepositLimit = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const availableDepositLimitValue = values.slice(4, 5)?.[0]

      if (multiStrategyAddress && availableDepositLimitValue && assetDecimals) {
        value = FixedNumber.fromValue(
          availableDepositLimitValue?.[0],
          assetDecimals
        )
      }
    })

    return value
  }, [assetDecimals, blockchainResults, multiStrategyAddress])

  const availableWithdrawLimit = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const availableWithdrawLimitValue = values.slice(5, 6)?.[0]

      if (
        multiStrategyAddress &&
        availableWithdrawLimitValue &&
        assetDecimals
      ) {
        value = FixedNumber.fromValue(
          availableWithdrawLimitValue?.[0],
          assetDecimals
        )
      }
    })

    return value
  }, [assetDecimals, blockchainResults, multiStrategyAddress])

  const transactionStates = useMemo(
    () =>
      ({}) as {
        deposit: TransactionStatus | undefined
        withdraw: TransactionStatus | undefined
      },
    []
  )

  const {
    sendTransaction: depositTransaction,
    state: depositState,
    resetState: resetDepositState,
  } = useSendTransaction()

  const {
    sendTransaction: withdrawTransaction,
    state: withdrawState,
    resetState: resetWithdrawState,
  } = useSendTransaction()

  useEffect(() => {
    transactionStates.deposit = depositState
  }, [depositState, transactionStates])
  useEffect(() => {
    transactionStates.withdraw = withdrawState
  }, [withdrawState, transactionStates])

  // #endregion

  // Write contract calls

  // #region

  const depositIntoVault = useCallback(
    async (chainId: string, amount: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'depositing %o tokens into multi strategy vault %o',
        amount.toString(),
        multiStrategyAddress
      )

      const data = multiStrategyContract.interface.encodeFunctionData(
        'deposit',
        [fixedToBigNumber(amount), account]
      )

      await depositTransaction({
        chainId: parsedChainId,
        to: multiStrategyAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.deposit,
        resetDepositState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      multiStrategyAddress,
      multiStrategyContract.interface,
      depositTransaction,
      resetDepositState,
      readFromSubgraph,
      transactionStates.deposit,
    ]
  )

  const withdrawFromVault = useCallback(
    async (chainId: string, amount: FixedNumber, maxLoss?: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'withdrawing %o shares from multi strategy vault %o',
        amount.toString(),
        multiStrategyAddress
      )

      let data: string
      if (maxLoss) {
        console.info('maxLoss set to be %o', maxLoss.toString())
        data = multiStrategyContract.interface.encodeFunctionData(
          'withdraw(uint256,address,address,uint256)',
          [
            fixedToBigNumber(amount),
            account,
            account,
            fixedToBigNumber(maxLoss),
          ]
        )
      } else {
        data = multiStrategyContract.interface.encodeFunctionData(
          'withdraw(uint256,address,address)',
          [fixedToBigNumber(amount), account, account]
        )
      }

      await withdrawTransaction({
        chainId: parsedChainId,
        to: multiStrategyAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.withdraw,
        resetWithdrawState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      multiStrategyAddress,
      withdrawTransaction,
      resetWithdrawState,
      readFromSubgraph,
      multiStrategyContract.interface,
      transactionStates.withdraw,
    ]
  )

  // #endregion

  const vaultParams = useMemo(() => {
    if (
      totalAssetValue !== undefined &&
      currentPricePerShare !== undefined &&
      totalAssetValue !== undefined &&
      isShutdown !== undefined &&
      convertToAssetsRatio !== undefined
    ) {
      return {
        currentPricePerShare,
        totalAssetValue,
        isShutdown,
        convertToAssetsRatio,
        availableDepositLimit,
        availableWithdrawLimit,
        deposit: depositIntoVault,
        withdraw: withdrawFromVault,
      }
    } else {
      return undefined
    }
  }, [
    currentPricePerShare,
    totalAssetValue,
    isShutdown,
    convertToAssetsRatio,
    availableDepositLimit,
    availableWithdrawLimit,
    depositIntoVault,
    withdrawFromVault,
  ])

  return vaultParams
}
