import { Button, Flex, Image, Text } from '@chakra-ui/react'
import lendTokensEmpty from '../../../../assets/icons/lend-tokens-empty.svg'
import { VStack } from '../../../../components/elements/Stack'

export default function VaultsTableEmpty() {
  return (
    <Flex
      width="579px"
      justifyContent="center"
      alignItems="center"
      mx="auto"
      cursor="default"
    >
      <VStack>
        <Image width="222px" height="222px" src={lendTokensEmpty} />
        <Text variant="body-l/bold">Term Vaults are on their way!</Text>
        <Text variant="body-sm/normal">
          None available now, but check back shortly.
        </Text>
        <Button
          mt={2}
          size="md"
          variant="tertiary"
          border={0}
          w="100%"
          onClick={() => {
            window.open('', '_blank', 'noopener,noreferrer')
          }}
        >
          Learn how Term Vaults work
        </Button>
      </VStack>
    </Flex>
  )
}
