export const externalLinks = {
  privacyPolicy: 'https://docs.term.finance/legal/privacy-policy',
  termsOfUse: 'https://docs.term.finance/legal/terms-of-use',
  liquidation:
    'https://docs.term.finance/protocol/term-repo/defaults/liquidation',
  nexusMutual: 'https://v2.nexusmutual.io/cover/product/127',
  priceFeedDocs:
    'https://developers.term.finance/term-finance-protocol/protocol-contracts/price-feeds',
  lending: 'https://docs.term.finance/periphery/blue-sheets-simple-earn',
  risk: 'https://docs.term.finance/protocol/risk-disclosures',
}
