import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FixedNumber } from 'ethers'
import AssetDropdown from '../../../../components/elements/AssetDropdown'
import { HStack } from '../../../../components/elements/Stack'
import MetaVaultBannerNoPosition from '../../../MetaVault/elements/MetaVaultBannerNoPosition'
import MetaVaultsTable from '../MetaVaultsTable'
import TotalDeposited from '../TotalDeposited'
import VaultsTable from '../VaultsTable'

export default function VaultsPageLoading() {
  const totalDeposited = FixedNumber.fromString('0')
  const depositToken = null
  const onChangePurchaseToken = () => {}

  return (
    <Box maxW="1400px" px={{ base: 8, xl: 10 }} mx="auto" mt={4}>
      <MetaVaultBannerNoPosition isDataLoaded={false} />

      {/* Meta Vaults */}
      <Heading
        variant="heading/h1"
        pt={6}
        mt={6}
        borderTop="1px solid #0000001A"
      >
        <Trans>Meta Vaults</Trans>
      </Heading>
      <Flex w="100%" pt={6} justify="space-between" mb={6}>
        <HStack spacing={10}>
          <Heading fontSize="20px" variant="heading/h2">
            <Trans>Deposit</Trans>
          </Heading>
          <AssetDropdown
            isDataLoading
            currencies={{}}
            onChangePurchaseToken={onChangePurchaseToken}
            showAllOption
          />
        </HStack>
        <TotalDeposited
          isDataLoading
          total={totalDeposited}
          label="Total Deposited"
        />
      </Flex>

      <Flex w="100%" flexDir="column" gap={4}>
        <MetaVaultsTable
          key={depositToken}
          metaVaults={[]}
          isDataLoading
          onViewMetaVault={() => {}}
        />
      </Flex>

      {/* Individual Strategy Vaults */}
      <Heading variant="heading/h1" mt={12}>
        <Trans>Individual Strategy Vaults</Trans>
      </Heading>
      <Flex w="100%" pt={8} justify="space-between" mb={6}>
        <HStack>
          <Text fontSize="34px" variant="body-3xl/bold">
            Deposit
          </Text>
          <AssetDropdown
            isDataLoading
            currencies={{}}
            onChangePurchaseToken={onChangePurchaseToken}
            showAllOption
          />
        </HStack>
        <TotalDeposited
          isDataLoading
          total={totalDeposited}
          label="Total Deposited"
        />
      </Flex>
      <Flex w="100%" flexDir="column" gap={4}>
        <VaultsTable
          selectedDepositToken={depositToken ?? ''}
          vaultsData={[]}
          selectedChainId={'1'}
          isDataLoading
          onViewVault={() => {}}
        />
      </Flex>
    </Box>
  )
}
