import {
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import IconButton from '../../../../components/elements/IconButton'
import { HStack } from '../../../../components/elements/Stack'
import icon_edit from '../../../../assets/icons/icon_edit.svg'
import icon_cancel from '../../../../assets/icons/icon_cancel.svg'
import { Trans } from '@lingui/macro'

export default function VaultCollateralPopout({
  onEditCollateralRatio,
  onRemoveCollateralFromVault,
}: {
  onEditCollateralRatio: () => void
  onRemoveCollateralFromVault: () => void
}) {
  return (
    <Menu
      computePositionOnMount={false}
      placement="bottom-end"
      strategy="fixed"
    >
      <MenuButton
        variant="link"
        as={Button}
        size="xs"
        rightIcon={
          <IconButton noBorder customColor="gray.6" customBg="transparent" />
        }
        onClick={() => {}}
        aria-label=""
      />
      <MenuList boxShadow=" 0px 0px 24px 0px #00000014" border="none">
        <MenuItem onClick={onEditCollateralRatio}>
          <HStack gap={1}>
            <Image w={6} h={6} src={icon_edit} alt="Edit" />
            <Text variant="body-sm/medium">
              <Trans>Update ratio</Trans>
            </Text>
          </HStack>
        </MenuItem>

        <MenuItem onClick={onRemoveCollateralFromVault}>
          <HStack gap={1}>
            <Image w={6} h={6} src={icon_cancel} alt="Cancel" />
            <Text variant="body-sm/medium">
              <Trans>Remove from vault</Trans>
            </Text>
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
