import {
  Box,
  Button,
  Divider,
  HStack,
  Link,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { Stack, VStack } from '../../../../components/elements/Stack'

import dayjs from 'dayjs'
import { BigNumber, FixedNumber } from 'ethers'
import { every, isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import BombPot from '../../../../components/elements/BombPotGif'
import InfoBox from '../../../../components/elements/InfoBox'
import SubmitApproveButton from '../../../../components/elements/SubmitApproveButton'
import Tooltip from '../../../../components/elements/Tooltip'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import {
  Auction,
  Currency,
  DraftLoanTender,
  NativeCurrency,
  VaultSummaryItem,
  Wallet,
} from '../../../../data/model'
import { parseLoanTender } from '../../../../data/parse'
import {
  DEFAULT_LOAN_TENDER,
  LOW_LIQUIDITY_ASSETS,
} from '../../../../helpers/constants'
import {
  bigToFixedNumber,
  fixedToBigNumber,
} from '../../../../helpers/conversions'
import { add } from '../../../../helpers/math'
import { termToastMessages } from '../../../../helpers/toasts'
import {
  formatFixedToken,
  formatMaturityDate,
  getAuctionTermString,
} from '../../../../helpers/utils'
import { useTermToast } from '../../../../hooks/toasts'
import { useChainConfig } from '../../../../providers/config'
import LoanVaultSummary from '../../../VaultAuction/elements/LoanVaultSummary'
import ClearButton from '../CreateTendersBox/ClearButton'
import SummaryBody from './SummaryBody'
import SummaryContainer from './SummaryContainer'
import SummaryDetails from './SummaryDetails'
import SummaryFootnote from './SummaryFootnote'
import SummaryHeader from './SummaryHeader'
import SummaryReferralCode from './SummaryReferralCode'
import SummaryStat from './SummaryStat'
import SummaryTitle from './SummaryTitle'
import TenderNumberHeading from './TenderNumberHeading'
import WrapGasButton from './WrapGasButton'

export default function LoanTenderSummary({
  isDataLoading,
  auction,
  auctionStartTimestamp,
  auctionEndTimestamp,
  wallet,
  tenders,
  maturityDate,
  purchaseCurrency,
  collateralCurrency,
  purchaseTokenBalance,
  purchaseTokenAllowance,
  gasTokenCurrency,
  gasTokenBalance,
  referralCode,
  referralCodeIsConnectedAddress,
  vaultConstraints,
  openConfirmDeleteReferralCodeModal,
  onApprovePurchaseToken,
  submitTenders,
  clearTenders,
  onDeleteReferralCode,
  onConnect,
  onKytCheck,
  onWrapGasTokenCall,
  onWrapTokenCall,
  onUnwrapGasTokenCall,
  onUnwrapTokenCall,
  onSetTotalTenderAmount,
}: {
  isDataLoading?: boolean
  auction: Pick<
    Auction,
    | 'chainId'
    | 'minOfferAmount'
    | 'maxOfferPrice'
    | 'bombPotAmount'
    | 'bombPotAuction'
    | 'bombPotRewardTokenSymbol'
  >
  auctionStartTimestamp: number
  auctionEndTimestamp: number
  wallet: Wallet
  tenders: DraftLoanTender[]
  maturityDate: number
  purchaseCurrency: Currency
  collateralCurrency: Currency
  purchaseTokenBalance: BigNumber
  purchaseTokenAllowance: BigNumber
  gasTokenCurrency: NativeCurrency
  gasTokenBalance: FixedNumber
  referralCode: string
  referralCodeIsConnectedAddress: boolean
  vaultConstraints?: VaultSummaryItem[]
  openConfirmDeleteReferralCodeModal: () => void

  onApprovePurchaseToken: (amount?: string) => Promise<void>
  submitTenders: () => Promise<void>
  clearTenders: () => void
  onDeleteReferralCode: () => void
  onConnect: () => void
  onKytCheck: () => Promise<boolean>
  onWrapGasTokenCall: (value: BigNumber) => Promise<void>
  onWrapTokenCall: (
    address: string,
    symbol: string,
    value: BigNumber
  ) => Promise<void>
  onUnwrapGasTokenCall: (value: BigNumber) => Promise<void>
  onUnwrapTokenCall: (address: string, value: BigNumber) => Promise<void>
  onSetTotalTenderAmount?: (totalTenderAmount: FixedNumber) => void
}) {
  const termToast = useTermToast()
  const currentTime = useCurrentTime()
  const chainConfig = useChainConfig(auction.chainId)
  const startTime = dayjs.unix(auctionStartTimestamp)
  const closeTime = dayjs.unix(auctionEndTimestamp)
  const auctionOpen = currentTime < closeTime && currentTime >= startTime
  const duration = dayjs.duration(closeTime.diff(currentTime))
  const diffFormatted = `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`

  const parsedTenders = useMemo(
    () =>
      tenders.map((draft) =>
        parseLoanTender(
          draft,
          purchaseCurrency.decimals,
          auction.minOfferAmount,
          purchaseTokenBalance.add(
            fixedToBigNumber(gasTokenBalance, gasTokenBalance.format.decimals)
          ),
          FixedNumber.fromString('0', 18),
          auction.maxOfferPrice
            ? FixedNumber.fromValue(auction.maxOfferPrice.mul(100), 18)
            : FixedNumber.fromString('0', 18)
        )
      ),
    [
      auction.maxOfferPrice,
      auction.minOfferAmount,
      purchaseCurrency.decimals,
      purchaseTokenBalance,
      gasTokenBalance,
      tenders,
    ]
  )
  const totalLending = useMemo(
    () =>
      FixedNumber.fromValue(
        parsedTenders.reduce(
          (acc, tender) => acc.add(tender.amount.value),
          BigNumber.from(0)
        ),
        purchaseCurrency.decimals,
        `fixed128x${purchaseCurrency.decimals}`
      ),
    [parsedTenders, purchaseCurrency.decimals]
  )

  // for vault constraints:
  useEffect(() => {
    if (onSetTotalTenderAmount && totalLending) {
      onSetTotalTenderAmount(totalLending)
    }
  }, [onSetTotalTenderAmount, totalLending])

  const approvalAmount = useMemo(
    () =>
      fixedToBigNumber(
        tenders
          ?.filter((x) => !!x)
          ?.reduce(
            (acc, cur) => {
              try {
                return add(
                  acc,
                  FixedNumber.fromString(
                    cur.amount.replace(',', '') || '0',
                    `fixed128x${purchaseCurrency?.decimals ?? 18}`
                  )
                )
              } catch (e) {
                console.warn('Failed to calculate approvalAmount', e)
                return acc
              }
            },
            FixedNumber.fromString(
              '0',
              `fixed128x${purchaseCurrency?.decimals ?? 18}`
            )
          )
      ),
    [purchaseCurrency.decimals, tenders]
  )

  const [wrapping, setWrapping] = useState(false)
  const [hasWrapped, setHasWrapped] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  // amount to wrap for tender = (tendered amount - balance of wrapped gas token)
  const amountToWrap: BigNumber = useMemo(() => {
    const totalLendingBN = fixedToBigNumber(
      totalLending,
      totalLending.format.decimals
    )
    if (
      purchaseCurrency.symbol === gasTokenCurrency.wrappedGasSymbol &&
      totalLendingBN.gt(purchaseTokenBalance)
    ) {
      setHasWrapped(false)
      return totalLendingBN.sub(purchaseTokenBalance)
    } else {
      return BigNumber.from(0)
    }
  }, [purchaseCurrency, gasTokenCurrency, totalLending, purchaseTokenBalance])
  const amountToWrapFN = bigToFixedNumber(
    amountToWrap,
    gasTokenCurrency.decimals
  )

  const handleWrappingStatusChange = (wrapping: boolean) => {
    setWrapping(wrapping)
  }

  const handleWrappedStatusChange = (hasWrapped: boolean) => {
    setHasWrapped(hasWrapped)
  }

  const showWrapGasButton =
    purchaseCurrency.symbol === gasTokenCurrency.wrappedGasSymbol &&
    (hasWrapped || amountToWrap.gt(0))

  const bombPotAuction =
    auction.bombPotAmount &&
    auction.bombPotAuction &&
    auction.bombPotRewardTokenSymbol

  return (
    <SummaryContainer>
      <SummaryTitle>Offer Summary</SummaryTitle>
      <SummaryBody>
        <Stack spacing="4px" w="full">
          <SummaryStat>
            <SummaryHeader
              text={`${
                parsedTenders.length > 1
                  ? 'Total Supply Amount'
                  : 'Supply Amount'
              }`}
              rightElement={purchaseCurrency.symbol}
            />
            <SummaryDetails
              text={formatFixedToken(
                totalLending,
                purchaseCurrency.symbol,
                true
              )}
            />
          </SummaryStat>
          <SummaryStat>
            <SummaryHeader text="Maturity" />
            <SummaryDetails
              text={
                isDataLoading ? (
                  <Skeleton w="100px" h={4} />
                ) : (
                  formatMaturityDate(maturityDate)
                )
              }
            />
          </SummaryStat>
          <SummaryStat>
            <SummaryHeader text="Term" />
            <SummaryDetails
              text={
                isDataLoading ? (
                  <Skeleton w="100px" h={4} />
                ) : (
                  getAuctionTermString(auctionEndTimestamp, maturityDate)
                )
              }
            />
          </SummaryStat>
          {bombPotAuction && (
            <SummaryStat>
              <HStack>
                <SummaryHeader
                  text="Bomb Pot Available"
                  rightElement={(
                    auction.bombPotRewardTokenSymbol as string
                  ).toUpperCase()}
                />
                <BombPot w="12px" />
              </HStack>
              <SummaryDetails text={auction.bombPotAmount} />
            </SummaryStat>
          )}
          {vaultConstraints && (
            <LoanVaultSummary
              isDataLoading={isDataLoading}
              vaultSummary={vaultConstraints}
            />
          )}
        </Stack>
        {tenders.length > 1 &&
          parsedTenders.map((tender, index) => (
            <Stack key={index} spacing={1} w="full">
              {tenders.length > 1 && (
                <TenderNumberHeading tenderNumber={index + 1} />
              )}
              <SummaryStat>
                <SummaryHeader
                  text="Supply Amount"
                  rightElement={purchaseCurrency.symbol}
                />
                <SummaryDetails
                  text={
                    tender.amount.isValid
                      ? formatFixedToken(
                          FixedNumber.fromValue(
                            tender.amount.value,
                            purchaseCurrency.decimals,
                            `fixed128x${purchaseCurrency.decimals}`
                          ),
                          purchaseCurrency.symbol,
                          true
                        )
                      : '-'
                  }
                />
              </SummaryStat>
            </Stack>
          ))}
        <Divider borderColor="gray.2" mt="8px" mb="8px" />
        {wallet.status === 'not-connected' ? (
          <>
            <SummaryFootnote
              warning={true}
              text="Connect your wallet to participate in this auction."
            />
            <Button
              variant="primary"
              size="sm"
              w="full"
              mt="8px"
              onClick={onConnect}
            >
              Connect wallet
            </Button>
          </>
        ) : (
          <>
            {vaultConstraints?.some((item) => !!item.warning) ? (
              <SummaryFootnote
                warning
                text="Drafted offers would violate vault constraints."
              />
            ) : (
              <SummaryFootnote
                text={
                  approvalAmount.gt(purchaseTokenAllowance)
                    ? `Approve ${purchaseCurrency.symbol} to start submitting offers.`
                    : `Your ${purchaseCurrency.symbol} will be locked until the auction clears. To unlock, delete your offer from the table below.`
                }
              />
            )}
            {chainConfig &&
              LOW_LIQUIDITY_ASSETS.includes(
                collateralCurrency.address.toLowerCase()
              ) && (
                <InfoBox kind="warning">
                  <Text color="gray.6">
                    This{' '}
                    <Link
                      color="blue.4"
                      textDecoration="none"
                      href={
                        chainConfig.blockExplorerUrl +
                        '/token/' +
                        collateralCurrency.address
                      }
                      isExternal
                    >
                      collateral asset
                    </Link>{' '}
                    may have limited liquidity. Do your own research before
                    participating.
                  </Text>
                </InfoBox>
              )}
            {!!referralCode && (
              <SummaryReferralCode
                referralCode={referralCode}
                openConfirmDeleteReferralCodeModal={
                  openConfirmDeleteReferralCodeModal
                }
              />
            )}
            <VStack spacing="8px" w="full">
              {showWrapGasButton && (
                <WrapGasButton
                  isWrapButtonDisabled={
                    vaultConstraints?.some(
                      (constraint) => !!constraint.warning
                    ) ||
                    wrapping ||
                    hasWrapped ||
                    !auctionOpen ||
                    !every(
                      tenders,
                      (tender: DraftLoanTender) =>
                        parseLoanTender(
                          tender,
                          purchaseCurrency.decimals,
                          auction.minOfferAmount,
                          purchaseTokenBalance.add(
                            fixedToBigNumber(
                              gasTokenBalance,
                              gasTokenBalance.format.decimals
                            )
                          ),
                          FixedNumber.fromString('0', 18),
                          auction.maxOfferPrice
                            ? FixedNumber.fromValue(
                                auction.maxOfferPrice.mul(100),
                                18
                              )
                            : FixedNumber.fromString('0', 18)
                        ).isValid
                    )
                  }
                  amountToWrap={amountToWrapFN}
                  gasTokenCurrency={gasTokenCurrency}
                  onWrap={async () => {
                    await onWrapGasTokenCall(amountToWrap)
                  }}
                  onWrappingStatusChange={handleWrappingStatusChange}
                  onWrappedStatusChange={handleWrappedStatusChange}
                />
              )}
              <SubmitApproveButton
                step={
                  showWrapGasButton && approvalAmount.gt(purchaseTokenAllowance)
                    ? 2
                    : 1
                }
                forcefullyShowStep={showWrapGasButton ? true : false}
                approvingCurrencySymbol={purchaseCurrency.symbol}
                onApprove={onApprovePurchaseToken}
                onSubmit={async () => {
                  setSubmitting(true)
                  termToast.pending(
                    termToastMessages.bidOrOffer.pending(
                      'offer',
                      tenders.length
                    )
                  )
                  try {
                    await submitTenders()
                    termToast.success(
                      termToastMessages.bidOrOffer.success(
                        'offer',
                        tenders.length,
                        diffFormatted
                      )
                    )
                  } catch (error) {
                    if (
                      (error as Error).message.includes(
                        'user rejected transaction'
                      )
                    ) {
                      termToast.dismissed()
                    } else {
                      termToast.failure(
                        termToastMessages.bidOrOffer.failure(
                          'offer',
                          tenders.length
                        )
                      )
                    }
                  } finally {
                    setSubmitting(false)
                    setHasWrapped(false)
                  }
                }}
                isDisabled={
                  vaultConstraints?.some(
                    (constraint) => !!constraint.warning
                  ) ||
                  wrapping ||
                  (amountToWrap.gt(0) && !hasWrapped) ||
                  !auctionOpen ||
                  !every(
                    tenders,
                    (tender: DraftLoanTender) =>
                      parseLoanTender(
                        tender,
                        purchaseCurrency.decimals,
                        auction.minOfferAmount,
                        purchaseTokenBalance.add(
                          fixedToBigNumber(
                            gasTokenBalance,
                            gasTokenBalance.format.decimals
                          )
                        ),
                        FixedNumber.fromString('0', 18),
                        auction.maxOfferPrice
                          ? FixedNumber.fromValue(
                              auction.maxOfferPrice.mul(100),
                              18
                            )
                          : FixedNumber.fromString('0', 18)
                      ).isValid
                  ) ||
                  referralCodeIsConnectedAddress
                }
                approvalAmount={approvalAmount}
                submitApprovalAmount={true}
                approvedAmount={purchaseTokenAllowance}
                submitLabel={
                  tenders?.length > 1 ? 'Submit offers' : 'Submit offer'
                }
                onKytCheck={onKytCheck}
                submitTooltip={
                  referralCodeIsConnectedAddress && (
                    <Tooltip
                      label={
                        'Using your connected wallet’s address as a referral is not possible.'
                      }
                    >
                      <Box pos="absolute" top="0" left="0" w="100%" h="100%" />
                    </Tooltip>
                  )
                }
              />
              {!approvalAmount.gt(purchaseTokenAllowance) && (
                <ClearButton
                  onClick={clearTenders}
                  isDisabled={
                    submitting || isEqual(tenders, [DEFAULT_LOAN_TENDER])
                  }
                  label={tenders?.length > 1 ? 'Clear all' : 'Clear'}
                />
              )}
            </VStack>
          </>
        )}
      </SummaryBody>
    </SummaryContainer>
  )
}
