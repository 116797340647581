import { Box, Image } from '@chakra-ui/react'
import { useCallback } from 'react'

import vaultUSDC from '../../../../assets/icons/tokens/USDC_VAULT.svg'
import vaultWETH from '../../../../assets/icons/tokens/WETH_VAULT.svg'

export default function VaultTokenIcon({ symbol }: { symbol: string }) {
  const vaultTokenImage = useCallback(() => {
    switch (symbol?.toUpperCase()) {
      case 'WETH':
        return {
          icon: vaultWETH,
          bg: '#E4E6EB',
        }
      case 'USDC':
      default:
        return {
          icon: vaultUSDC,
          bg: '#3E73C4',
        }
    }
  }, [symbol])

  return (
    <Box bg={vaultTokenImage().bg} w="122px" h="125px" p={2.5}>
      <Image w="full" h="full" src={vaultTokenImage().icon} />
    </Box>
  )
}
