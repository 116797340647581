import { Box, Heading, Skeleton, Text, HStack, Button } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FixedNumber } from 'ethers'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'
import { LineChartVault } from './LineChartVault'
import { HistoricalPerformanceDataChart } from '../../../../models/metaVault'

const TIME_RANGES = {
  '1D': 86400, // 24 * 60 * 60
  '1W': 604800, // 7 * 24 * 60 * 60
  '1M': 2592000, // 30 * 24 * 60 * 60
  '3M': 7776000, // 90 * 24 * 60 * 60
  '6M': 15552000, // 180 * 24 * 60 * 60
  '1Y': 31536000, // 365 * 24 * 60 * 60
  '3Y': 94608000, // 3 * 365 * 24 * 60 * 60
}

export const TIME = [
  {
    value: TIME_RANGES['1M'],
    label: '1M',
  },
  {
    value: TIME_RANGES['3M'],
    label: '3M',
  },
  {
    value: TIME_RANGES['6M'],
    label: '6M',
  },
  {
    value: TIME_RANGES['1Y'],
    label: '1Y',
  },
  {
    value: TIME_RANGES['3Y'],
    label: '3Y',
  },
]

export default function HistoricalPerformanceChart({
  isDataLoaded,
  currentYield,
  dataChart,
  setSelectedTime,
  selectedTime = 0,
  metaVaultAssetCurrencySymbol,
}: {
  isDataLoaded?: boolean
  currentYield: FixedNumber
  dataChart: HistoricalPerformanceDataChart[]
  setSelectedTime?: (value: number) => void
  selectedTime?: number
  metaVaultAssetCurrencySymbol: string
}) {
  const { formattedPercentage: formattedYieldPercentage } =
    fixedToFormattedPercentage(currentYield, 2, true)

  return (
    <Box w="407px" bg="white" borderRadius="8px" p={5}>
      <Trans>
        <Heading variant="heading/h1">Historical Performance</Heading>
        <Text fontWeight="500" fontSize="12px" pt={5}>
          Current Yield
        </Text>
      </Trans>

      <Text fontSize="20px" color="#000000" fontWeight="500" lineHeight="32px">
        {formattedYieldPercentage}%
      </Text>

      {!isDataLoaded ? (
        <Skeleton isLoaded={isDataLoaded} h="160px" w="100%"></Skeleton>
      ) : (
        <Box bg="white" p={0} mt={6} w="100%">
          <LineChartVault
            dataChart={dataChart}
            metaVaultAssetCurrencySymbol={metaVaultAssetCurrencySymbol}
          />
        </Box>
      )}

      <HStack>
        {TIME.map((time) => (
          <Button
            key={time.value}
            variant="ghost"
            w="58px"
            h="28px"
            fontSize="10px"
            fontWeight="500"
            border="1px solid #E0E0E0"
            borderRadius="30px"
            color={selectedTime === time.value ? 'white' : 'blue.8'}
            bg={selectedTime === time.value ? 'blue.10' : 'white'}
            _hover={{ bg: 'blue.10', color: 'white' }}
            onClick={() => setSelectedTime && setSelectedTime(time.value)}
          >
            {time.label}
          </Button>
        ))}
      </HStack>
    </Box>
  )
}
