import august from '../assets/icons/curators/august.svg'
import edge from '../assets/icons/curators/edge.svg'
import k3 from '../assets/icons/curators/k3.svg'
import mev from '../assets/icons/curators/mev.svg'
import re7 from '../assets/icons/curators/re7.svg'
import shorewoods from '../assets/icons/curators/shorewoods.svg'

import yearn from '../assets/icons/tokens/YEARN.svg'

import unknown from '../assets/icons/error.svg'

const vaultIcons: {
  [vaultName: string]: string
} = {
  RE7: re7,
  YEARN: yearn,
  AUGUST: august,
  SHOREWOODS: shorewoods,
  MEV: mev,
  EDGE: edge,
  K3: k3,

  unknown: unknown,
}

export default vaultIcons
