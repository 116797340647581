import dayjs from 'dayjs'
import { BigNumber, Contract, FixedNumber } from 'ethers'
import { commify, solidityKeccak256 } from 'ethers/lib/utils'
import {
  PageAuctionQuery,
  PageAuctionsQuery,
  PagePortfolioQuery,
  TermAuction,
  TermRepo,
} from '../gql/graphql'
import {
  Auction,
  Address,
  Currency,
  RolloverAuctionInfo,
  TermPeriod,
  TermRepoCurrency,
} from '../data/model'
import { evaluate, multiply } from './math'
import { SUPPORTED_ABI_VERSIONS, SUPPORTED_VAULT_TOKENS } from './constants'
import { memoize } from 'lodash'

export function convertChainId(chainId: any): number {
  const numericChainId = Number(chainId)
  if (Number.isNaN(numericChainId)) {
    throw new Error(`Invalid chainId: ${chainId}`)
  }
  return numericChainId
}

// Currently setup to manually verify 4626 against a pre-defined list of tokens
export function isVaultToken(symbol: string | undefined): boolean {
  return symbol
    ? SUPPORTED_VAULT_TOKENS.map((token) => token.toUpperCase()).includes(
        symbol.toUpperCase()
      )
    : false
}

export function getABIVersion(contractVersion?: string) {
  return contractVersion &&
    SUPPORTED_ABI_VERSIONS.hasOwnProperty(contractVersion)
    ? SUPPORTED_ABI_VERSIONS[contractVersion]
    : '0.6.0'
}

export function isTermRepoCurrency(
  currency: Currency
): currency is TermRepoCurrency {
  return (currency as TermRepoCurrency).isRepoToken !== false
}

///@dev When a new tender is submitted to chain, the id is generated on chain using the following predictable hash function.
export function getGeneratedTenderId(
  input: string,
  contract: Contract,
  walletAddress: string
) {
  return solidityKeccak256(
    ['bytes32', 'address', 'address'],
    [input, walletAddress, contract.address.toLowerCase()]
  )
}

export function fixedToBigNumber(fn: FixedNumber, decimals?: number) {
  return BigNumber.from(
    evaluate(
      {
        nodeKind: 'mul',
        args: [
          {
            nodeKind: 'value',
            value: fn,
          },
          {
            nodeKind: 'value',
            value: FixedNumber.fromString(
              '1' + '0'.repeat(decimals ?? fn.format.decimals),
              fn.format
            ),
          },
        ],
      },
      0
    )
      .toString()
      .split('.')[0]
  )
}

export function bigToFixedNumber(
  bn: BigNumber,
  decimals: number,
  format?: string
) {
  return FixedNumber.fromValue(bn, decimals, format ?? `fixed128x${decimals}`)
}

export function padDecimals(amount: string, decimals: number) {
  const [wholePart, decimalPart] = amount.split('.')
  if (decimals === 0) {
    return `${wholePart}`
  }
  return `${wholePart}.${decimalPart}${'0'.repeat(
    Math.max(decimals - decimalPart.length, 0)
  )}`
}

// Return true if the original value does not match the formatted value,
// and if the formatted value consists of only zeroes
export function isRounded(origValue: string, formattedValue: string) {
  return (
    origValue !== formattedValue &&
    parseFloat(origValue) !== parseFloat(formattedValue)
  )
}

export function hasOnlyZeroes(value: string) {
  return value
    .replace(/[^0-9]/g, '')
    .split('')
    .every((c) => c === '0')
}

export function formatFixed(
  value: FixedNumber | undefined,
  {
    displayDecimals = 2,
    prefix,
    suffix,
  }: { prefix?: string; suffix?: string; displayDecimals?: number } = {}
) {
  if (!value) {
    return ['-', false, false] as [string, boolean, boolean]
  }
  const origValue = value.toString()
  const roundedValue = padDecimals(
    value?.round(displayDecimals)?.toString(),
    displayDecimals
  )
  const isValueRounded = isRounded(origValue, roundedValue)
  const onlyZeroes = hasOnlyZeroes(roundedValue)

  if (value.isNegative()) {
    const formattedValue = `-${prefix ?? ''}${padDecimals(
      commify(
        evaluate(
          {
            nodeKind: 'mul',
            args: [
              {
                nodeKind: 'value',
                value: value,
              },
              {
                nodeKind: 'value',
                value: FixedNumber.fromString('-1', value.format),
              },
            ],
          },
          displayDecimals
        )?.toString()
      ),
      displayDecimals
    )}${suffix ?? ''}`
    return [formattedValue, isValueRounded, onlyZeroes] as [
      string,
      boolean,
      boolean,
    ]
  }
  const formattedValue = `${!prefix ? '' : prefix}${padDecimals(
    commify(value?.round(displayDecimals)?.toString()),
    displayDecimals
  )}${suffix ? '' + suffix : ''}`
  return [formattedValue, isValueRounded, onlyZeroes] as [
    string,
    boolean,
    boolean,
  ]
}

export function fixedToFormattedPercentage(
  percentageValue: FixedNumber,
  displayDecimals?: number,
  hideSymbol?: boolean,
  isMultiply?: boolean
): {
  formattedPercentage: string
  isValueRounded: boolean
  onlyZeroes: boolean
} {
  if (isMultiply) {
    percentageValue = multiply(
      percentageValue,
      FixedNumber.fromString('100', percentageValue?.format)
    )
  }

  const [formattedPercentage, isValueRounded, onlyZeroes] = formatFixed(
    percentageValue,
    {
      displayDecimals: displayDecimals ?? 2,
      suffix: hideSymbol ? undefined : '%',
    }
  )

  // Remove the 0 if the only decimal place is 0
  const formattedPercentageNoZero = formattedPercentage.replace(/\.0\b/g, '')

  return {
    formattedPercentage: `${
      onlyZeroes && isValueRounded ? `~` : ``
    }${formattedPercentageNoZero}`,
    onlyZeroes,
    isValueRounded,
  }
}

export function graphResultToAuction(
  chainId: string,
  result: (
    | PageAuctionQuery
    | PageAuctionsQuery
    | PagePortfolioQuery
  )['termAuctions'][0]
): Auction {
  const servicingFeeRaw = BigNumber.from(result.term?.servicingFee ?? '0')
  const dayCountFractionMantissa = BigNumber.from(
    result.dayCountFractionMantissa ?? '0'
  )
  const servicingFee = servicingFeeRaw
    .mul(dayCountFractionMantissa)
    .div(BigNumber.from('1' + '0'.repeat(18)))

  return {
    chainId,
    version: result.version ?? '0.2.4',
    address: result.auction ?? '',
    bidLockerAddress: result.auctionBidLocker ?? '',
    offerLockerAddress: result.auctionOfferLocker ?? '',
    loanManagerAddress: result.term?.termRepoServicer ?? '',
    collateralManagerAddress: result.term?.termRepoCollateralManager ?? '',
    repoLockerAddress: result.term?.termRepoLocker ?? '',
    auctionStartTimestamp: result.auctionStartTime ?? 0,
    auctionRevealTimestamp: result.revealTime ?? 0,
    auctionEndTimestamp: result.auctionEndTime ?? 0,
    maturityTimestamp: result.term?.repurchaseTimestamp ?? 0,
    purchaseCurrency: result.term?.purchaseToken ?? '',
    purchaseCurrencyDecimals: result.term?.purchaseTokenMeta?.decimals ?? 0,
    // termCurrencyOraclePriceUSDC: await priceOracle.getLatestPrice(termCurrency),
    purchaseCurrencyOraclePriceUSDC: BigNumber.from(0), // Filled in later.
    purchaseCurrencyOraclePriceDecimals: 0, // Filled in later.
    collateralCurrency: (result.term?.collateralTokens ?? [])[0] ?? '',
    collateralCurrencyDecimals:
      result.term?.collateralTokensMeta?.[0]?.decimals ?? 0,
    collateralCurrencyOraclePriceUSDC: BigNumber.from(0), // Filled in later.
    // collateralCurrencyOraclePriceUSDC: await priceOracle.getLatestPrice(
    //   collateralCurrency
    // ),
    collateralCurrencyOraclePriceDecimals: 0, // Filled in later.
    collateralLiquidatedDamages:
      result.term?.liquidatedDamagesSchedule?.[0].liquidatedDamages,
    initialMarginRatio: result.term?.collateralRatios?.[0]?.initialRatio
      ? FixedNumber.fromValue(
          result.term.collateralRatios?.[0]?.initialRatio,
          18
        )
      : FixedNumber.fromString('0'),
    maintenanceMarginRatio: result.term?.collateralRatios?.[0]?.maintenanceRatio
      ? FixedNumber.fromValue(
          result.term.collateralRatios?.[0]?.maintenanceRatio,
          18
        )
      : FixedNumber.fromString('0'),
    termId: result.term?.id ?? '',
    termRepoTokenAddress: result.term?.termRepoToken ?? '',
    auctionId: result.id ?? '',

    cancelled: result.auctionCancelled ?? false,
    cancelledForWithdrawal: result.auctionCancelledForWithdrawal ?? false,
    closed: result.auctionComplete ?? false,

    auctionClearingRate: BigNumber.from(result.auctionClearingPrice ?? '0'),

    dayCountFractionMantissa: dayCountFractionMantissa,
    maxBidPrice: BigNumber.from(result.auctionMaxBidPrice ?? '0'),
    maxOfferPrice: BigNumber.from(result.auctionMaxOfferPrice ?? '0'),
    minBidAmount: BigNumber.from(result.auctionMinBidAmount ?? '0'),
    minOfferAmount: BigNumber.from(result.auctionMinOfferAmount ?? '0'),
    servicingFee: servicingFee,
  }
}

export function graphResultToRolloverAuction(
  chainId: string,
  result: Partial<TermRepo> | undefined,
  currencies: { [address: Address]: Currency } | undefined,
  now: dayjs.Dayjs
): RolloverAuctionInfo[] | undefined {
  return result?.approvedRolloverTermAuctions
    ?.filter(
      (rolloverAuction) =>
        !rolloverAuction.auctionCancelled &&
        !rolloverAuction.auctionComplete &&
        !!rolloverAuction.auctionEndTime &&
        now.isBefore(dayjs.unix(rolloverAuction.auctionEndTime))
    )
    ?.map(
      (rolloverAuction: Partial<TermAuction>) =>
        ({
          chainId,
          id: rolloverAuction.id ?? '',
          version: rolloverAuction.version ?? '0.2.4',
          auction: rolloverAuction.auction ?? '',
          auctionBidLocker: rolloverAuction.auctionBidLocker ?? '',
          auctionOfferLocker: rolloverAuction.auctionOfferLocker ?? '',
          rolloverManagerAddress: result.termRepoRolloverManager ?? '',
          maturityTimestamp: rolloverAuction.term?.repurchaseTimestamp ?? 0,
          endOfRepurchaseWindowTimestamp:
            rolloverAuction.term?.endOfRepurchaseWindow ?? 0,
          auctionEndTimestamp: rolloverAuction.auctionEndTime ?? 0,
          auctionComplete: rolloverAuction.auctionComplete ?? false,
          marginRequirement:
            bigToFixedNumber(
              rolloverAuction.term?.collateralRatios?.[0].maintenanceRatio,
              18
            ) ?? FixedNumber.from(0),
          servicingFee: BigNumber.from(
            rolloverAuction.term?.servicingFee ?? '0'
          )
            .mul(
              BigNumber.from(rolloverAuction.dayCountFractionMantissa ?? '0')
            )
            .div(BigNumber.from('1' + '0'.repeat(18))),
          purchaseCurrencySymbol:
            currencies?.[result.purchaseToken]?.symbol ?? '',
          collateralCurrencySymbol:
            currencies?.[result.collateralTokens?.[0]]?.symbol ?? '',

          newTermId: rolloverAuction.term?.id ?? '',
          newTermRepoServicer: rolloverAuction.term?.termRepoServicer ?? '',
          oldTermId: result.id ?? '',
        }) as RolloverAuctionInfo
    )
}

export function graphResultToTerm(
  chainId: string,
  result: Partial<TermRepo>
): TermPeriod {
  const sortCompletedAuctions = memoize((completedAuctions: TermAuction[]) =>
    completedAuctions.sort((a, b) => b.auctionEndTime - a.auctionEndTime)
  )

  return {
    chainId,
    id: result.id ?? '',
    version: result.version ?? '0.2.4',
    repoServicerAddress: result.termRepoServicer ?? '',
    collateralManagerAddress: result.termRepoCollateralManager ?? '',
    termRepoLockerAddress: result.termRepoLocker ?? '',
    termRepoTokenAddress: result.termRepoToken ?? '',
    rolloverManagerAddress: result.termRepoRolloverManager ?? '',
    maturityTimestamp: result.repurchaseTimestamp ?? 0,
    redemptionTimestamp: result.redemptionTimestamp ?? 0,
    endOfRepaymentWindowTimestamp: result.endOfRepurchaseWindow ?? 0,
    purchaseCurrency: {
      address: result.purchaseToken ?? '',
      decimals: result.purchaseTokenMeta?.decimals ?? 0,
      symbol: result.purchaseTokenMeta?.symbol ?? '',
      isRepoToken: false,
    },
    collateralCurrency: {
      address: result.collateralTokens?.[0] ?? '',
      decimals: result.collateralTokensMeta?.[0]?.decimals ?? 0,
      symbol: result.collateralTokensMeta?.[0]?.symbol ?? '',
      isRepoToken: false,
    },
    termRepoTokenCurrency: {
      address: result.termRepoToken ?? '',
      decimals: result.termRepoTokenMeta?.decimals ?? 0,
      symbol: result.termRepoTokenMeta?.symbol ?? '',
      redemptionTimestamp: result.redemptionTimestamp ?? 0,
      purchaseToken: result.purchaseToken ?? '',
      isRepoToken: true,
    },
    // Open borrow positions will read the latest completed auction for auction specific data
    completedAuctions: result.completedAuctions
      ? sortCompletedAuctions(result.completedAuctions).map(
          (completeAuctions) => {
            return {
              chainId,
              auctionId: completeAuctions.id,
              version: completeAuctions.version,
              address: completeAuctions.auction,
              bidLockerAddress: completeAuctions.auctionBidLocker,
              offerLockerAddress: completeAuctions.auctionOfferLocker,
              auctionStartTimestamp: completeAuctions.auctionStartTime,
              auctionEndTimestamp: completeAuctions.auctionEndTime,
              closed: completeAuctions.auctionComplete,
              cancelled: completeAuctions.auctionCancelled,
              auctionClearingRate: BigNumber.from(
                completeAuctions.auctionClearingPrice ?? '0'
              ),
            } as Partial<Auction>
          }
        )
      : undefined,
  }
}

export function graphAuctionResultToPurchaseCurrency(
  result: Pick<TermRepo, 'purchaseToken' | 'purchaseTokenMeta'>
): Currency {
  return {
    address: result.purchaseToken ?? '',
    decimals: result.purchaseTokenMeta?.decimals ?? 0,
    symbol: result.purchaseTokenMeta?.symbol ?? '',
    isRepoToken: false,
  }
}

export function graphAuctionResultToCollateralCurrencies(
  result: Pick<TermRepo, 'collateralTokens' | 'collateralTokensMeta'>
): Currency {
  return {
    address: result.collateralTokens?.[0] ?? '',
    decimals: result.collateralTokensMeta?.[0]?.decimals ?? 0,
    symbol: result.collateralTokensMeta?.[0]?.symbol ?? '',
    isRepoToken: false,
  }
}
