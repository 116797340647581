import { Address } from '../data/model'
import { SubgraphVersion } from '../helpers/constants'
import {
  PageAuctionDocument,
  LastAuctionClearingPrices,
} from '../queries/page-auction'
import { PageAuctionsDocument } from '../queries/page-auctions'
import { PagePortfolioDocument } from '../queries/page-portfolio'
import { PageRewardsDocument } from '../queries/page-rewards'
import {
  PageLendDocument,
  LendTokensDropdownDocument,
} from '../queries/page-lend'
import { ActiveListingsDocument } from '../queries/listings/listings'
import { PortfolioListingsDocument } from '../queries/listings/portfolio-listings'
import { PageVaultsDocument } from '../queries/vaults/page-vaults'
import { PageVaultDocument } from '../queries/vaults/page-vault'
import { TermRepoTokenHoldingsDocument } from '../queries/term-repo-token-holdings'
import { PageMetaVaultDocument } from '../queries/vaults/page-meta-vault'
import { VaultOpenOffersDocument } from '../queries/vault-open-offers'

// TODO: re-enable this when mocha is fixed
// enum SubgraphVersion {
//   Latest = 'latest',
//   // ... add more as needed
// }

enum DocumentType {
  PAGE_AUCTION = 'page-auction',
  PAGE_AUCTIONS = 'page-auctions',
  PAGE_PORTFOLIO = 'page-portfolio',
  PAGE_REWARDS = 'page-rewards',
  PAGE_LEND = 'page-lend',
  LISTINGS = 'listings',
  PORTFOLIO_LISTINGS = 'portfolio-listings',
  COMPONENT_LAST_AUCTION_PRICE = 'component-last-auction-price',
  LEND_TOKENS_DROPDOWN = 'lend-tokens-dropdown',
  TERM_REPO_TOKEN_HOLDINGS = 'term-repo-token-holdings',
  PAGE_VAULTS = 'page-vaults',
  PAGE_VAULT = 'page-vault',
  PAGE_META_VAULT = 'page-meta-vault',
  VAULT_OPEN_OFFERS = 'vault-open-offers',
  // ... add more as needed
}

// Latest Graph Query Variables
type AuctionVariablesLatest = {
  auction: string
  wallet: string | undefined
}

type LastAuctionClearingPricesVariablesLatest = {
  purchaseToken: string
  collateralToken: string
  currentTimestamp: number
  thirtyDaysAgoTimestamp: number
}

type AuctionsVariablesLatest = {
  currentTime: number
  lastAuctionCutOff: number
}

type PortfolioVariablesLatest = {
  wallet: string
  recordsToFetch: number
  lastAuctionsId: string
  lastReposId: string
  lastPurchasesId: string
  lastRepoExposuresId: string
  lastRepoCollateralsId: string
  lastBidsId: string
  lastOffersId: string
  lastRepoTokenRedemptionsId: string
  lastRolloverInstructionsId: string
}

type RewardsVariablesLatest = {
  seasonStart: number
  lenders: Address[]
  currentTimestamp: number
}

type LendVariablesLatest = {
  purchaseToken: string
  currentTime: number
}

type ListingsVariablesLatest = {
  tokenList: string[]
}

type PortfolioListingsVariablesLatest = {
  wallet: Address
}

type LendTokensDropdownVariablesLatest = {
  currentTime: number
}

type TermRepoTokenHoldingsVariablesLatest = {
  termRepoTokens: Address[]
}

type VaultsVariablesLatest = {}

type VaultVariablesLatest = {
  id: string
}

type MetaVaultVariablesLatest = {
  id: string
}

type VaultOpenOffersVariablesLatest = {
  currentTime: number
  wallet: Address
}

export type QueryVariables =
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PAGE_AUCTION
      variables: AuctionVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.COMPONENT_LAST_AUCTION_PRICE
      variables: LastAuctionClearingPricesVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PAGE_AUCTIONS
      variables: AuctionsVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PAGE_PORTFOLIO
      variables: PortfolioVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PAGE_REWARDS
      variables: RewardsVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PAGE_LEND
      variables: LendVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.LISTINGS
      variables: ListingsVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PORTFOLIO_LISTINGS
      variables: PortfolioListingsVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.LEND_TOKENS_DROPDOWN
      variables: LendTokensDropdownVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.TERM_REPO_TOKEN_HOLDINGS
      variables: TermRepoTokenHoldingsVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PAGE_VAULTS
      variables: VaultsVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PAGE_VAULT
      variables: VaultVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.PAGE_META_VAULT
      variables: VaultVariablesLatest
    }
  | {
      subgraphVersion: SubgraphVersion.Latest
      docType: DocumentType.VAULT_OPEN_OFFERS
      variables: VaultOpenOffersVariablesLatest
    }

type GetQueryDocument = (
  subgraphVersion: SubgraphVersion,
  docType: DocumentType
) => any
type GetQueryVariables = (input: QueryVariables) => any

const getQueryDocument: GetQueryDocument = (
  subgraphVersion: SubgraphVersion,
  docType: DocumentType
) => {
  switch (subgraphVersion) {
    case SubgraphVersion.Latest:
      switch (docType) {
        case DocumentType.PAGE_AUCTION:
          return PageAuctionDocument
        case DocumentType.COMPONENT_LAST_AUCTION_PRICE:
          return LastAuctionClearingPrices
        case DocumentType.PAGE_AUCTIONS:
          return PageAuctionsDocument
        case DocumentType.PAGE_PORTFOLIO:
          return PagePortfolioDocument
        case DocumentType.PAGE_REWARDS:
          return PageRewardsDocument
        case DocumentType.PAGE_LEND:
          return PageLendDocument
        case DocumentType.LISTINGS:
          return ActiveListingsDocument
        case DocumentType.PORTFOLIO_LISTINGS:
          return PortfolioListingsDocument
        case DocumentType.LEND_TOKENS_DROPDOWN:
          return LendTokensDropdownDocument
        case DocumentType.TERM_REPO_TOKEN_HOLDINGS:
          return TermRepoTokenHoldingsDocument
        case DocumentType.PAGE_VAULTS:
          return PageVaultsDocument
        case DocumentType.PAGE_VAULT:
          return PageVaultDocument
        case DocumentType.PAGE_META_VAULT:
          return PageMetaVaultDocument
        case DocumentType.VAULT_OPEN_OFFERS:
          return VaultOpenOffersDocument
        default:
          throw new Error(
            `Unsupported document type for 'latest' version: ${docType}`
          )
      }
    // Add more versions here as necessary
    default:
      throw new Error(`Unsupported subgraph: ${subgraphVersion}`)
  }
}

const getQueryVariables: GetQueryVariables = (input: QueryVariables): any => {
  const { subgraphVersion, docType, variables } = input
  return _getQueryVariables(subgraphVersion, docType, variables)
}

// Private implementation
const _getQueryVariables = (
  subgraphVersion: SubgraphVersion,
  docType: DocumentType,
  variables: any
): any => {
  switch (subgraphVersion) {
    case SubgraphVersion.Latest:
      switch (docType) {
        case DocumentType.PAGE_AUCTION:
          return variables as AuctionVariablesLatest
        case DocumentType.COMPONENT_LAST_AUCTION_PRICE:
          return variables as LastAuctionClearingPricesVariablesLatest
        case DocumentType.PAGE_AUCTIONS:
          return variables as AuctionsVariablesLatest
        case DocumentType.PAGE_PORTFOLIO:
          return variables as PortfolioVariablesLatest
        case DocumentType.PAGE_REWARDS:
          return variables as RewardsVariablesLatest
        case DocumentType.PAGE_LEND:
          return variables as LendVariablesLatest
        case DocumentType.LISTINGS:
          return variables as ListingsVariablesLatest
        case DocumentType.PORTFOLIO_LISTINGS:
          return variables as PortfolioListingsVariablesLatest
        case DocumentType.LEND_TOKENS_DROPDOWN:
          return variables as LendTokensDropdownVariablesLatest
        case DocumentType.TERM_REPO_TOKEN_HOLDINGS:
          return variables as TermRepoTokenHoldingsVariablesLatest
        case DocumentType.PAGE_VAULTS:
          return variables as VaultsVariablesLatest
        case DocumentType.PAGE_VAULT:
          return variables as VaultVariablesLatest
        case DocumentType.PAGE_META_VAULT:
          return variables as MetaVaultVariablesLatest
        case DocumentType.VAULT_OPEN_OFFERS:
          return variables as VaultOpenOffersVariablesLatest
        default:
          throw new Error(
            `Unsupported document type for 'latest' version: ${docType}`
          )
      }
    // Add more versions here as necessary
    default:
      throw new Error(`Unsupported subgraph: ${subgraphVersion}`)
  }
}

export { DocumentType, SubgraphVersion, getQueryDocument, getQueryVariables }
