import { Heading, HStack, Skeleton, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FixedNumber } from 'ethers'
import { formatFixedUsd } from '../../../../helpers/utils'
import MetaVaultSvg from '../MetaVaultSvg'

type MetaVaultBannerProps = {
  isDataLoaded?: boolean
  totalDeposited?: FixedNumber
}

export default function MetaVaultBannerNoPosition({
  isDataLoaded = true,
  totalDeposited,
}: MetaVaultBannerProps) {
  return (
    <HStack
      w="full"
      bg="linear-gradient(98.18deg, #0057BA 23.86%, #5C3AD6 85.19%)"
      borderRadius="6px"
      color="white"
      overflow="hidden"
      p="32px 56px 60px 32px"
      justifyContent="space-between"
    >
      <VStack alignItems="left" spacing={2.5}>
        <VStack alignItems="left" spacing={2.5}>
          <Trans>
            <Text variant="body-sm/medium">Allocate your deposits with</Text>
            <Text fontWeight="bold" fontSize="56px" lineHeight="56px">
              Meta Vault
            </Text>
          </Trans>
        </VStack>

        <VStack
          alignItems="left"
          spacing={2.5}
          borderTop="1px solid #FFFFFF40"
          pt={2.5}
        >
          <Text variant="body-sm/medium">
            <Trans>Total deposited</Trans>
          </Text>

          <Skeleton isLoaded={isDataLoaded} h="29px" w="253px">
            <Heading variant="heading/h1">
              {formatFixedUsd(totalDeposited, false)}
            </Heading>
          </Skeleton>
        </VStack>
      </VStack>

      <MetaVaultSvg />
    </HStack>
  )
}
