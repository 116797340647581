import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address, Currency } from '../model'
import { Contract, FixedNumber } from 'ethers'
import { useEffect, useMemo } from 'react'
import { CallObject, MultichainCalls, useMultichainCalls } from './helper-hooks'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import { captureException } from '@sentry/react'
import { bigToFixedNumber, fixedToBigNumber } from '../../helpers/conversions'

export function useConvertToAssets(
  sharesToConvertByChain:
    | {
        [chainId: string]: {
          strategyAddress: Address
          shares: FixedNumber
          assetCurrency: Currency
        }[]
      }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  assetBalances:
    | {
        [chainId: string]: {
          [strategyAddress: string]:
            | { balance: FixedNumber; asset: Address }
            | null
            | undefined
        }
      }
    | undefined
} {
  const strategyContracts = useMemo(() => {
    const contracts: {
      [chainId: string]: {
        [strategyAddress: Address]: TermVaultStrategy
      }
    } = {}

    if (sharesToConvertByChain) {
      for (const [chainId, strategyAddressses] of Object.entries(
        sharesToConvertByChain
      )) {
        if (!strategyAddressses) continue

        contracts[chainId] = {}

        Object.values(strategyAddressses).forEach(({ strategyAddress }) => {
          if (!strategyAddress) return

          contracts[chainId][strategyAddress] = new Contract(
            strategyAddress,
            TermVaultStrategyABI,
            provider
          ) as TermVaultStrategy
        })
      }
    }

    return contracts
  }, [sharesToConvertByChain, provider])

  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (sharesToConvertByChain && strategyContracts) {
      // Flatten sharesToConvertByChain into a mapping for easier lookups
      const sharesMapping: {
        [chainId: string]: {
          [strategyAddress: Address]: FixedNumber
        }
      } = {}

      for (const [chainId, strategies] of Object.entries(
        sharesToConvertByChain
      )) {
        sharesMapping[chainId] = {}
        strategies.forEach(({ strategyAddress, shares }) => {
          if (strategyAddress) {
            sharesMapping[chainId][strategyAddress] = shares
          }
        })
      }

      Object.entries(strategyContracts).forEach(
        ([chainId, contractsByAddress]) => {
          const contracts = Object.entries(contractsByAddress)

          if (contracts.length > 0) {
            const convertToAssetsCalls = contracts
              .map(([strategyAddress, contract]) => {
                const shares = sharesMapping[chainId]?.[strategyAddress]
                if (!shares) {
                  return undefined
                }
                return {
                  contract,
                  method: 'convertToAssets',
                  args: [fixedToBigNumber(shares)],
                } as CallObject
              })
              .filter((call): call is CallObject => call !== undefined)

            calls[chainId] = convertToAssetsCalls
          }
        }
      )
    }

    return calls
  }, [sharesToConvertByChain, strategyContracts])

  const blockchainResponse = useMultichainCalls(contractCalls)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(blockchainResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [blockchainResponse])

  const blockchainErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResponse) {
      Object.entries(blockchainResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResponse])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const assetBalances = useMemo(() => {
    const result: {
      [chainId: string]: {
        [strategyAddress: Address]:
          | {
              balance: FixedNumber
              asset: Address
            }
          | undefined
          | null
      }
    } = {}

    if (blockchainResults && sharesToConvertByChain) {
      Object.entries(blockchainResults).forEach(([chainId, values]) => {
        result[chainId] = {}

        // Get strategyAddresses from sharesToConvertByChain
        const strategiesForChain = sharesToConvertByChain[chainId] ?? []
        const convertToAssetsValues = values.slice(0, strategiesForChain.length)

        convertToAssetsValues.forEach((value, index) => {
          const strategyAddress = strategiesForChain[index]?.strategyAddress
          const assetCurrency = strategiesForChain[index]?.assetCurrency

          if (strategyAddress && assetCurrency) {
            result[chainId][strategyAddress] = value
              ? {
                  balance: bigToFixedNumber(
                    value?.[0],
                    assetCurrency.decimals ?? 18
                  ),
                  asset: strategiesForChain[index]?.assetCurrency.address,
                }
              : undefined
          }
        })
      })
    }

    return result
  }, [blockchainResults, sharesToConvertByChain])

  return {
    assetBalances,
  }
}
