import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address, Auction, TermPeriod } from '../model'
import { useState, useEffect, useMemo } from 'react'
import { PageAuctionQuery } from '../../gql/graphql'
import { graphResultToAuction } from '../../helpers/conversions'
import { usePrices } from './use-prices'
import { useBombPots } from './use-bomb-pots'
import { captureException } from '@sentry/react'

export function useGraphAuction(
  chainId: string,
  address: Address,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  auctionsResult: PageAuctionQuery['termAuctions'] | undefined
): {
  auction: Auction | undefined
  term: Partial<TermPeriod> | undefined
} {
  const [auction, setAuction] = useState<Auction | undefined>()
  const [term, setTerm] = useState<Partial<TermPeriod> | undefined>()

  const bombPots = useBombPots(provider)

  const auctionsWithoutPrice = useMemo(
    () => auctionsResult?.map((a) => graphResultToAuction(chainId, a)),
    [auctionsResult, chainId]
  )

  useEffect(() => {
    if ((auctionsWithoutPrice?.length ?? 0) > 1) {
      const err = new Error(
        `More than one auction found for address ${address}. This should not happen.`
      )
      console.error(err)
      captureException(err)
    }
  }, [address, auctionsWithoutPrice?.length])

  // currency tokens grouped by chain - used to get prices
  const tokensGroupedByChain = useMemo(() => {
    const tokensByChain: {
      [chainId: string]: { address: string; decimals: number }[]
    } = {}

    auctionsResult?.forEach((auction) => {
      tokensByChain[chainId] = tokensByChain[chainId] || []

      const addToken = (tokenAddress: Address, tokenDecimals: number) => {
        if (
          tokenAddress &&
          !tokensByChain[chainId].some(
            (token) => token.address === tokenAddress
          )
        ) {
          tokensByChain[chainId].push({
            address: tokenAddress,
            decimals: tokenDecimals,
          })
        }
      }

      addToken(
        auction.term.purchaseToken,
        auction.term.purchaseTokenMeta?.decimals ?? 18
      )
      auction.term.collateralTokens?.forEach((token, index) => {
        addToken(token, auction.term.collateralTokensMeta?.[index]?.decimals)
      })
    })

    return tokensByChain
  }, [auctionsResult, chainId])

  const prices = usePrices(tokensGroupedByChain, undefined, provider)

  useEffect(() => {
    if (auctionsWithoutPrice?.[0]) {
      const auction = {
        ...auctionsWithoutPrice?.[0],
        purchaseCurrencyOraclePriceUSDC:
          prices?.[chainId]?.[0]?.price ??
          auctionsWithoutPrice?.[0].purchaseCurrencyOraclePriceUSDC,
        purchaseCurrencyOraclePriceDecimals:
          prices?.[chainId]?.[0]?.decimals ?? 18,
        collateralCurrencyOraclePriceUSDC:
          prices?.[chainId]?.[1]?.price ??
          auctionsWithoutPrice?.[0].collateralCurrencyOraclePriceUSDC,
        collateralCurrencyOraclePriceDecimals:
          prices?.[chainId]?.[1]?.decimals ?? 18,
      }

      if (bombPots) {
        const bombPot = bombPots[auction.auctionId]
        if (bombPot) {
          auction.bombPotAuction = true
          auction.bombPotAmount = Number(bombPot.reward)
          auction.bombPotRewardTokenSymbol = bombPot.rewardTokenSymbol
        }
      }

      setAuction(auction)
    }
  }, [auctionsWithoutPrice, prices, chainId, bombPots])

  useEffect(() => {
    if (auctionsResult && auctionsWithoutPrice?.[0]) {
      setTerm({
        chainId,
        id: auctionsResult[0].term.id,
        repoServicerAddress: auctionsResult[0].term.termRepoServicer,
        rolloverManagerAddress: auctionsResult[0].term.termRepoRolloverManager,
        collateralManagerAddress:
          auctionsResult[0].term.termRepoCollateralManager,
        termRepoLockerAddress: auctionsResult[0].term.termRepoLocker,
        redemptionTimestamp: auctionsResult[0].term.redemptionTimestamp,
        termRepoTokenAddress: auctionsResult[0].term.termRepoToken,
        completedAuctions: auctionsResult[0].term.completedAuctions?.map(
          (a) => {
            return {
              address: a.auction,
            }
          }
        ),
      })
    }
  }, [auctionsResult, auctionsWithoutPrice, chainId])

  return {
    auction,
    term,
  }
}
