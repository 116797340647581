import { ChakraProvider } from '@chakra-ui/react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import TermApp from './TermApp'
import { theme } from './chakra-theme'
import config from './config'
import './index.css'
import reportWebVitals from './reportWebVitals'

import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'
import '@fontsource/ibm-plex-mono/500.css'
import '@fontsource/ibm-plex-mono/700.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import SafeProvider from '@safe-global/safe-apps-react-sdk'
import { ErrorBoundary, withProfiler } from '@sentry/react'
import { DAppProvider } from '@usedapp/core'
import 'dayjs/locale/en'
import ErrorOverlay from './components/elements/ErrorOverlay'
import { messages as enMessages } from './locales/en/messages'
import { ConfigProvider } from './providers/config'
import { GoogleAnalyticsProvider } from './providers/google-analytics'
import { GraphProvider } from './providers/graph-provider'
import { RefreshingProvider } from './providers/refresher'
import { SentryProvider } from './providers/sentry'
import { StorageProvider } from './providers/storage'

i18n.load('en', enMessages)
i18n.activate('en')
dayjs.locale('en')

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
dayjs.extend(duration)

const initialize = async () => {
  if (config.meticulousProjectId) {
    await tryLoadAndStartRecorder({
      projectId: config.meticulousProjectId,
    })
  }

  const App = withProfiler(TermApp)

  const { subgraphUrls, listingsSubgraphUrls, vaultsSubgraphUrls } = (() => {
    const subgraphUrls: string[] = []
    const listingsSubgraphUrls: string[] = []
    const vaultsSubgraphUrls: string[] = []

    if (config.chains) {
      Object.values(config.chains).forEach((chain) => {
        if (!chain) {
          console.error(
            `Invalid blockchain configuration detected: ${JSON.stringify(chain)}`
          )
          return
        }

        if (chain.subgraphUrl) {
          subgraphUrls.push(chain.subgraphUrl)
        } else {
          console.error(
            `subgraphUrl missing in chain: ${JSON.stringify(chain)}`
          )
        }

        if (chain.listingsSubgraphUrl) {
          listingsSubgraphUrls.push(chain.listingsSubgraphUrl)
        } else {
          console.error(
            `listingsSubgraphUrl missing in chain: ${JSON.stringify(chain)}`
          )
        }

        if (chain.vaultsSubgraphUrl) {
          vaultsSubgraphUrls.push(chain.vaultsSubgraphUrl)
        } else {
          console.error(
            `vaultsSubgraphUrl missing in chain: ${JSON.stringify(chain)}`
          )
        }
      })
    }

    return { subgraphUrls, listingsSubgraphUrls, vaultsSubgraphUrls }
  })()

  const rootNode = document.getElementById('root') as HTMLElement
  const root = ReactDOM.createRoot(rootNode)
  root.render(
    <React.StrictMode>
      <SafeProvider>
        <RefreshingProvider>
          <ConfigProvider>
            <SentryProvider>
              <ErrorBoundary fallback={ErrorOverlay} showDialog>
                <GoogleAnalyticsProvider>
                  <StorageProvider>
                    <GraphProvider
                      subgraphUrls={[
                        ...subgraphUrls,
                        ...listingsSubgraphUrls,
                        ...vaultsSubgraphUrls,
                      ]}
                    >
                      <DAppProvider config={config.provider}>
                        <ChakraProvider theme={theme}>
                          <BrowserRouter>
                            <I18nProvider i18n={i18n}>
                              <App />
                            </I18nProvider>
                          </BrowserRouter>
                        </ChakraProvider>
                      </DAppProvider>
                    </GraphProvider>
                  </StorageProvider>
                </GoogleAnalyticsProvider>
              </ErrorBoundary>
            </SentryProvider>
          </ConfigProvider>
        </RefreshingProvider>
      </SafeProvider>
    </React.StrictMode>
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}
initialize()
