import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react'

const fonts = 'Inter, sans-serif'

const bodyTextStyles = {
  'body-xs': {
    fontSize: '12px',
    lineHeight: '16px',
  },
  'body-sm': {
    fontSize: '14px',
    lineHeight: '20px',
  },
  'body-md': {
    fontSize: '16px',
    lineHeight: '24px',
  },
  'body-l': {
    fontSize: '18px',
    lineHeight: '28px',
  },
  'body-xl': {
    fontSize: '20px',
    lineHeight: '28px',
  },
  'body-2xl': {
    fontSize: '24px',
    lineHeight: '32px',
  },
  'body-3xl': {
    fontSize: '30px',
    lineHeight: '36px',
  },
  'body-4xl': {
    fontSize: '38px',
    lineHeight: '36px',
  },
}

const v1ButtonBaseStyles = {
  background: 'blue.500',
  color: '#FFFFFF',
  paddingTop: '8px',
  paddingRight: '16px',
  paddingBottom: '8px',
  paddingLeft: '16px',
  width: 'auto',
}

export const theme = extendTheme({
  zIndices: {
    // TODO (matt) TL-807: remove this once we have a more permanent solution
    modal: 50, // for walletconnect
    // connectModal: 50,
  },
  breakpoints: {
    sm: '30em', // 480px
    md: '48em', // 768px
    lg: '64em', // 1024px
    xl: '80em', // 1280px
    '1xl': '90em', // 1440px
    '2xl': '96em', // 1536px
    '3xl': '120em', // 1920px
  },
  fonts: {
    body: fonts,
    plexMono: 'IBM Plex Mono',
  },
  fontSizes: {
    '2xs': '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '30px',
    '4xl': '36px',
    '5xl': '48px',
    '7xl': '72px',
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    '3': '12px',
    '4': '16px',
    '5': '20px',
    '6': '24px',
    '7': '28px',
    '8': '32px',
    '9': '36px',
    '10': '40px',
  },
  styles: {
    global: () => ({
      body: {
        bg: '#F7F9FC',
        fontFamily: fonts,
      },
    }),
  },
  colors: {
    // Single digits are v1.1 colors
    blue: {
      0: '#F7F9FC',
      1: '#F0F6FF',
      2: '#B8D6FF',
      3: '#679FF2',
      4: '#0099FF',
      5: '#0A5ED9',
      6: '#1128B6',
      8: '#154E9F',
      9: '#001027',
      10: '#0057BA',
      50: '#EBF8FF',
      100: '#BEE3F8',
      200: '#B8D6FF',
      400: '#4299E1',
      500: '#0A5ED9',
      800: '#154E9F',
      900: '#1A365D',
    },
    gray: {
      0: '#FBFCFE',
      1: '#F9FAFB',
      2: '#E4E7EB',
      3: '#CAD5E4',
      4: '#AEBCD0',
      5: '#758AA7',
      '5.5': 'rgba(127, 136, 149, 1)',
      6: '#4A5667',
      7: '#E0E0E0',
      25: '#F9FBFD',
      50: '#F7FAFC',
      200: '#E2E8F0',
      300: 'rgba(228, 231, 235, 0.33)',
      400: '#BDBDBD',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      900: '#171923',
    },
    purple: {
      1: '#F4F2FA',
      4: '#5C3AD6',
      50: '#FAF5FF',
      500: '#805AD5',
    },
    cyan: {
      1: '#E4F5FB',
      4: '#5FD0FD',
      8: '#026992',
      50: '#EDFDFD',
      100: 'rgba(237, 241, 254, 0.4)',
      500: '#00B5D8',
    },
    pink: {
      50: '#FFF5F7',
      500: '#D53F8C',
    },
    green: {
      1: '#E9FDF9',
      2: '#C6F6D5',
      3: '#9AE6B4',
      4: '#00D061',
      5: '#38A169',
      6: '#026553',
      100: '#C6F6D5',
      200: '#9AE6B4',
      500: '#38A169',
    },
    orange: {
      1: '#FFF7EB',
      2: '#FFEFD9',
      3: '#FBD38D',
      5: '#E07F00',
      100: '#FEEBCB',
      200: '#FBD38D',
      500: '#DD6B20',
    },
    red: {
      2: '#FFDCD9',
      3: '#FEB2B2',
      4: 'rgba(227, 95, 86, 0.03)',
      5: '#EB1B0E',
      100: '#FED7D7',
      200: '#FEB2B2',
      500: '#E53E3E',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: '600',
        width: 'auto',
        borderRadius: '6px',
      },
      sizes: {
        sm: {
          ...bodyTextStyles['body-xs'],
          py: '4px',
          px: '8px',
        },
        md: {
          ...bodyTextStyles['body-sm'],
          py: '6px',
          px: '12px',
        },
        lg: {
          ...bodyTextStyles['body-sm'],
          py: '8px',
          px: '16px',
        },
      },
      variants: {
        primaryFlat: {
          background: 'blue.5',
          color: 'white',
          _hover: {
            backgroundBlendMode: 'overlay',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), #0099FF',
          },
          _active: {
            backgroundBlendMode: 'overlay, normal, normal',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.72)), linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), #0099FF',
          },
          _focus: {
            background:
              'linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), #0099FF',
            boxShadow: '0px 0px 0px 2px rgba(0, 143, 241, 0.41)',
          },
          _disabled: {
            opacity: 0.5,
            pointerEvents: 'none',
            background:
              'linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), #0099FF',
          },
        },
        primary: {
          background:
            'linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), linear-gradient(0deg, #0099FF, #0099FF)',
          color: 'white',
          _hover: {
            backgroundBlendMode: 'overlay',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), #0099FF',
          },
          _active: {
            backgroundBlendMode: 'overlay, normal, normal',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.72)), linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), #0099FF',
          },
          _focus: {
            background:
              'linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), #0099FF',
            boxShadow: '0px 0px 0px 2px rgba(0, 143, 241, 0.41)',
          },
          _disabled: {
            opacity: 0.5,
            pointerEvents: 'none',
            background:
              'linear-gradient(180deg, #0099FF 0%, rgba(18, 34, 179, 0.69) 100%), #0099FF',
          },
        },
        secondary: {
          background: 'blue.2',
          color: 'blue.8',
          _hover: {
            backgroundBlendMode: 'overlay, normal',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.49)), #B8D6FF',
          },
          _active: {
            backgroundBlendMode: 'overlay, normal',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #B8D6FF',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _focus: {
            background: 'blue.2',
            boxShadow: '0px 0px 0px 2px rgba(0, 143, 241, 0.41)',
          },
          _disabled: {
            opacity: 0.5,
            pointerEvents: 'none',
            background: 'blue.2',
          },
        },
        tertiary: {
          borderColor: 'blue.5',
          color: 'blue.5',
          background: 'transparent',
          borderWidth: '1px',
          _hover: {
            background: 'blue.1',
          },
          _active: {
            backgroundBlendMode: 'overlay, normal',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #D2E4FF',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _disabled: {
            opacity: 0.5,
            pointerEvents: 'none',
          },
        },
        error: {
          background: 'red.2',
          color: 'red.5',
          _hover: {
            backgroundBlendMode: 'overlay, normal',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.49)), #FFDCD9',
          },
          _active: {
            backgroundBlendMode: 'overlay, normal',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #FFDCD9',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _focus: {
            backgroundBlendMode: 'overlay, normal',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #FFDCD9',
            boxShadow: '0px 0px 0px 2px rgba(0, 143, 241, 0.41)',
          },
          _disabled: {
            opacity: 0.5,
            pointerEvents: 'none',
            background: 'red.2',
          },
        },
        // V1 variants
        'without-padding': {
          ...v1ButtonBaseStyles,
          background: 'transparent',
          paddingTop: '0px',
          paddingRight: '0px',
          paddingBottom: '0px',
          paddingLeft: '0px',
          paddingInlineStart: '0px',
          paddingInlineEnd: '0px',
        },
        outline: {
          ...v1ButtonBaseStyles,
          borderColor: 'blue.200',
          borderWidth: '1px',
          borderRadius: '6px',
          background: 'transparent',
          color: 'gray.700',
        },
        link: {
          ...v1ButtonBaseStyles,
          background: 'transparent',
          color: 'inherit',
          _hover: {
            textDecoration: 'none',
          },
        },
        gray: {
          ...v1ButtonBaseStyles,
          background: 'gray.200',
          borderRadius: '6px', // TODO: move to base?
          color: 'gray.700',
          fontWeight: '600',
          fontSize: '18px',
          lineHeight: '28px',
          padding: '10px 24px',
          height: 'auto',
        },
        'tender-btn-group': {
          borderWidth: '1px',
          borderColor: 'gray.2',
          borderRadius: '6px',
          background: '#FFFFFF',
          padding: '10px 24px',
          color: 'gray.5',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: '600',
          minWidth: '216px',
          _first: {
            borderRightWidth: '0px',
          },
          _active: {
            color: 'blue.9',
            _before: {
              content: '""',
              position: 'absolute',
              width: 'calc(100% - 8px)',
              height: 'calc(100% - 8px)',
              background: 'gray.2',
              zIndex: '0',
              pointerEvents: 'none',
              borderRadius: '6px',
            },
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: fonts,
      },
      variants: {
        'heading/h1': {
          fontSize: '24px',
          lineHeight: '120%',
          letterSpacing: '-0.02em',
          fontWeight: '700',
        },
        'heading/h2': {
          fontSize: '20px',
          lineHeight: '120%',
          fontWeight: '700',
        },
        'heading/h3': {
          fontSize: '16px',
          lineHeight: '120%',
          fontWeight: '700',
        },
        'heading/h4': {
          fontSize: '14px',
          lineHeight: '120%',
          fontWeight: '700',
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          px: '4px',
        },
      },
      variants: {
        flushed: {
          field: {
            py: '10px',
            px: '4px',
            borderBottomWidth: '1px',
            borderBottomColor: 'blue.5',
            color: 'blue.9',
            _placeholder: {
              color: 'gray.3',
            },
            _hover: {
              background: 'gray.1',
            },
            _focus: {
              background: 'gray.1',
              borderWidth: '1px',
              borderColor: 'blue.5',
            },
            _error: {
              background: 'gray.1',
              borderColor: 'red.5',
            },
            _disabled: {
              opacity: 0.5,
              pointerEvents: 'none',
            },
          },
        },
        table: {
          field: {
            ...bodyTextStyles['body-xs'],
            py: '9px',
            borderWidth: '1px',
            borderColor: 'gray.2',
            background: 'white',
            _placeholder: {
              color: 'blue.9',
            },
            _hover: {
              background: 'gray.1',
            },
            _focus: {
              borderColor: 'blue.5',
            },
            _error: {
              borderColor: 'red.5',
            },
            _disabled: {
              opacity: 0.5,
              pointerEvents: 'none',
            },
          },
        },
      },
    },
    Select: {
      variants: {
        flushed: {
          field: {
            py: '8px',
            px: '4px',
            borderBottomWidth: '1px',
            borderColor: 'blue.5',
            _placeholder: {
              color: 'gray.3',
            },
            _hover: {
              background: 'gray.1',
            },
            _focus: {
              borderWidth: '1px',
              background: 'gray.1',
            },
            _disabled: {
              opacity: 0.5,
            },
          },
        },
        error: {
          field: {
            py: '8px',
            px: '4px',
            borderBottomWidth: '1px',
            borderColor: 'red.5',
            _placeholder: {
              color: 'gray.3',
            },
            _hover: {
              background: 'gray.1',
            },
            _focus: {
              borderWidth: '1px',
              background: 'gray.1',
            },
          },
        },
      },
    },
    Radio: {
      parts: ['label'],
      baseStyle: {
        label: {
          fontSize: '14px',
          fontWeight: '400',
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
      variants: {
        'create-tender': {
          label: {
            color: 'gray.700',
            fontSize: '14px',
            ml: 1,
          },
        },
      },
    },
    Badge: {
      baseStyle: {
        ...bodyTextStyles['body-md'],
        fontWeight: 500,
        padding: '0px 8px',
        borderRadius: '6px',
        textTransform: 'none',
      },
      variants: {
        '1week': {
          background: 'blue.1',
          color: 'blue.6',
        },
        '2week': {
          background: 'rgba(228, 231, 235, 0.6)',
          color: 'blue.9',
        },
        '4week': {
          background: 'purple.1',
          color: 'purple.4',
        },
        '6week': {
          background: 'blue.1',
          color: 'blue.8',
        },
        '8week': {
          background: 'green.1',
          color: 'green.6',
        },
        '13week': {
          background: 'cyan.1',
          color: 'cyan.8',
        },
        '26week': {
          background: 'orange.1',
          color: 'orange.5',
        },
        '52week': {
          background: 'blue.1',
          color: 'blue.4',
        },
      },
    },
    Tag: createMultiStyleConfigHelpers([]).defineMultiStyleConfig({
      baseStyle: {
        label: {
          fontWeight: '500',
          borderRadius: '6px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'Inter, sans-serif',
          fontStyle: 'normal',
        },
      },
      sizes: {
        xs: {
          container: {
            px: '4px',
            height: '16px',
            gap: '2px',
          },
          label: {
            fontSize: '8px',
            lineHeight: '16px',
          },
        },
        sm: {
          container: {
            padding: '8px 6px',
            height: '20px',
          },
          label: {
            ...bodyTextStyles['body-xs'],
          },
        },
        s: {
          container: {
            padding: '8px',
            height: '20px',
            gap: '4px',
          },
          label: {
            ...bodyTextStyles['body-sm'],
          },
        },
        md: {
          container: {
            padding: '8px',
            gap: '4px',
            height: '24px',
          },
          label: {
            ...bodyTextStyles['body-md'],
          },
        },
      },
      variants: {
        marginRatio: {
          container: {
            borderWidth: 1,
            borderColor: 'blue.300',
            background: 'blue.50',
            color: 'blue.300',
            fontWeight: 'normal',
            fontSize: 'xs',
            minHeight: 5,
            px: '7px',
          },
          label: {
            lineHeight: '16px',
          },
        },
      },
    }),
    FormLabel: {
      variants: {
        'create-tender': {
          color: 'gray.600',
          fontSize: '12px',
          lineHeight: '16px',
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'blue.5',
        textDecoration: 'underline',
      },
      variants: {
        inline: {
          color: 'blue.5',
          textDecoration: 'none',
        },
      },
    },
    Modal: {
      baseStyle: {
        body: {
          pb: '28px',
          px: '28px',
        },
        header: {
          color: 'blue.9',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
      variants: {
        standard: {
          dialog: {
            maxW: '483px',
          },
        },
        wide: {
          dialog: {
            maxW: '572px',
          },
        },
        // connectModal: {
        //   dialog: {
        //     zIndex: 'connectModal',
        //   },
        // }
      },
    },
    Text: {
      baseStyle: {
        fontFamily: fonts,
      },
      variants: {
        'body-xs/normal': {
          ...bodyTextStyles['body-xs'],
          fontWeight: 'normal',
        },
        'body-xs/medium': {
          ...bodyTextStyles['body-xs'],
          fontWeight: '500',
        },
        'body-xs/semibold': {
          ...bodyTextStyles['body-xs'],
          fontWeight: '600',
        },
        'body-xs/bold': {
          ...bodyTextStyles['body-xs'],
          fontWeight: '700',
        },
        'body-xs/extra-bold': {
          ...bodyTextStyles['body-xs'],
          fontWeight: '800',
        },
        'body-sm/normal': {
          ...bodyTextStyles['body-sm'],
          fontWeight: 'normal',
        },
        'body-sm/medium': {
          ...bodyTextStyles['body-sm'],
          fontWeight: '500',
        },
        'body-sm/semibold': {
          ...bodyTextStyles['body-sm'],
          fontWeight: '600',
        },
        'body-sm/bold': {
          ...bodyTextStyles['body-sm'],
          fontWeight: '700',
        },
        'body-sm/extra-bold': {
          ...bodyTextStyles['body-sm'],
          fontWeight: '800',
        },
        'body-md/normal': {
          ...bodyTextStyles['body-md'],
          fontWeight: 'normal',
        },
        'body-md/medium': {
          ...bodyTextStyles['body-md'],
          fontWeight: '500',
        },
        'body-md/semibold': {
          ...bodyTextStyles['body-md'],
          fontWeight: '600',
        },
        'body-md/bold': {
          ...bodyTextStyles['body-md'],
          fontWeight: '700',
        },
        'body-md/extra-bold': {
          ...bodyTextStyles['body-md'],
          fontWeight: '800',
        },
        'body-l/normal': {
          ...bodyTextStyles['body-l'],
          fontWeight: 'normal',
        },
        'body-l/medium': {
          ...bodyTextStyles['body-l'],
          fontWeight: '500',
        },
        'body-l/semibold': {
          ...bodyTextStyles['body-l'],
          fontWeight: '600',
        },
        'body-l/bold': {
          ...bodyTextStyles['body-l'],
          fontWeight: '700',
        },
        'body-l/extra-bold': {
          ...bodyTextStyles['body-l'],
          fontWeight: '800',
        },
        'body-xl/normal': {
          ...bodyTextStyles['body-xl'],
          fontWeight: 'normal',
        },
        'body-xl/medium': {
          ...bodyTextStyles['body-xl'],
          fontWeight: '500',
        },
        'body-xl/semibold': {
          ...bodyTextStyles['body-xl'],
          fontWeight: '600',
        },
        'body-xl/bold': {
          ...bodyTextStyles['body-xl'],
          fontWeight: '700',
        },
        'body-xl/extra-bold': {
          ...bodyTextStyles['body-xl'],
          fontWeight: '800',
        },
        'body-2xl/normal': {
          ...bodyTextStyles['body-2xl'],
          fontWeight: 'normal',
        },
        'body-2xl/medium': {
          ...bodyTextStyles['body-2xl'],
          fontWeight: '500',
        },
        'body-2xl/semibold': {
          ...bodyTextStyles['body-2xl'],
          fontWeight: '600',
        },
        'body-2xl/bold': {
          ...bodyTextStyles['body-2xl'],
          fontWeight: '700',
        },
        'body-2xl/extra-bold': {
          ...bodyTextStyles['body-2xl'],
          fontWeight: '800',
        },
        'body-3xl/normal': {
          ...bodyTextStyles['body-3xl'],
          fontWeight: 'normal',
        },
        'body-3xl/medium': {
          ...bodyTextStyles['body-3xl'],
          fontWeight: '500',
        },
        'body-3xl/semibold': {
          ...bodyTextStyles['body-3xl'],
          fontWeight: '600',
        },
        'body-3xl/bold': {
          ...bodyTextStyles['body-3xl'],
          fontWeight: '700',
        },
        'body-3xl/extra-bold': {
          ...bodyTextStyles['body-3xl'],
          fontWeight: '800',
        },
        'body-4xl/normal': {
          ...bodyTextStyles['body-4xl'],
          fontWeight: 'normal',
        },
        'body-4xl/medium': {
          ...bodyTextStyles['body-4xl'],
          fontWeight: '500',
        },
        'body-4xl/semibold': {
          ...bodyTextStyles['body-4xl'],
          fontWeight: '600',
        },
        'body-4xl/bold': {
          ...bodyTextStyles['body-4xl'],
          fontWeight: '700',
        },
        'body-4xl/extra-bold': {
          ...bodyTextStyles['body-4xl'],
          fontWeight: '800',
        },
        'chart-sub': {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px' /* 142.857% */,
          letterSpacing: '0.25px',
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: '2px',
          borderWidth: '2px',
          borderColor: 'gray.2',
          _checked: {
            background: 'blue.2',
            borderColor: 'blue.2',
            color: 'white',
          },
          _disabled: {
            borderColor: 'gray.2',
            background: 'gray.2',
          },
        },
      },
      error: {
        control: {
          borderColor: 'red.5',
          background: 'white',
        },
      },
    },
    Skeleton: {
      baseStyle: {
        startColor: 'gray.2',
        endColor: 'gray.1',
        height: '20px',
        opacity: 0.5,
        borderRadius: '4px',
      },
    },
    Accordion: {
      variants: {
        'bomb-pot': {
          container: {
            border: '1px solid rgba(0, 153, 255, 0.4)',
            borderRadius: '13px',
            boxShadow: '0px 4px 70px 0px rgba(0, 0, 0, 0.1)',
            bg: '#EDF5FC',
            w: '343px',
            p: '12px, 20px, 12px, 20px',
          },
          button: {
            color: 'blue.9',
            justifyContent: 'space-between',
            _hover: {
              bg: 'transparent',
            },
          },
        },
      },
    },
    Stepper: {
      variants: {
        progress: {
          indicator: {
            border: 'none',
            bg: 'transparent',
            w: '12px',
            '&[data-status=complete]': {
              bg: 'transparent',
            },
          },
        },
      },
    },
    Progress: {
      variants: {
        progress: {
          filledTrack: {
            bg: '#0A5ED9',
          },
          track: {
            borderRadius: '2px',
            bg: '#D9D9D9',
          },
        },
      },
    },
    Table: {
      variants: {
        striped: {
          tr: {
            '&:nth-of-type(odd)': {
              td: {
                bg: 'blue.0',
              },
            },
          },
        },
        'striped-even': {
          tr: {
            '&:nth-of-type(even)': {
              bg: 'blue.0',
            },
          },
        },
      },
    },
    Tabs: {
      variants: {
        'soft-rounded': {
          tablist: {
            spacing: '4px',
          },
          tab: {
            bg: 'transparent',
            borderRadius: '6px',
            padding: '10px 12px',
            justifyContent: 'center',
            alignItems: 'center',

            color: 'gray.5',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',

            _selected: {
              bg: 'gray.2',
              color: 'blue.9',
            },
            '&:not(:last-child)': {
              marginRight: '4px',
            },
          },
        },
      },
    },
  },
})
