import {
  VStack,
  Image,
  Text,
  Heading,
  Skeleton,
  HStack,
  Button,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import {
  formatFixedPercentage,
  formatFixedUsd,
} from '../../../../helpers/utils'
import { FixedNumber } from 'ethers'
import Tooltip from '../../../../components/elements/Tooltip'
import sparkle_icon from '../../../../assets/icons/sparkle-alt.svg'

type MetaVaultBannerProps = {
  isDataLoaded?: boolean
  totalDeposited: FixedNumber
  yourDepositsValue?: FixedNumber
  averagingValue?: FixedNumber
  acrossValue?: number
  onViewPortfolio: () => void
}

export default function MetaVaultBannerHasPositions({
  isDataLoaded = true,
  totalDeposited,
  yourDepositsValue,
  averagingValue,
  acrossValue,
  onViewPortfolio,
}: MetaVaultBannerProps) {
  return (
    <HStack
      w="full"
      bg="linear-gradient(98.18deg, #0057BA 23.86%, #5C3AD6 85.19%)"
      borderRadius="6px"
      color="white"
      overflow="hidden"
      p="28px 64px 28px 32px"
      justifyContent="space-between"
    >
      <VStack alignItems="left" spacing={2.5}>
        <VStack alignItems="left" spacing={2.5}>
          <Trans>
            <Text variant="body-sm/medium">Review Your</Text>
            <Text fontWeight="bold" fontSize="32px" lineHeight="32px">
              Strategy Vaults
            </Text>
          </Trans>
        </VStack>

        <VStack
          alignItems="left"
          spacing={2.5}
          borderTop="1px solid #FFFFFF40"
          pt={2.5}
        >
          <Text variant="body-sm/medium">
            <Trans>Total deposited</Trans>
          </Text>

          <Skeleton isLoaded={isDataLoaded} h="29px" w="253px">
            <Heading variant="heading/h1">
              {formatFixedUsd(totalDeposited, false)}
            </Heading>
          </Skeleton>
        </VStack>
      </VStack>

      <VStack spacing={3} align="start">
        <Text variant="body-sm/normal">
          {' '}
          <Trans>Your deposits</Trans>
        </Text>
        <Skeleton isLoaded={isDataLoaded} h="32px" w="166px">
          <Text fontWeight="medium" lineHeight="32px" fontSize="28px">
            {yourDepositsValue ? formatFixedUsd(yourDepositsValue) : '0'}
          </Text>
        </Skeleton>
      </VStack>

      <VStack spacing={3} align="start">
        <Text variant="body-sm/normal">
          {' '}
          <Trans>Across</Trans>
        </Text>
        <Skeleton isLoaded={isDataLoaded} h="32px" w="166px">
          <Text fontWeight="medium" lineHeight="32px" fontSize="28px">
            <Trans> {acrossValue} vaults</Trans>
          </Text>
        </Skeleton>
      </VStack>

      <VStack spacing={3} align="start">
        <Text variant="body-sm/normal">
          <Trans>Averaging</Trans>
        </Text>
        <Skeleton isLoaded={isDataLoaded} h="32px" w="166px">
          {!averagingValue || averagingValue.isZero() ? (
            <HStack>
              <Text fontWeight="medium" lineHeight="32px" fontSize="28px">
                N/A
              </Text>
              <Tooltip
                noDelay
                placement="bottom"
                label={'More data needed to display yield'}
              >
                <Image boxSize={6} src={sparkle_icon} />
              </Tooltip>
            </HStack>
          ) : (
            <Text fontWeight="medium" lineHeight="32px" fontSize="28px">
              {formatFixedPercentage(averagingValue, 1)} APY
            </Text>
          )}
        </Skeleton>
      </VStack>

      <Button variant="secondary" bg="white" onClick={onViewPortfolio}>
        <Trans>View Portfolio</Trans>
      </Button>
    </HStack>
  )
}
