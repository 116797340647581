import { InfoOutlineIcon } from '@chakra-ui/icons'
import { IconButton, Image, Input, Skeleton, Text } from '@chakra-ui/react'
import { useState } from 'react'
import edit_icon from '../../../../assets/icons/edit.svg'
import tick_icon from '../../../../assets/icons/tick_grey.svg'
import x_icon from '../../../../assets/icons/x_grey.svg'
import { HStack, VStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'
import { FixedNumber } from 'ethers'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import { parseUserInput } from '../../../../helpers/utils'
// import { fixedToFormattedPercentage } from '../../../../helpers/conversions'

export default function EditableStat({
  isGovernor,
  type,
  label,
  initialValue,
  tooltip,
  onSave,
  isDataLoaded,
}: {
  isGovernor: boolean
  type: 'date' | 'percentage'
  label: string
  initialValue: number | FixedNumber | string
  tooltip?: string
  onSave: (ratio: string) => Promise<void>
  isDataLoaded?: boolean
}) {
  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  // const [value, setValue] = useState(() => {
  //   if (type === 'percentage' && initialValue instanceof FixedNumber) {
  //     return fixedToFormattedPercentage(initialValue as FixedNumber, 2, true, true).formattedPercentage
  //   } else {
  //     return initialValue
  //   }
  // })
  const [value, setValue] = useState(initialValue)
  const suffix = label === 'WAL Cap' ? ' days' : '%'

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseUserInput(event.target.value, true, 2))
  }

  const toggleEditing = () => {
    setIsEditing((prev) => !prev)
  }

  const handleSave = async () => {
    setSubmitting(true)
    termToast.pending(termToastMessages.editVaultStat.pending())
    try {
      await onSave(value.toString())
      termToast.success(
        termToastMessages.editVaultStat.success(label, value.toString())
      )
      setIsEditing(false)
    } catch (error) {
      if ((error as Error).message.includes('user rejected transaction')) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.editVaultStat.failure())
      }
    } finally {
      setSubmitting(false)
    }
  }

  const handleCancel = () => {
    setValue(initialValue)
    setIsEditing(false)
  }

  return (
    <VStack align="start">
      <HStack gap={1}>
        <Text variant="body-md/normal" color="gray.6" whiteSpace="nowrap">
          {label}
        </Text>
        {tooltip && (
          <Tooltip
            label="The maximum WAL of the vault."
            placement="top-start"
            w="304px"
            offset={[-5, 6]}
          >
            <InfoOutlineIcon color="gray.4" />
          </Tooltip>
        )}
        {isGovernor && (
          <IconButton
            variant="unstyled"
            size="xs"
            icon={<Image w={6} h={6} src={edit_icon} alt="proceed" />}
            onClick={toggleEditing}
            cursor="pointer"
            aria-label=""
          />
        )}
      </HStack>
      <Skeleton w="180px" isLoaded={isDataLoaded}>
        <HStack gap={0}>
          <Input
            type="text"
            value={`${value}${!isEditing ? suffix : ''}`}
            onChange={handleChange}
            mr={1}
            border="none"
            cursor="text"
            pointerEvents={!isGovernor || !isEditing ? 'none' : 'auto'}
            _focus={{
              outline: 'none',
              boxShadow: 'none',
            }}
            h="auto"
            fontSize="md"
            fontWeight="semibold"
            color="blue.9"
            borderBottom="1px solid"
            borderBottomColor={
              isEditing && isGovernor ? 'blue.500' : 'transparent'
            }
            borderRadius={0}
            w="89px"
          />
          {isGovernor && isEditing && (
            <HStack gap={1}>
              <IconButton
                variant="unstyled"
                size="xs"
                icon={<Image w={6} h={6} src={tick_icon} alt="proceed" />}
                onClick={handleSave}
                isDisabled={
                  !!(initialValue && value === initialValue) || submitting
                }
                cursor="pointer"
                aria-label=""
              />
              <IconButton
                variant="unstyled"
                size="xs"
                icon={<Image w={6} h={6} src={x_icon} alt="cancel" />}
                onClick={handleCancel}
                cursor="pointer"
                aria-label=""
              />
            </HStack>
          )}
        </HStack>
      </Skeleton>
    </VStack>
  )
}
