import { Image, ImageProps } from '@chakra-ui/react'
import { useProtocolIcon } from '../../../data/hooks/use-protocol-icon'

export default function ProtocolIcon({
  protocol,
  boxSize = '16px',
  imageProps,
}: {
  protocol: string
  boxSize?: string
  imageProps?: ImageProps
}) {
  const src = useProtocolIcon(protocol)
  return <Image boxSize={boxSize} src={src} alt={protocol} {...imageProps} />
}
