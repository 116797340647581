import { Image, ImageProps } from '@chakra-ui/react'
import { useVaultIcon } from '../../../data/hooks/use-vault-icon'

export default function VaultCuratorIcon({
  vaultName,
  boxSize = '16px',
  imageProps,
}: {
  vaultName: string
  boxSize?: string
  imageProps?: ImageProps
}) {
  const src = useVaultIcon(vaultName)
  return (
    <Image
      boxSize={boxSize}
      src={src}
      alt={`${vaultName} vault icon`}
      {...imageProps}
    />
  )
}
