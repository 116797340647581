import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import TokenIconLabel from '../../../../../components/elements/TokenLabelIcon'
import { useTermToast } from '../../../../../hooks/toasts'
import { useState } from 'react'
import { termToastMessages } from '../../../../../helpers/toasts'

export const RemoveCollateralModal = ({
  isOpen = true,
  onClose,
  token,
  onRemoveCollateral,
}: {
  isOpen: boolean
  onClose: () => void
  token: string
  onRemoveCollateral: () => void
}) => {
  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)
  const handleRemove = async () => {
    setSubmitting(true)
    termToast.pending(termToastMessages.removeCollateral.pending(token))
    try {
      await onRemoveCollateral()
      termToast.success(termToastMessages.removeCollateral.success(token))
    } catch (error) {
      if ((error as Error).message.includes('user rejected transaction')) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.removeCollateral.failure(token))
      }
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />

      <ModalContent p={1}>
        <ModalHeader>
          <Text variant="body-2xl/semibold">
            <Trans>Are you sure?</Trans>
          </Text>
          <ModalCloseButton m={2} />
        </ModalHeader>

        <ModalBody>
          <Box display="inline-flex" gap={1}>
            <Trans>
              <Text variant="body-sm/normal">Are you sure you want to</Text>
              <Text variant="body-sm/semibold">remove</Text>
              <Box
                display="inline-flex"
                gap={1}
                bg="gray.2"
                py={0.5}
                px={2}
                borderRadius={6}
                alignItems="center"
              >
                <TokenIconLabel
                  isSmall
                  token={token}
                  boxSize="12px"
                  customVariant="body-xs/medium"
                  colorSymbol="gray.6"
                  isMedium
                />
              </Box>

              <Text variant="body-sm/semibold">from the vault?</Text>
            </Trans>
          </Box>
          <Text variant="body-sm/normal">
            <Trans>
              This won't affect current holdings but will prevent future
              purchases.
            </Trans>
          </Text>
        </ModalBody>

        <ModalFooter pt={-2} mb={2.5}>
          <Button
            variant="error"
            width="100%"
            onClick={handleRemove}
            disabled={submitting}
          >
            <Trans>Remove</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RemoveCollateralModal
