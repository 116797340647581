import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip as RTooltip,
} from 'recharts'
import { getColor } from './index'
import { RepoTokenPieChartMapping } from '../../../../models/vault'
import { Box, HStack, Text } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

type Props = {
  isActiveTab: boolean
  holdings: RepoTokenPieChartMapping[]
  height?: number
  width?: number
  getColorCustom?: (index: number) => string
  isTooltip?: boolean
}

const CustomTooltip = ({
  active,
  payload,
}: {
  active: boolean
  payload: any[]
}) => {
  if (active && payload && payload.length) {
    const balance = payload[0].payload.balance
    const color = payload[0].payload.fill
    return (
      <>
        <HStack pl={2}>
          <svg
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8L16 8L8 -6.99382e-07L0 8Z"
              fill="#1A365D"
              fillOpacity="0.9"
            />
          </svg>
        </HStack>
        <HStack
          align="center"
          justify="space-between"
          bg="blue.900"
          mb={1.5}
          p={2}
          spacing={3}
          borderRadius="4px"
        >
          <HStack>
            <Box w="8px" height="8px" bg={color}></Box>
            <Text variant="body-sm/medium" color="rgba(255, 255, 255, 0.7)">
              Balance:
            </Text>
          </HStack>
          <Text variant="body-sm/medium" color="white">
            {Number(balance)}
          </Text>
        </HStack>
      </>
    )
  }
  return null
}

export default function CollateralPieChart({
  isActiveTab,
  holdings,
  width,
  height,
  getColorCustom,
  isTooltip,
}: Props) {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [isMouseOver, setIsMouseOver] = useState(false)

  const throttle = (
    func: (...args: any[]) => void,
    delay: number
  ): ((...args: any[]) => void) => {
    let lastCall = 0
    return (...args: any[]) => {
      const now = new Date().getTime()
      return now - lastCall < delay
        ? undefined
        : ((lastCall = now), func(...args))
    }
  }

  const handleMouseOver = useCallback(
    throttle((event: React.MouseEvent) => {
      setCoordinates({ x: event.clientX, y: event.clientY })
      setIsMouseOver(true)
    }, 500),
    []
  )

  const handleMouseOut = () => {
    setIsMouseOver(false)
    setCoordinates({ x: 0, y: 0 })
  }
  return (
    <div
      style={{
        width: width || '100%',
        height: height || '144px',
        minHeight: '144px',
      }}
    >
      {isActiveTab && (
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey="ratio"
              data={holdings}
              innerRadius={'25%'}
              outerRadius={'100%'}
            >
              {holdings.map((_entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    getColorCustom ? getColorCustom(index) : getColor(index)
                  }
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                />
              ))}
            </Pie>

            {isMouseOver && isTooltip && (
              <RTooltip
                wrapperStyle={{
                  visibility: 'visible',
                  position: 'fixed',
                  zIndex: 9999,
                  left: coordinates.x,
                  top: coordinates.y - 32,
                }}
                content={<CustomTooltip active={false} payload={[]} />}
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}
