import { ChainId } from '@usedapp/core'
import {
  Address,
  Currency,
  MappedYieldResult,
  MetaVaultStrategyAllocations,
} from '../../data/model'
import {
  BaseVaultData,
  MetaVaultData,
  VaultData,
  VaultsMapping,
  VaultStrategy,
} from '../../models/vaults'
import { FixedNumber } from 'ethers'

type MetaVaultMapping = {
  name: string
  assetSymbol: string
  description: string
}

const metaVaultMapping: {
  [chainId: string]: { [metaVaultAddress: string]: MetaVaultMapping }
} = {
  [ChainId.Sepolia.toString()]: {
    '0x18d6cdfc3efb862871fbc1c65369028b31a5cdc9': {
      name: 'Term USDC Meta Vault',
      assetSymbol: 'USDC',
      description:
        'Maximize yield with this Meta Vault funded with USD and backed by strategies using USD, ETH, and Bitcoin.',
    },
    '0xb7328a2c65a501089f57229cd8c9b7d4e295dc42': {
      name: 'Term ETH Meta Vault',
      assetSymbol: 'WETH',
      description:
        'Maximize yield with this Meta Vault funded with ETH. Strategies backed by USD and Bitcoin',
    },
  },
}

export const getMetaVaultMapping = (
  chainId: string,
  metaVault: Address
): MetaVaultMapping => {
  const fallbackMetaVaultMapping: MetaVaultMapping = {
    name: 'Meta Vault',
    assetSymbol: 'META',
    description: 'Maximize yield with this Meta Vault provided by TERM.',
  }

  return metaVaultMapping?.[chainId]?.[metaVault] ?? fallbackMetaVaultMapping
}

export const mapVaultsAndMetaVaults = (
  allChainIds: Set<number>,
  rawVaults: VaultStrategy | undefined,
  vaultsMappings: VaultsMapping | undefined,
  mappedReceiptTokenCurrencies:
    | {
        [chainId: string]: {
          [address: string]: Currency
        }
      }
    | undefined,
  currentYield: MappedYieldResult,
  totalAssetValueData: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  },
  availableDepositLimit: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  },
  availableWithdrawLimit: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  },
  metaVaultStrategyAllocations?: {
    [chainId: string]: MetaVaultStrategyAllocations
  }
): { vaults: VaultData[]; metaVaults: MetaVaultData[] } => {
  return Array.from(allChainIds)
    .map((chainId) => {
      const vaults = rawVaults?.[chainId] ?? []
      return vaults.map((vault) => {
        const chainIdStr = chainId.toString()
        const baseVaultData: BaseVaultData = {
          chainId: chainIdStr,
          address: vault.id,
          purchaseCurrency: {
            address: vault.asset.id,
            symbol: vault.asset.symbol,
            decimals: Number(vault.asset.decimals),
            isRepoToken: false,
          },
          receiptCurrency: mappedReceiptTokenCurrencies?.[chainIdStr]?.[
            vault.id
          ] ?? {
            address: vault.id,
            symbol: 'Unknown',
            decimals: 18,
            isRepoToken: false,
          },
          currentYield: currentYield?.[chainIdStr]?.[vault.id] ?? undefined,
          collateralCurrencies: vault.collateralTokens.map(
            (token) => token.symbol
          ),
          totalAssetValue:
            totalAssetValueData?.[chainIdStr]?.[vault.id] ??
            FixedNumber.fromString('0', `fixed128x${vault.asset.decimals}`),
          availableDepositLimit:
            availableDepositLimit?.[chainIdStr]?.[vault.id] ??
            FixedNumber.fromString('0', `fixed128x${vault.asset.decimals}`),
          availableWithdrawLimit:
            availableWithdrawLimit?.[chainIdStr]?.[vault.id] ??
            FixedNumber.fromString('0', `fixed128x${vault.asset.decimals}`),
        }

        if (vault.isMetaVault) {
          const metaVaultMapping = getMetaVaultMapping(chainIdStr, vault.id)
          return {
            ...baseVaultData,
            isMetaVault: true,
            vaultName: metaVaultMapping.name,
            description: metaVaultMapping.description,
          } as MetaVaultData
        } else {
          return {
            ...baseVaultData,
            isMetaVault: false,
            curator:
              vaultsMappings?.vaultDetails?.[chainIdStr]?.[vault.id]
                ?.curatorName ?? 'Unknown',
            curatorName:
              vaultsMappings?.vaultDetails?.[chainIdStr]?.[vault.id]
                ?.curatorName ?? 'Unknown',
            vaultName:
              vaultsMappings?.vaultDetails?.[chainIdStr]?.[vault.id]
                ?.vaultName ?? 'Unknown',
            curatorIcon:
              vaultsMappings?.vaultDetails?.[chainIdStr]?.[vault.id]?.icon,
            targetAllocation: vault?.assocMetaVaultAddress
              ? FixedNumber.fromString(
                  vaultsMappings?.metaVaultTargets?.[chainIdStr]?.[
                    vault.assocMetaVaultAddress
                  ]?.[vault.id]?.toString() ?? '0',
                  `fixed128x18`
                )
              : FixedNumber.fromString('0', `fixed128x18`),
            actualAllocation: FixedNumber.fromString(
              metaVaultStrategyAllocations?.[chainIdStr]?.[
                vault.id
              ]?.ratio?.toString() ?? '0',
              `fixed128x18`
            ),
          } as VaultData
        }
      })
    })
    .flat()
    .reduce<{ vaults: VaultData[]; metaVaults: MetaVaultData[] }>(
      (acc, vault) => {
        if (vault.isMetaVault) {
          acc.metaVaults.push(vault)
        } else {
          acc.vaults.push(vault)
        }
        return acc
      },
      { vaults: [], metaVaults: [] }
    )
}
