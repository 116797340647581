import {
  Button,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Text,
} from '@chakra-ui/react'
import { VStack, HStack } from '../Stack'
import { ReactNode } from 'react'
import {
  GAS_TOKEN_SYMBOL,
  WRAPPED_GAS_TOKEN_SYMBOL,
} from '../../../helpers/constants'
import { parseUserInput } from '../../../helpers/utils'
import TokensChip from '../TokensChip'
import TokenChip from '../TokenChip'

export default function TokenAmountField({
  label,
  type = 'text',
  placeholder,
  leftElement,
  rightLabel,
  tokenDropdownList,
  helperText,
  value,
  onChange,
  onChangeToken,
  onMax,
  isTokenSelectionDisabled,
  isDisabled,
  w,
  maxLabel = 'MAX',
  isError,
  tooltip,

  tokenSymbol,
  tokenDecimals,
}: {
  label: string | React.ReactNode
  type?: string
  placeholder?: string
  leftElement?: string | ReactNode
  rightLabel?: string | ReactNode
  tokenDropdownList?: string[]
  helperText?: ReactNode
  value: string
  onChange: (value: string) => void
  onChangeToken?: (symbol: string) => void
  onMax?: () => void
  isTokenSelectionDisabled?: boolean
  isDisabled?: boolean
  w?: FormControlProps['w']
  maxLabel?: string

  isError?: boolean

  tooltip?: ReactNode

  tokenSymbol: string
  tokenDecimals: number
}) {
  /* TODO (matt): check this later when unwrapping is completed */
  const isGasToken =
    tokenSymbol === GAS_TOKEN_SYMBOL || tokenSymbol === WRAPPED_GAS_TOKEN_SYMBOL
  const isMaxable = !!onMax
  return (
    <FormControl as="fieldset" w={w} flexShrink={0}>
      <VStack spacing={2} align="start">
        <HStack justify="space-between" alignSelf="stretch">
          <HStack>
            <FormLabel
              as="legend"
              variant="create-tender"
              mb={0}
              color="gray.700"
              fontSize="sm"
              fontWeight="500"
              lineHeight="5"
              mr={0}
            >
              {label}
            </FormLabel>
            {tooltip && tooltip}
          </HStack>
          {rightLabel && (
            <Text
              as="span"
              variant="body-xs/normal"
              color={isError ? 'red.5' : 'gray.5'}
            >
              {rightLabel}
            </Text>
          )}
        </HStack>
        <InputGroup w="100%">
          {leftElement && (
            <InputLeftElement
              pointerEvents="none"
              color="gray.500"
              w={typeof leftElement === 'string' ? 'fit-content' : '20px'}
              children={leftElement}
              fontSize="sm"
            />
          )}
          <Input
            value={value}
            type={type}
            variant="flushed"
            isDisabled={isDisabled}
            placeholder={placeholder}
            color="blue.9"
            pl={leftElement ? '10px' : '4px'}
            pr="2px"
            border={isError ? '1px solid' : undefined}
            borderColor={isError ? 'red.5' : undefined}
            _focus={{
              border: isError ? '1px solid' : undefined,
              borderColor: isError ? 'red.5' : 'blue.5',
            }}
            onChange={(ev) =>
              onChange(parseUserInput(ev.target.value, true, tokenDecimals))
            }
          />
          {/* TODO (matt): check this later when unwrapping is completed */}
          {isGasToken && !isTokenSelectionDisabled ? (
            <InputRightElement
              width="auto"
              color="blue.9"
              fontSize="xs"
              transform="translateX(-4px)"
            >
              <TokensChip
                token={tokenSymbol}
                tokens={[GAS_TOKEN_SYMBOL, WRAPPED_GAS_TOKEN_SYMBOL]}
                onChange={(token) => onChangeToken && onChangeToken(token)}
              />
            </InputRightElement>
          ) : tokenDropdownList && tokenDropdownList.length > 0 ? (
            <InputRightElement
              width="auto"
              color="blue.9"
              fontSize="xs"
              transform="translateX(-4px)"
            >
              <Select
                size="sm"
                pt={0.5}
                pb={1}
                bg="gray.2"
                color="gray.6"
                fontWeight="medium"
                borderRadius={8}
                w="max-content"
                fontSize="xs"
                h={5}
                pl={0.5}
                pr={0}
                onChange={(e) => onChangeToken && onChangeToken(e.target.value)}
                value={tokenSymbol}
              >
                {tokenDropdownList.map((token) => (
                  <option key={token} value={token}>
                    {token}
                  </option>
                ))}
              </Select>
            </InputRightElement>
          ) : (
            <InputRightElement
              width="auto"
              pointerEvents="none"
              color="blue.9"
              fontSize="xs"
              transform="translateX(-4px)"
            >
              <TokenChip token={tokenSymbol} size="sm" />
            </InputRightElement>
          )}
        </InputGroup>
        <HStack
          justifyContent={
            helperText && isMaxable
              ? 'space-between'
              : helperText && !isMaxable
                ? 'flex-start'
                : 'flex-end'
          }
          w="full"
        >
          {helperText && (
            <FormHelperText
              color="gray.5"
              fontSize="xs"
              lineHeight="4"
              marginTop="0 !important"
            >
              {helperText}
            </FormHelperText>
          )}
          {isMaxable && (
            <Button variant="link" onClick={onMax} px={2}>
              <Text variant="body-xs/semibold" color="blue.5">
                {maxLabel}
              </Text>
            </Button>
          )}
        </HStack>
      </VStack>
    </FormControl>
  )
}
