import {
  Box,
  Button,
  Flex,
  HStack,
  Skeleton,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { BigNumber, FixedNumber } from 'ethers'
import { useMemo, useState } from 'react'
import ButtonGroup from '../../../../components/elements/ButtonGroup'
import Chip from '../../../../components/elements/Chip'
import TokenIcon from '../../../../components/elements/TokenIcon'
import {
  bigToFixedNumber,
  fixedToBigNumber,
  formatFixed,
} from '../../../../helpers/conversions'
import {
  divide,
  fixedCompare,
  multiply,
  subtract,
} from '../../../../helpers/math'
import {
  formatFixedUsd,
  parseUserInputCurrencyAmountFN,
} from '../../../../helpers/utils'
import { TabOption } from '../../types'
import VaultInputField from '../VaultInputField'
import SubmitApproveButton from '../../../../components/elements/SubmitApproveButton'
import WrapGasButton from '../../../Auction/elements/TenderSummary/WrapGasButton'
import { VStack } from '../../../../components/elements/Stack'
import { useTermToast } from '../../../../hooks/toasts'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { termToastMessages } from '../../../../helpers/toasts'
import { Address, Currency, NativeCurrency } from '../../../../data/model'
import { toReceiptToken } from '../../../../helpers/receiptToken'

type Props = {
  isDepositPaused: boolean
  convertToAssetsRatio: FixedNumber
  vaultAssetCurrency: Currency
  vaultAssetBalance: FixedNumber
  vaultReceiptCurrency: Currency
  vaultReceiptBalance: FixedNumber
  vaultAssetAllowance: BigNumber
  vaultAssetPrice: FixedNumber
  gasTokenBalance: FixedNumber
  gasTokenCurrency: NativeCurrency

  availableDepositLimit: FixedNumber
  availableWithdrawLimit: FixedNumber

  onVaultAssetApprove: (amount?: string) => Promise<void>
  onWrapGasToken: (value: BigNumber) => Promise<void>
  onDeposit: (amount: string) => Promise<void>
  onWithdraw: (amount: string) => Promise<void>

  account?: Address
  onConnect: () => void
  onKytCheck: () => Promise<boolean>
  isDataLoaded?: boolean
}

const zeroBN = BigNumber.from(0)

export default function VaultDepositWithdraw({
  isDepositPaused,
  convertToAssetsRatio,
  vaultAssetCurrency,
  vaultAssetBalance,
  vaultReceiptCurrency,
  vaultReceiptBalance,
  vaultAssetAllowance,
  vaultAssetPrice,
  gasTokenBalance,
  gasTokenCurrency,

  availableDepositLimit,
  availableWithdrawLimit,

  onVaultAssetApprove,
  onWrapGasToken,
  onDeposit,
  onWithdraw,

  account,
  onConnect,
  onKytCheck,
  isDataLoaded = true,
}: Props) {
  const termToast = useTermToast()
  const currentTime = useCurrentTime()

  const [tab, setTab] = useState<TabOption>(TabOption.Deposit)
  const [amount, setAmount] = useState('')
  const [wrapping, setWrapping] = useState(false)
  const [hasWrapped, setHasWrapped] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const amountFN = parseUserInputCurrencyAmountFN(
    amount,
    vaultAssetCurrency.decimals,
    '0'
  )

  const receiptAmount = useMemo(() => {
    if (tab === TabOption.Deposit) {
      return divide(
        amountFN,
        convertToAssetsRatio ?? FixedNumber.fromString('1', amountFN.format)
      )
    } else {
      return multiply(
        amountFN,
        convertToAssetsRatio ?? FixedNumber.fromString('1', amountFN.format)
      )
    }
  }, [convertToAssetsRatio, amountFN, tab])

  const tokenAmountUsd = multiply(vaultAssetPrice, amountFN)

  const onChangeTab = (tab: TabOption) => {
    setAmount('')
    setTab(tab)
  }

  const inputToken =
    tab === TabOption.Deposit
      ? vaultAssetCurrency.symbol
      : toReceiptToken(vaultAssetCurrency.symbol)

  const [helperText, isError] = useMemo((): [string | undefined, boolean] => {
    const amountFn = parseUserInputCurrencyAmountFN(
      amount,
      vaultAssetCurrency.decimals,
      '0'
    )

    if (
      tab === TabOption.Deposit &&
      vaultAssetBalance &&
      subtract(vaultAssetBalance, amountFn).isNegative()
    ) {
      return ['Insufficient wallet balance', true]
    }

    if (
      tab === TabOption.Deposit &&
      availableDepositLimit &&
      subtract(availableDepositLimit, amountFn).isNegative()
    ) {
      return ['Amount exceeds deposit limit', true]
    }

    if (
      tab === TabOption.Withdraw &&
      vaultReceiptBalance &&
      subtract(vaultReceiptBalance, amountFn).isNegative()
    ) {
      return ['Insufficient wallet balance', true]
    }

    if (
      tab === TabOption.Withdraw &&
      availableWithdrawLimit &&
      subtract(availableWithdrawLimit, amountFn).isNegative()
    ) {
      return ['Amount exceeds withdraw limit', true]
    }

    if (tokenAmountUsd && !tokenAmountUsd.isZero()) {
      return [
        `${formatFixedUsd(tokenAmountUsd, false, true).toString()}`,
        false,
      ]
    }

    return [undefined, false]
  }, [
    amount,
    vaultAssetCurrency.decimals,
    tab,
    vaultAssetBalance,
    availableDepositLimit,
    vaultReceiptBalance,
    availableWithdrawLimit,
    tokenAmountUsd,
  ])

  // amount to wrap for tender = (tendered amount - balance of wrapped gas token)
  const amountToWrap: BigNumber = useMemo(() => {
    if (
      vaultAssetCurrency.symbol === gasTokenCurrency.wrappedGasSymbol &&
      fixedCompare(amountFN, 'gt', vaultAssetBalance)
    ) {
      setHasWrapped(false)
      return fixedToBigNumber(subtract(amountFN, vaultAssetBalance))
    } else {
      return zeroBN
    }
  }, [
    amountFN,
    vaultAssetCurrency.symbol,
    gasTokenCurrency.wrappedGasSymbol,
    vaultAssetBalance,
  ])

  const amountToWrapFN = bigToFixedNumber(
    amountToWrap,
    gasTokenCurrency.decimals
  )

  const handleWrappingStatusChange = (wrapping: boolean) => {
    setWrapping(wrapping)
  }

  const handleWrappedStatusChange = (hasWrapped: boolean) => {
    setHasWrapped(hasWrapped)
  }

  const showWrapGasButton =
    vaultAssetCurrency.symbol === gasTokenCurrency.wrappedGasSymbol &&
    (hasWrapped || amountToWrap.gt(0))

  return (
    <Flex flexDir="column" maxW="584px" bg="white">
      <Box w="full">
        <ButtonGroup
          left={{
            label: 'Deposit',
            value: TabOption.Deposit,
          }}
          right={{
            label: 'Withdraw',
            value: TabOption.Withdraw,
          }}
          value={tab}
          onChange={onChangeTab}
          flexGrow={1}
          w="full"
        />
      </Box>
      <Box mt="20px" w="full">
        <VaultInputField
          label={<Text color="gray.6">Amount</Text>}
          value={amount}
          isDisabled={!account}
          helperText={helperText}
          rightLabel={
            vaultAssetBalance && vaultReceiptBalance && !!account
              ? tab === TabOption.Deposit
                ? formatFixedUsd(vaultAssetBalance)
                : formatFixedUsd(vaultReceiptBalance)
              : undefined
          }
          onChange={setAmount}
          onMax={
            account
              ? () => {
                  if (tab === TabOption.Deposit) {
                    setAmount(
                      (fixedCompare(
                        vaultAssetBalance,
                        'gt',
                        availableDepositLimit
                      )
                        ? availableDepositLimit.toString()
                        : vaultAssetBalance?.toString()) ?? ''
                    )
                  } else {
                    setAmount(
                      (fixedCompare(
                        vaultReceiptBalance,
                        'gt',
                        availableWithdrawLimit
                      )
                        ? availableWithdrawLimit.toString()
                        : vaultReceiptBalance?.toString()) ?? ''
                    )
                  }
                }
              : undefined
          }
          leftElement={
            <Skeleton isLoaded={isDataLoaded}>
              <TokenIcon token={inputToken} boxSize="24px" />
            </Skeleton>
          }
          placeholder="0"
          w={'full'}
          tokenSymbol={
            isDataLoaded
              ? tab === TabOption.Deposit
                ? vaultAssetCurrency.symbol
                : vaultReceiptCurrency.symbol
              : ''
          }
          tokenDecimals={vaultAssetCurrency.decimals}
          isError={isError}
        />
      </Box>
      <Box mt="auto" w="full">
        {!isDataLoaded ? (
          <Button
            variant="tertiary"
            color="white"
            bg="blue.5"
            isDisabled
            leftIcon={<Spinner size="xs" />}
            w="100%"
          />
        ) : !account ? (
          <Button variant="primary" w="100%" onClick={onConnect}>
            Connect wallet
          </Button>
        ) : (
          <>
            <ToReceive
              receiptAmount={receiptAmount}
              assetTokenSymbol={vaultAssetCurrency.symbol}
              receiptTokenSymbol={vaultReceiptCurrency.symbol}
              tab={tab}
            />
            {tab === TabOption.Deposit && (
              <VStack mt={2} spacing="8px" w="full">
                {showWrapGasButton && (
                  <WrapGasButton
                    isWrapButtonDisabled={
                      isDepositPaused || wrapping || hasWrapped
                    }
                    amountToWrap={amountToWrapFN}
                    gasTokenCurrency={gasTokenCurrency}
                    onWrap={async () => {
                      await onWrapGasToken(amountToWrap)
                    }}
                    onWrappingStatusChange={handleWrappingStatusChange}
                    onWrappedStatusChange={handleWrappedStatusChange}
                  />
                )}
                <SubmitApproveButton
                  size="md"
                  step={
                    showWrapGasButton &&
                    fixedCompare(amountFN, 'gt', vaultAssetBalance)
                      ? 2
                      : 1
                  }
                  forcefullyShowStep={showWrapGasButton ? true : false}
                  approvingCurrencySymbol={vaultAssetCurrency.symbol}
                  onApprove={onVaultAssetApprove}
                  onSubmit={async () => {
                    setSubmitting(true)
                    termToast.pending(termToastMessages.vaultDeposit.pending())
                    try {
                      await onDeposit(amount)
                      termToast.success(
                        termToastMessages.vaultDeposit.success(
                          vaultAssetCurrency.symbol,
                          amount
                        )
                      )
                    } catch (error) {
                      if (
                        (error as Error).message.includes(
                          'user rejected transaction'
                        )
                      ) {
                        termToast.dismissed()
                      } else {
                        termToast.failure(
                          termToastMessages.vaultDeposit.failure()
                        )
                      }
                    } finally {
                      setSubmitting(false)
                      setHasWrapped(false)
                    }
                  }}
                  isDisabled={
                    submitting ||
                    isDepositPaused ||
                    amountFN.isZero() ||
                    wrapping ||
                    (amountToWrap.gt(0) && !hasWrapped)
                  }
                  approvalAmount={fixedToBigNumber(amountFN)}
                  submitApprovalAmount={true}
                  approvedAmount={vaultAssetAllowance ?? zeroBN}
                  submitLabel={'Deposit'}
                  onKytCheck={onKytCheck}
                />
              </VStack>
            )}
            {tab === TabOption.Withdraw && (
              <Button
                mt={2}
                variant="primary"
                w="100%"
                isDisabled={submitting || amountFN.isZero() || isError}
                onClick={async () => {
                  setSubmitting(true)
                  termToast.pending(termToastMessages.vaultWithdraw.pending())
                  try {
                    await onWithdraw(amount)
                    termToast.success(
                      termToastMessages.vaultWithdraw.success(
                        vaultReceiptCurrency.symbol,
                        amount
                      )
                    )
                  } catch (error) {
                    if (
                      (error as Error).message.includes(
                        'user rejected transaction'
                      )
                    ) {
                      termToast.dismissed()
                    } else {
                      termToast.failure(
                        termToastMessages.vaultWithdraw.failure()
                      )
                    }
                  } finally {
                    setSubmitting(false)
                  }
                }}
              >
                Withdraw
              </Button>
            )}
          </>
        )}
      </Box>
    </Flex>
  )
}

function ToReceive({
  receiptAmount,
  assetTokenSymbol,
  receiptTokenSymbol,
  tab,
}: {
  receiptAmount?: FixedNumber
  assetTokenSymbol: string
  receiptTokenSymbol: string
  tab: TabOption
}) {
  return (
    <HStack
      justify="space-between"
      display={receiptAmount && !receiptAmount.isZero() ? 'flex' : 'none'}
      transform={
        receiptAmount && !receiptAmount.isZero()
          ? 'translate(0, 6px)'
          : 'translate(0, 42px)'
      }
      transition="0.3s"
      bg="blue.0"
      borderRadius="6px 6px 0px 0px"
      padding="8px 12px 14px 12px"
    >
      <Text color="gray.5.5" variant="body-sm/medium">
        You'll receive
      </Text>
      <Flex>
        <Text as="span" variant="body-sm/bold" color="blue.9">
          {receiptAmount && formatFixed(receiptAmount)}
        </Text>
        <Box ml="8px">
          <Chip>
            <HStack spacing="4px">
              {tab === TabOption.Deposit && (
                <>
                  <TokenIcon token={toReceiptToken(assetTokenSymbol)} />
                  <Text as="span" color="gray.6" variant="body-xs/medium">
                    {receiptTokenSymbol}
                  </Text>
                </>
              )}
              {tab === TabOption.Withdraw && (
                <>
                  <TokenIcon token={assetTokenSymbol} />
                  <Text as="span" color="gray.6" variant="body-xs/medium">
                    {assetTokenSymbol}
                  </Text>
                </>
              )}
            </HStack>
          </Chip>
        </Box>
      </Flex>
    </HStack>
  )
}
