import { useMemo } from 'react'
import protocolIcons from '../../helpers/protocolIcons'

export function useProtocolIcon(protocolName?: string) {
  return useMemo(
    () =>
      protocolIcons[(protocolName ?? '').toUpperCase()] ||
      protocolIcons['unknown'],
    [protocolName]
  )
}
