import {
  Box,
  Circle,
  Flex,
  HStack,
  Image,
  Link,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import yearnLogo from '../../../../assets/icons/projects/yearn.svg'
import { FixedNumber } from 'ethers'
import { ArrowUpRight } from '../../../../components/elements/Icons'
import { shortenAddress } from '../../../../helpers/utils'
import { useChainConfig } from '../../../../providers/config'
import { Currency } from '../../../../data/model'
import { useNetworkWhiteIcons } from '../../../../data/hooks/use-network-icons'
// import VaultCuratorIcon from '../../../../components/elements/VaultCuratorIcon'
import Tooltip from '../../../../components/elements/Tooltip'
import BannerStat from '../../../Vault/elements/Banner/BannerStat'
import VaultTokenIcon from '../../../Vault/elements/Banner/VaultTokenIcon'

type Props = {
  currentYield: FixedNumber | undefined
  pricePerShare: FixedNumber
  totalDeposited: FixedNumber
  connectedWalletDeposits?: FixedNumber

  vaultAssetCurrency: Currency
  vaultReceiptCurrency: Currency
  vaultReceiptBalance: FixedNumber

  metaVaultName: string
  metaVaultAddress: string
  metaVaultIcon: string
  chainId: string

  isDataLoaded?: boolean
}

export default function MetaVaultBanner({
  currentYield,
  pricePerShare,
  totalDeposited,
  connectedWalletDeposits = FixedNumber.fromString('0', `fixed128x128`),

  vaultAssetCurrency,
  vaultReceiptCurrency,
  vaultReceiptBalance,

  metaVaultName,
  metaVaultAddress,
  metaVaultIcon,
  chainId,

  isDataLoaded = true,
}: Props) {
  const chainConfig = useChainConfig(chainId)
  const networkIcon = useNetworkWhiteIcons(chainId)

  const VAULT_TYPE = 'Yearn V3'

  const textRef = useRef<HTMLDivElement>(null)
  const [isMetaVaultNameTruncated, setIsMetaVaultNameTruncated] =
    useState(false)

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current
      setIsMetaVaultNameTruncated(scrollWidth > offsetWidth)
    }
  }, [metaVaultName])

  return (
    <Flex
      w="full"
      bg="blue.10"
      borderRadius="6px"
      border="0px solid gray.2"
      color="#fff"
      overflow="hidden"
      justifyContent="space-between"
    >
      {/* Meta Vault Info */}
      <VStack spacing={3} align="start" justifyContent="center" pl={6}>
        <Skeleton isLoaded={isDataLoaded} h="40px" w="100%">
          <HStack spacing={3}>
            {/* <VaultCuratorIcon vaultName={metaVaultIcon ?? 'unknown'} /> */}
            <Tooltip
              noDelay
              label={isMetaVaultNameTruncated ? metaVaultName : undefined}
            >
              <Text
                ref={textRef}
                variant="body-4xl/normal"
                // noOfLines={1}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxW="100%"
              >
                {metaVaultName}
                {/* {vaultCurator} {vaultAssetCurrency.symbol} Vault */}
              </Text>
            </Tooltip>
          </HStack>
        </Skeleton>
        <HStack color="white" opacity={0.7} spacing={1} w="full">
          <Skeleton isLoaded={isDataLoaded} h="22px" w="90px">
            <Box w="90px">
              <Link
                href={chainConfig?.getExplorerAddressLink(metaVaultAddress)}
                isExternal
              >
                <HStack spacing="4px">
                  <Text
                    variant="body-sm/medium"
                    color="white"
                    opacity={0.7}
                    noOfLines={1}
                  >
                    {shortenAddress(metaVaultAddress, {
                      charsFront: 2,
                      charsBack: 2,
                    })}
                  </Text>
                  <ArrowUpRight color="white" opacity={0.5} h={4} />
                </HStack>
              </Link>
            </Box>
          </Skeleton>
          <Circle bg="white" opacity={0.7} size="2px" />
          {/* Chain Name */}
          <Skeleton isLoaded={isDataLoaded} h="22px" w="80px">
            <HStack spacing="4px" w="80px">
              <Text variant="body-sm/medium" noOfLines={1}>
                {chainConfig?.chainName}
              </Text>
              <Image src={networkIcon} w="16px" h="16px" opacity={0.7} />
            </HStack>
          </Skeleton>
          <Circle bg="white" opacity={0.7} size="2px" />
          {/* Vault Type */}
          <Skeleton isLoaded={isDataLoaded} h="22px" w="85px">
            <HStack spacing="4px" w="85px">
              <Text variant="body-sm/medium" noOfLines={1}>
                {VAULT_TYPE}
              </Text>
              <Image src={yearnLogo} w="16px" h="16px" opacity={0.7} />
            </HStack>
          </Skeleton>
        </HStack>
      </VStack>

      {/* Stats */}
      <HStack spacing={6} pl={4} pb={6}>
        {/* Current Yield */}
        <BannerStat
          title="Current Yield"
          value={currentYield}
          symbol={vaultAssetCurrency.symbol}
          isDataLoaded={isDataLoaded}
          isPercentage
          overrideValue={!currentYield ? 'N/A' : undefined}
          maxW="140px"
        />
        <BannerStat
          title="Total Deposited"
          value={totalDeposited}
          symbol={vaultAssetCurrency.symbol}
          isDataLoaded={isDataLoaded}
          maxW="140px"
        />
        <BannerStat
          title="Your value"
          value={connectedWalletDeposits}
          symbol={vaultAssetCurrency.symbol}
          isDataLoaded={isDataLoaded}
          maxW="140px"
        />
        <BannerStat
          title="Price Per Share"
          value={pricePerShare}
          symbol={vaultAssetCurrency.symbol}
          isDataLoaded={isDataLoaded}
          maxW="140px"
        />
      </HStack>
      <Skeleton
        isLoaded={isDataLoaded}
        h="full"
        borderTopLeftRadius="0"
        borderBottomLeftRadius="0"
      >
        <VaultTokenIcon symbol={vaultAssetCurrency.symbol} />
      </Skeleton>
    </Flex>
  )
}
