import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { isAddress } from 'ethers/lib/utils'
import { useState } from 'react'
import { parseUserInput } from '../../../../helpers/utils'
import { INTEREST_RATE_DECIMALS } from '../../../../helpers/constants'

export const VaultModalBase = ({
  title,
  description,
  titleInput,
  contentCheckbox,
  isOpen,
  placeholder,
  textButton,
  width = 480,
  hasRevert = false,
  isAddressInput = false,
  defaultValue,
  actionButton,
  onClose,
  submitting,
}: {
  title: string
  description?: string
  contentCheckbox: string
  titleInput: string
  isOpen: boolean
  placeholder: string
  textButton: string
  width?: number
  hasRevert?: boolean
  defaultValue?: string
  isAddressInput?: boolean
  actionButton: (input: string) => Promise<void>
  onClose: () => void
  submitting: boolean
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isError, setIsError] = useState(false)
  const [valueInput, setValueInput] = useState(defaultValue || '')

  const handleOnChangeInput = (value: string) => {
    if (isAddressInput) {
      setIsError(!isAddress(value))
      setValueInput(value)
    } else {
      setValueInput(parseUserInput(value, true, INTEREST_RATE_DECIMALS))
    }
  }

  const closeModal = () => {
    onClose()
    setValueInput(defaultValue || '')
    setIsError(false)
    setIsConfirmed(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} variant="standard">
      <ModalOverlay />

      <ModalContent p={1}>
        <ModalHeader>
          <Text variant="body-2xl/semibold">
            <Trans>{title}</Trans>
          </Text>
          <ModalCloseButton m={2} />
        </ModalHeader>

        <ModalBody width={width}>
          {description && (
            <Text variant="body-sm/normal" color="blue.9" pb={5}>
              <Trans>{description}</Trans>
            </Text>
          )}

          <Box>
            <Text variant="body-sm/medium" color="gray.6">
              <Trans>{titleInput}</Trans>
            </Text>
            <FormControl isInvalid={isError}>
              <Input
                value={valueInput}
                placeholder={placeholder}
                onChange={(e) => handleOnChangeInput(e.target.value)}
                variant="flushed"
                fontSize="sm"
                _hover={{ background: 'transparent' }}
                _focus={{
                  border: 'none',
                  background: 'transparent',
                }}
              />
            </FormControl>
          </Box>

          <Box display="flex" gap={3} mt={10} alignItems="flex-start">
            <Checkbox
              size="lg"
              isChecked={isConfirmed}
              onChange={() => setIsConfirmed(!isConfirmed)}
            />
            <Text variant="body-sm/normal" color="gray.6">
              {' '}
              <Trans>{contentCheckbox}</Trans>
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter pt={-2} mb={2.5}>
          <Box display="flex" alignItems="center" width="100%" gap={10}>
            {hasRevert && (
              <Box>
                {' '}
                <Button
                  variant="tertiary"
                  border="none"
                  _disabled={{
                    opacity: 0.5,
                    cursor: 'not-allowed',
                  }}
                  isDisabled={
                    !valueInput ||
                    isError ||
                    Number(valueInput) === Number(defaultValue)
                  }
                >
                  {' '}
                  <Trans>Revert</Trans>
                </Button>
              </Box>
            )}
            <Button
              variant="secondary"
              width="100%"
              _disabled={{
                opacity: 0.5,
                cursor: 'not-allowed',
              }}
              isDisabled={
                submitting ||
                !valueInput ||
                !isConfirmed ||
                isError ||
                !!(defaultValue && Number(valueInput) === Number(defaultValue))
              }
              onClick={() => actionButton(valueInput)}
            >
              <Trans>{textButton}</Trans>
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default VaultModalBase
