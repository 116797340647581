import { Box, HStack, Text, Image, VStack } from '@chakra-ui/react'
import RepoCardHeading from '../../../Lend/elements/RepoCardHeading'
import Tooltip from '../../../../components/elements/Tooltip'
import TokenIcon from '../../../../components/elements/TokenIcon'
import TokenIconMultiple from '../../../../components/elements/TokenIconMultiple'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import sparkle from '../../../../assets/icons/sparkle.svg'
import { useChainConfig } from '../../../../providers/config'
import RepoCardButton from '../../../Lend/elements/RepoCardButton'
import { formatFixedToken } from '../../../../helpers/utils'
import { MetaVaultData } from '../../../../models/vaults'

export default function MetaVaultCard({
  metaVault,
  onViewMetaVault,
}: {
  metaVault: MetaVaultData
  onViewMetaVault: (metaVaultAddress: string, chainId: string) => void
}) {
  const chainConfig = useChainConfig(metaVault.chainId)

  let formattedYieldPercentage: string | undefined
  if (metaVault?.currentYield) {
    formattedYieldPercentage = fixedToFormattedPercentage(
      metaVault.currentYield,
      2,
      true
    ).formattedPercentage
  }

  return (
    <HStack
      minW={{
        base: '922px',
      }}
      position="relative"
      borderRadius="6px"
      overflow="visible"
      boxShadow={'0px 4px 4px rgba(49, 130, 206, 0.03)'}
      p={3}
      justifyContent="space-between"
      background={chainConfig?.gradient ?? 'white'}
      transition="all .25s ease-in-out"
      spacing={14}
    >
      <HStack spacing={8}>
        {/* Meta vault asset icon */}
        <TokenIcon token={metaVault.purchaseCurrency.symbol} boxSize="48px" />
        <Box maxW="355px" minW="290px">
          {/* Meta vault Name */}
          <Text fontSize="20px" fontWeight="bold">
            {metaVault.vaultName}
          </Text>
          {/* Meta vault description */}
          <Text fontSize="14px" fontWeight="normal">
            {metaVault.description}
          </Text>
        </Box>
      </HStack>

      <HStack>
        <HStack spacing={8}>
          {/* Current Yield */}
          <Box
            h="100%"
            borderRight={{
              base: '1px solid',
            }}
            borderRightColor={{
              base: 'gray.3',
            }}
            w="138px"
          >
            <RepoCardHeading title="Current Yield" mb={2} />
            {!formattedYieldPercentage ? (
              <HStack>
                <Text variant="body-2xl/bold" color="black">
                  N/A
                </Text>
                <Tooltip
                  noDelay
                  placement="bottom"
                  label={'More data needed to display yield'}
                >
                  <Image boxSize={6} src={sparkle} />
                </Tooltip>
              </HStack>
            ) : (
              <HStack w="100%">
                <Text variant="body-2xl/bold" color="black">
                  {formattedYieldPercentage}%
                </Text>
                <Tooltip noDelay placement="bottom" label="Current Yield">
                  <Image boxSize="24px" src={sparkle} alt={'Fixed APY'} />
                </Tooltip>
              </HStack>
            )}
          </Box>

          {/* Collateral */}
          <VStack px={0} spacing={0} alignItems="start">
            <RepoCardHeading title="Collateral" mb={2} />
            <Tooltip
              noDelay
              placement="bottom"
              label={metaVault.collateralCurrencies.join(', ')}
            >
              <Box>
                <TokenIconMultiple symbols={metaVault.collateralCurrencies} />
              </Box>
            </Tooltip>
          </VStack>
        </HStack>

        <HStack>
          {/* Total Asset Value */}
          <VStack
            mr={'52px'}
            py={2.5}
            spacing={0}
            alignItems="end"
            width={{
              base: '168px',
              xl: '300px',
            }}
          >
            <RepoCardHeading title="Total Asset Value" mb={2} />
            <Text variant="body-l/normal" color="blue.9" whiteSpace="nowrap">
              {formatFixedToken(
                metaVault.totalAssetValue,
                metaVault.purchaseCurrency.symbol,
                false
              )}
            </Text>
          </VStack>
          {/* Button */}
          <VStack
            minW={{
              base: 'max-content',
            }}
            px={{
              base: 0,
            }}
            gap={1}
            alignItems="end"
          >
            <HStack spacing={0.5} justifyContent="right" h="16px">
              <Text
                variant="body-xs/normal"
                color="gray.5.5"
                textAlign={{
                  base: 'right',
                }}
                whiteSpace="nowrap"
                ml="auto"
              >
                {chainConfig?.chainName}
              </Text>
              <NetworkIcon
                key={metaVault.chainId}
                chainId={metaVault.chainId}
                boxSize={'16px'}
                variant="faded"
                withTooltip
              />
            </HStack>
            <RepoCardButton
              handleClick={() => {
                onViewMetaVault(metaVault.address, metaVault.chainId)
              }}
            >
              View
            </RepoCardButton>
          </VStack>
        </HStack>
      </HStack>
    </HStack>
  )
}
