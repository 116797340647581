import {
  Button,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { VStack } from '../../../../components/elements/Stack'
// import ShutdownWithdrawalsModal from '../ShutdownWithdrawalsModal'
import DisableRepoTokenModal from '../VaultModal/DisableRepoTokenModal'
import EditMarkupModal from '../VaultModal/EditMarkupModal'
import UpdateDiscountRateAdapterModal from '../VaultModal/UpdateDiscountRateAdapterModal'
import { Address } from '../../../../data/model'
import UpdateTermControllerModal from '../VaultModal/UpdateTermControllerModal'
import { useTermToast } from '../../../../hooks/toasts'
import { useState } from 'react'
import { termToastMessages } from '../../../../helpers/toasts'

export default function VaultCuratorAdminPanel({
  isDepositPaused,
  isVaultPaused,
  isGovernor,
  isManager,
  vaultAddress,
  currentTermController,
  currentDiscountRateMarkup,
  currentDiscountRateAdapter,
  onSetTermController,
  onSetDiscountRateAdapter,
  onSetDiscountRateMarkup,
  onSetRepoTokenBlacklist,
  onEditCollateralParameters,
  onPauseStrategy,
  onUnpauseStrategy,
  onPauseDeposit,
  onUnpauseDeposit,
}: {
  isDepositPaused: boolean
  isVaultPaused: boolean
  isGovernor: boolean
  isManager: boolean
  vaultAddress: Address
  currentTermController: Address
  currentDiscountRateAdapter?: Address
  currentDiscountRateMarkup?: string
  onSetTermController: (address: Address) => Promise<void>
  onSetDiscountRateAdapter: (address: Address) => Promise<void>
  onSetDiscountRateMarkup: (markup: string) => Promise<void>
  onSetRepoTokenBlacklist: (address: Address) => Promise<void>
  onEditCollateralParameters: (idx: number) => void
  onPauseStrategy: () => Promise<void>
  onUnpauseStrategy: () => Promise<void>
  onPauseDeposit: () => Promise<void>
  onUnpauseDeposit: () => Promise<void>
}) {
  const {
    isOpen: isDisableRepoTokenOpen,
    onOpen: onDisableRepoTokenOpen,
    onClose: onDisableRepoTokenClose,
  } = useDisclosure()

  const {
    isOpen: isEditMarkupModalOpen,
    onOpen: onEditMarkupModalOpen,
    onClose: onEditMarkupModalClose,
  } = useDisclosure()

  const {
    isOpen: isUpdateTermControllerModalOpen,
    onOpen: onUpdateTermControllerModalOpen,
    onClose: onUpdateTermControllerModalClose,
  } = useDisclosure()

  const {
    isOpen: isUpdateAdapterModalOpen,
    onOpen: onUpdateAdapterModalOpen,
    onClose: onUpdateAdapterModalClose,
  } = useDisclosure()

  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)

  // const {
  //   isOpen: isShutdownWithdrawalsModalOpen,
  //   onOpen: onShutdownWithdrawalsModalOpen,
  //   onClose: onShutdownWithdrawalsModalClose,
  // } = useDisclosure()

  const handleOnPauseDeposit = async () => {
    setSubmitting(true)
    termToast.pending(termToastMessages.onPauseDeposit.pending())
    try {
      await onPauseDeposit()
      termToast.success(termToastMessages.onPauseDeposit.success())
    } catch (error) {
      console.error(error)
      if ((error as Error).message.includes('user rejected transaction')) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.onPauseDeposit.failure())
      }
    } finally {
      setSubmitting(false)
    }
  }

  const handleOnUnpauseDeposit = async () => {
    setSubmitting(true)
    termToast.pending(termToastMessages.onUnpauseDeposit.pending())
    try {
      await onUnpauseDeposit()
      termToast.success(termToastMessages.onUnpauseDeposit.success())
    } catch (error) {
      console.error(error)
      if ((error as Error).message.includes('user rejected transaction')) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.onUnpauseDeposit.failure())
      }
    } finally {
      setSubmitting(false)
    }
  }

  const handleOnPauseStrategy = async () => {
    setSubmitting(true)
    termToast.pending(termToastMessages.onPauseStrategy.pending())
    try {
      await onPauseStrategy()
      termToast.success(termToastMessages.onPauseStrategy.success())
    } catch (error) {
      console.error(error)
      if ((error as Error).message.includes('user rejected transaction')) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.onPauseStrategy.failure())
      }
    } finally {
      setSubmitting(false)
    }
  }

  const handleOnUnpauseStrategy = async () => {
    setSubmitting(true)
    termToast.pending(termToastMessages.onUnpauseStrategy.pending())
    try {
      await onUnpauseStrategy()
      termToast.success(termToastMessages.onUnpauseStrategy.success())
    } catch (error) {
      console.error(error)
      if ((error as Error).message.includes('user rejected transaction')) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.onUnpauseStrategy.failure())
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <VStack spacing={4} alignItems="left" bg="white" borderRadius={2}>
      <Text variant="body-l/semibold" color="blue.9">
        <Trans>Curator Admin Panel</Trans>
      </Text>

      <Link href={`/?vault=${vaultAddress}`} w="100%">
        <Button
          variant="primary"
          w="100%"
          rightIcon={<FontAwesomeIcon icon={['far', 'arrow-up-right']} />}
        >
          <Trans>View auctions</Trans>
        </Button>
      </Link>

      <Flex flexDir="column">
        <Flex alignItems="center" justify="space-between">
          <Button
            variant="tertiary"
            border="none"
            isDisabled={!isGovernor}
            p="0"
            onClick={onDisableRepoTokenOpen}
          >
            <Trans>Disable Repo token</Trans>
          </Button>

          <Menu>
            <MenuButton
              as={Button}
              rightIcon={
                <FontAwesomeIcon
                  icon={['far', 'chevron-down']}
                  role="button"
                  width="12px"
                  style={{
                    marginLeft: '7px',
                  }}
                />
              }
              variant="body-md/semibold"
              color="gray.5"
            >
              <Trans>Advanced settings</Trans>
            </MenuButton>
            <MenuList boxShadow=" 0px 0px 24px 0px #00000014" border="none">
              <MenuItem
                isDisabled={!isGovernor}
                onClick={onUpdateTermControllerModalOpen}
                color="gray.700"
              >
                <Trans>Update term controller</Trans>
              </MenuItem>

              <MenuItem
                onClick={onUpdateAdapterModalOpen}
                color="gray.700"
                isDisabled={!isGovernor}
              >
                <Trans>Update discount rate adapter</Trans>
              </MenuItem>
              {isDepositPaused ? (
                <MenuItem
                  onClick={handleOnUnpauseDeposit}
                  color="green.5"
                  isDisabled={!isGovernor}
                >
                  <Trans>Unpause deposits</Trans>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={handleOnPauseDeposit}
                  color="red.5"
                  isDisabled={!isGovernor}
                >
                  <Trans>Pause deposits</Trans>
                </MenuItem>
              )}
              {isVaultPaused ? (
                <MenuItem
                  onClick={handleOnUnpauseStrategy}
                  color="green.5"
                  isDisabled={!isGovernor}
                >
                  <Trans>Unpause vault</Trans>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={handleOnPauseStrategy}
                  color="red.5"
                  isDisabled={!isGovernor || submitting}
                >
                  <Trans>Pause vault</Trans>
                </MenuItem>
              )}
              {/* <MenuItem onClick={onShutdownWithdrawalsModalOpen} color="red.5">
                <Trans>Shutdown withdrawals</Trans>
              </MenuItem> */}
            </MenuList>
          </Menu>
        </Flex>

        <Button
          variant="tertiary"
          border="none"
          p="0"
          w="fit-content"
          isDisabled={!isGovernor}
          onClick={() => {
            // 2 is the index of the Collateral tab
            onEditCollateralParameters(2)
          }}
        >
          <Trans>Edit collateral parameters</Trans>
        </Button>

        <Button
          variant="tertiary"
          border="none"
          p="0"
          w="fit-content"
          isDisabled={!isGovernor}
          onClick={onEditMarkupModalOpen}
        >
          {' '}
          <Trans>Edit markup</Trans>
        </Button>
      </Flex>

      <DisableRepoTokenModal
        onSetRepoTokenBlacklist={onSetRepoTokenBlacklist}
        isOpen={isDisableRepoTokenOpen}
        onClose={onDisableRepoTokenClose}
      />
      <EditMarkupModal
        currentDiscountRateMarkup={currentDiscountRateMarkup}
        onSetDiscountRateMarkup={onSetDiscountRateMarkup}
        isOpen={isEditMarkupModalOpen}
        onClose={onEditMarkupModalClose}
      />
      <UpdateTermControllerModal
        currentTermController={currentTermController}
        onSetTermController={onSetTermController}
        isOpen={isUpdateTermControllerModalOpen}
        onClose={onUpdateTermControllerModalClose}
      />
      <UpdateDiscountRateAdapterModal
        currentDiscountRateAdapter={currentDiscountRateAdapter}
        onSetDiscountRateAdapter={onSetDiscountRateAdapter}
        isOpen={isUpdateAdapterModalOpen}
        onClose={onUpdateAdapterModalClose}
      />

      {/* <ShutdownWithdrawalsModal
        onShutdownStrategy={onShutdownStrategy}
        isOpen={isShutdownWithdrawalsModalOpen}
        onClose={onShutdownWithdrawalsModalClose}
      /> */}
    </VStack>
  )
}
