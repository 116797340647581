import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { BigNumber, FixedNumber, Signer } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGraphQueries } from '../data/hooks/helper-hooks'
import { TokenInfoPair, usePrices } from '../data/hooks/use-prices'
import { useVaults } from '../data/hooks/use-vaults'
import { Address, Currency, MappedLendCurrencies } from '../data/model'
import {
  PageVaultsQuery,
  PageVaultsQueryVariables,
} from '../gql/vaults/graphql'
import { add, divide, fixedCompare, multiply } from '../helpers/math'
import { calculateCurrentYield, mapSentioAPIResults } from '../helpers/utils'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import { useChainConfigs, useConfig } from '../providers/config'
import { useGlobalRefresher } from '../providers/refresher'
import { useVaultsMappings } from '../data/hooks/use-vaults-mappings'
import { useSentioData } from '../data/hooks/helper-hooks'
import { mapVaultsAndMetaVaults } from '../pages/Vaults/utils'
import {
  MetaVaultData,
  VaultData,
  VaultsMapping,
  VaultsPageParams,
  VaultStrategy,
} from '../models/vaults'
import { useMetaVaults } from '../data/hooks/use-meta-vaults'
import { useBalances } from '../data/hooks/use-balances'
import { TokenInfo, useCurrencies } from '../data/hooks/use-currencies'
import { useConvertToAssets } from '../data/hooks/use-convert-to-assets'
import { TokenAllowance, useAllowances } from '../data/hooks/use-allowances'
import { bigToFixedNumber, fixedToBigNumber } from '../helpers/conversions'
import { useTokensApprove } from '../data/hooks/use-token-approve'

export const composeVaultsPageViewModel = (
  rawVaults: VaultStrategy | undefined,
  vaultsMappings: VaultsMapping | undefined,
  currentPricePerShare: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  },
  sentioAPIResults: {
    [chainId: string]: {
      [strategyAddress: Address]: {
        price: FixedNumber | null | undefined
        timestamp: number
      }
    }
  },
  totalAssetValueData: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  },
  availableDepositLimit: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  },
  availableWithdrawLimit: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  },
  prices:
    | {
        [chainId: string]: {
          [token: Address]: FixedNumber
        }
      }
    | undefined,
  mappedReceiptTokenBalances:
    | {
        [chainId: string]: {
          [address: string]: FixedNumber
        }
      }
    | undefined,
  mappedVaultAssetTokenBalances:
    | {
        [chainId: string]: {
          [address: string]: FixedNumber
        }
      }
    | undefined,
  mappedReceiptTokenCurrencies:
    | {
        [chainId: string]: {
          [address: string]: Currency
        }
      }
    | undefined,
  assetBalancesFromShares:
    | {
        [chainId: string]: {
          [strategyAddress: string]:
            | {
                balance: FixedNumber
                asset: Address
              }
            | null
            | undefined
        }
      }
    | undefined
): {
  vaults: VaultData[]
  metaVaults: MetaVaultData[]
  totalVaultsDepositedUSD: FixedNumber
  totalMetaVaultsDepositedUSD: FixedNumber
  connectedAccountReceiptTokenBalances:
    | {
        [chainId: string]: {
          [address: string]: FixedNumber
        }
      }
    | undefined
  connectedAccountVaultAssetTokenBalances:
    | {
        [chainId: string]: {
          [address: string]: FixedNumber
        }
      }
    | undefined
  connectedAccountPositions:
    | {
        deposits: FixedNumber
        numberOfPositions: number
        averageYield: FixedNumber
      }
    | undefined
} => {
  const allChainIds = new Set(
    [
      ...Object.keys(rawVaults ?? {}),
      ...Object.keys(totalAssetValueData ?? {}),
      ...Object.keys(currentPricePerShare ?? {}),
    ].map((k) => parseInt(k, 10))
  )

  // ((current pps / previous pps) ^ (365.25/days between updates) - 1) * 100
  const currentYield = calculateCurrentYield(
    currentPricePerShare,
    sentioAPIResults
  )

  const { vaults, metaVaults } = mapVaultsAndMetaVaults(
    allChainIds,
    rawVaults,
    vaultsMappings,
    mappedReceiptTokenCurrencies,
    currentYield,
    totalAssetValueData,
    availableDepositLimit,
    availableWithdrawLimit
  )

  const totalVaultsDepositedUSD = vaults.reduce((acc, vault) => {
    const price =
      prices?.[vault.chainId]?.[vault.purchaseCurrency.address] ??
      FixedNumber.fromString('0')
    const usdValue = multiply(vault.totalAssetValue, price)
    return add(acc, usdValue)
  }, FixedNumber.fromString('0'))

  const totalMetaVaultsDepositedUSD = metaVaults.reduce((acc, vault) => {
    const price =
      prices?.[vault.chainId]?.[vault.purchaseCurrency.address] ??
      FixedNumber.fromString('0')
    const usdValue = multiply(vault.totalAssetValue, price)
    return add(acc, usdValue)
  }, FixedNumber.fromString('0'))

  const connectedAccountPositions = assetBalancesFromShares
    ? Object.entries(assetBalancesFromShares).map(
        ([chainId, balancesByStrategy]) => {
          const numberOfPositions = Object.values(balancesByStrategy).filter(
            (value) =>
              value &&
              fixedCompare(value.balance, 'gt', FixedNumber.fromString('0'))
          ).length

          let totalWeightedYield = FixedNumber.fromString('0')
          let totalValue = FixedNumber.fromString('0')

          // Calculate weighted yield and total value
          Object.entries(balancesByStrategy).forEach(
            ([strategyAddress, balance]) => {
              if (balance) {
                const price =
                  prices?.[chainId]?.[balance.asset] ??
                  FixedNumber.fromString('0')
                const positionValue = multiply(balance.balance, price)
                const yieldForStrategy =
                  currentYield?.[chainId]?.[strategyAddress] ??
                  FixedNumber.fromString('0')

                // Add to totals
                totalValue = add(totalValue, positionValue)
                totalWeightedYield = add(
                  totalWeightedYield,
                  multiply(positionValue, yieldForStrategy)
                )
              }
            }
          )
          // Calculate final weighted average
          const averageYield =
            numberOfPositions > 0 &&
            !fixedCompare(totalValue, 'eq', FixedNumber.fromString('0'))
              ? divide(totalWeightedYield, totalValue)
              : FixedNumber.fromString('0')

          return {
            deposits: totalValue,
            numberOfPositions,
            averageYield: divide(averageYield, FixedNumber.fromString('100')),
          }
        }
      )?.[0]
    : undefined

  return {
    vaults,
    metaVaults,
    totalVaultsDepositedUSD,
    totalMetaVaultsDepositedUSD,
    connectedAccountReceiptTokenBalances: mappedReceiptTokenBalances,
    connectedAccountVaultAssetTokenBalances: mappedVaultAssetTokenBalances,
    connectedAccountPositions,
  }
}

export function useVaultsPage(
  account: Address | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  onConnect: () => void,
  onKytCheck: () => Promise<boolean>,
  onCheckActiveNetwork: (
    chainId?: number,
    chainName?: string
  ) => Promise<boolean>
) {
  const config = useConfig()
  const chainConfigs = useChainConfigs()
  const { slow: autoRefresher } = useGlobalRefresher()
  const navigate = useNavigate()

  // subgraph queries
  const queries = useMemo(() => {
    return chainConfigs.map((chainConfig) => {
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(
        subgraphVersion,
        DocumentType.PAGE_VAULTS
      )

      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.PAGE_VAULTS,
        variables: {},
      })

      return {
        chainId: chainConfig.chainId,
        url: chainConfig.vaultsSubgraphUrl!,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [chainConfigs])

  const {
    results: data,
    fetching,
    error,
    refresh: readFromSubgraph,
  } = useGraphQueries<PageVaultsQuery, PageVaultsQueryVariables>(
    queries.length > 0 ? queries : []
  )

  useEffect(() => {
    if (queries.length > 0) {
      readFromSubgraph()
    }
  }, [readFromSubgraph, autoRefresher, queries.length])

  const mappedVaultsSubgraphData = useMemo(() => {
    if (!data || Object.keys(data).length === 0)
      return {
        vaultStrategies: {},
        mappedCurrencies: {},
        singleStrategyAddressesByChain: {},
        multiStrategyAddressesByChain: {},
        assets: {},
      }

    return Object.entries(data).reduce(
      (acc, [chainIdStr, result]) => {
        const chainId = Number(chainIdStr)

        acc.vaultStrategies[chainId] = []
        acc.singleStrategyAddressesByChain[chainId] = []
        acc.multiStrategyAddressesByChain[chainId] = []
        acc.assets[chainId] = []

        result?.termVaultStrategies
          ?.filter(
            (strat) =>
              !config.chains[chainIdStr].vaultsToFilterOut.includes(
                strat.id.toLowerCase()
              )
          )
          ?.forEach((vault) => {
            const { id, asset, collateralTokens } = vault

            acc.vaultStrategies[chainId].push({
              id,
              isMetaVault: false,
              collateralTokens,
              asset,
            })

            if (asset) {
              const { symbol, id: tokenAddress } = asset
              acc.mappedCurrencies[symbol] = acc.mappedCurrencies[symbol] || {}
              acc.mappedCurrencies[symbol][chainId] = tokenAddress
              acc.assets[chainId]?.push({
                address: tokenAddress,
                decimals: Number(asset.decimals),
              })
            }

            acc.singleStrategyAddressesByChain[chainId].push(id)
          })

        result?.termMultiStrats
          ?.filter(
            (multiStrat) =>
              !config.chains[chainIdStr].vaultsToFilterOut.includes(
                multiStrat.id.toLowerCase()
              )
          )
          ?.forEach((vault) => {
            const collateralTokens = Array.from(
              new Map(
                vault.strategies
                  .flatMap((strategy) => strategy.collateralTokens)
                  .map((token) => [token.id, token]) // Use map to deduplicate based on token.id
              ).values()
            )

            acc.vaultStrategies[chainId].push({
              id: vault.id,
              isMetaVault: true,
              collateralTokens,
              asset: {
                id: vault?.asset?.id ?? '',
                name: vault?.asset?.name ?? '',
                decimals: vault?.asset?.decimals ?? '',
                symbol: vault?.asset?.symbol ?? '',
              },
            })

            acc.multiStrategyAddressesByChain[chainId].push(vault.id)
          })
        return acc
      },
      {
        vaultStrategies: {} as VaultStrategy,
        mappedCurrencies: {} as MappedLendCurrencies,
        singleStrategyAddressesByChain: {} as { [chainId: string]: Address[] },
        multiStrategyAddressesByChain: {} as { [chainId: string]: Address[] },
        assets: {} as { [chainId: string]: TokenInfoPair[] | undefined },
      }
    )
  }, [config.chains, data])

  const {
    vaultStrategies,
    mappedCurrencies,
    assets,
    singleStrategyAddressesByChain,
    multiStrategyAddressesByChain,
  } = mappedVaultsSubgraphData

  const assetDecimals = useMemo(() => {
    if (!assets) return {}

    return Object.entries(assets).reduce(
      (acc, [chainId, tokenInfoPairs]) => {
        if (tokenInfoPairs) {
          acc[chainId] = tokenInfoPairs.map((token) => token.decimals)
        }
        return acc
      },
      {} as { [chainId: string]: number[] | undefined }
    )
  }, [assets])

  const assetDecimalsPerStrategy = useMemo(() => {
    if (!vaultStrategies) return {}

    return Object.entries(vaultStrategies).reduce(
      (acc, [chainId, strategies]) => {
        acc[chainId] = strategies.reduce(
          (acc, strategy) => {
            acc[strategy.id] = Number(strategy.asset.decimals)
            return acc
          },
          {} as { [strategyAddress: Address]: number }
        )
        return acc
      },
      {} as { [chainId: string]: { [strategyAddress: Address]: number } }
    )
  }, [vaultStrategies])

  const pricesData = usePrices(assets, undefined, provider)
  const {
    currentPricePerShare: singleStrategyCurrentPricePerShare,
    totalAssetValue: singleStrategyTotalAssetValue,
    availableDepositLimit: singleStrategyAvailableDepositLimit,
    availableWithdrawLimit: singleStrategyAvailableWithdrawLimit,
    deposit: singleStrategyDeposit,
    withdraw: singleStrategyWithdraw,
  } = useVaults(
    account,
    singleStrategyAddressesByChain,
    assetDecimals,
    signer,
    provider,
    () => {}
  )

  const {
    currentPricePerShare: multiStrategyCurrentPricePerShare,
    totalAssetValue: multiStrategyTotalAssetValue,
    availableDepositLimit: multiStrategyAvailableDepositLimit,
    availableWithdrawLimit: multiStrategyAvailableWithdrawLimit,
    deposit: multiStrategyDeposit,
    withdraw: multiStrategyWithdraw,
  } = useMetaVaults(
    account,
    multiStrategyAddressesByChain,
    assetDecimals,
    signer,
    provider,
    () => {}
  )

  const {
    combinedStrategyCurrentPricePerShare,
    combinedStrategyTotalAssetValue,
    combinedStrategyAvailableDepositLimit,
    combinedStrategyAvailableWithdrawLimit,
  } = useMemo(() => {
    const combinedStrategyCurrentPricePerShare: {
      [chainId: string]: {
        [strategyAddress: string]: FixedNumber | null | undefined
      }
    } = {}

    const combinedStrategyTotalAssetValue: {
      [chainId: string]: {
        [strategyAddress: string]: FixedNumber | null | undefined
      }
    } = {}

    const combinedStrategyAvailableDepositLimit: {
      [chainId: string]: {
        [strategyAddress: string]: FixedNumber | null | undefined
      }
    } = {}

    const combinedStrategyAvailableWithdrawLimit: {
      [chainId: string]: {
        [strategyAddress: string]: FixedNumber | null | undefined
      }
    } = {}

    function mergeStrategies<T>(
      target: { [chainId: string]: { [strategyAddress: string]: T } },
      source: { [chainId: string]: { [strategyAddress: string]: T } }
    ) {
      for (const chainId in source) {
        if (!target[chainId]) {
          target[chainId] = {}
        }
        Object.assign(target[chainId], source[chainId]) // Merge strategies within the chainId
      }
    }

    mergeStrategies(
      combinedStrategyCurrentPricePerShare,
      singleStrategyCurrentPricePerShare
    )
    mergeStrategies(
      combinedStrategyCurrentPricePerShare,
      multiStrategyCurrentPricePerShare
    )

    mergeStrategies(
      combinedStrategyTotalAssetValue,
      singleStrategyTotalAssetValue
    )
    mergeStrategies(
      combinedStrategyTotalAssetValue,
      multiStrategyTotalAssetValue
    )

    mergeStrategies(
      combinedStrategyAvailableDepositLimit,
      singleStrategyAvailableDepositLimit
    )
    mergeStrategies(
      combinedStrategyAvailableDepositLimit,
      multiStrategyAvailableDepositLimit
    )

    mergeStrategies(
      combinedStrategyAvailableWithdrawLimit,
      singleStrategyAvailableWithdrawLimit
    )
    mergeStrategies(
      combinedStrategyAvailableWithdrawLimit,
      multiStrategyAvailableWithdrawLimit
    )

    return {
      combinedStrategyCurrentPricePerShare,
      combinedStrategyTotalAssetValue,
      combinedStrategyAvailableDepositLimit,
      combinedStrategyAvailableWithdrawLimit,
    }
  }, [
    singleStrategyCurrentPricePerShare,
    multiStrategyCurrentPricePerShare,
    singleStrategyTotalAssetValue,
    multiStrategyTotalAssetValue,
    singleStrategyAvailableDepositLimit,
    multiStrategyAvailableDepositLimit,
    singleStrategyAvailableWithdrawLimit,
    multiStrategyAvailableWithdrawLimit,
  ])

  const prices = useMemo(() => {
    if (!pricesData) return undefined

    const result: { [chainId: string]: { [token: Address]: FixedNumber } } = {}

    pricesData &&
      Object.entries(pricesData).forEach(([chainId, priceArray]) => {
        result[chainId] =
          priceArray.reduce(
            (acc, price) => {
              acc[price.token] = FixedNumber.fromValue(
                price.price ?? BigNumber.from(0),
                price.decimals,
                `fixed128x${price.decimals}`
              )
              return acc
            },
            {} as { [token: Address]: FixedNumber }
          ) ?? {}
      })

    return result
  }, [pricesData])

  const { data: sentioData } = useSentioData()

  const mappedSentioResults = useMemo(() => {
    if (!sentioData) return {}
    return mapSentioAPIResults(sentioData, assetDecimalsPerStrategy)
  }, [sentioData, assetDecimalsPerStrategy])

  // fetch vault mappings from external source
  const vaultsMapping = useVaultsMappings()

  // fetch connected user balances for asset currencies + receipt currencies

  const receiptTokenCurrenciesInput = useMemo(() => {
    if (!vaultStrategies) return {}

    return Object.entries(vaultStrategies).reduce(
      (acc, [chainId, strategies]) => {
        acc[chainId] = strategies.map((strategy) => ({
          address: strategy.id, // getting vault receipt token information
          version: '',
          isRepoToken: false,
        }))
        return acc
      },
      {} as { [chainId: string]: TokenInfo[] }
    )
  }, [vaultStrategies])

  const receiptTokenCurrenciesByChain = useCurrencies(
    receiptTokenCurrenciesInput,
    provider
  )

  const mappedReceiptTokenCurrencies = useMemo(() => {
    if (!receiptTokenCurrenciesByChain) {
      return undefined
    } else {
      return Object.fromEntries(
        Object.entries(receiptTokenCurrenciesByChain).map(
          ([chainId, currencies]) => [
            chainId,
            currencies.reduce<{ [address: string]: Currency }>(
              (acc, currency) => {
                acc[currency.address] = currency
                return acc
              },
              {} as { [address: string]: Currency }
            ),
          ]
        )
      ) as { [chainId: string]: { [address: string]: Currency } }
    }
  }, [receiptTokenCurrenciesByChain])

  const mappedVaultAssetTokenCurrencies = useMemo(() => {
    if (!vaultStrategies) {
      return undefined
    } else {
      return Object.fromEntries(
        Object.entries(vaultStrategies).map(([chainId, strategies]) => [
          chainId,
          strategies.reduce<{ [vaultAddress: string]: Currency }>(
            (acc, strategy) => {
              acc[strategy.asset.id] = {
                address: strategy.asset.id,
                symbol: strategy.asset.symbol,
                decimals: Number(strategy.asset.decimals),
                isRepoToken: false,
              }
              return acc
            },
            {} as { [vaultAddress: string]: Currency }
          ),
        ])
      ) as { [chainId: string]: { [vaultAddress: string]: Currency } }
    }
  }, [vaultStrategies])

  const receiptTokenBalancesData = useBalances(
    account,
    mappedReceiptTokenCurrencies,
    provider
  )
  const vaultAssetTokenBalancesData = useBalances(
    account,
    mappedVaultAssetTokenCurrencies,
    provider
  )

  const vaultAssetTokenAllowancesInput = useMemo(() => {
    if (!vaultStrategies || !account) return undefined
    const tokenAllowances: { [chainId: string]: TokenAllowance[] | undefined } =
      {}

    for (const [chainId, vaults] of Object.entries(vaultStrategies)) {
      const allowances: TokenAllowance[] = vaults.map((vault) => ({
        token: vault.asset.id,
        owner: account,
        spender: vault.id,
        termId: vault.id,
        decimals: Number(vault.asset.decimals),
      }))
      tokenAllowances[chainId] = allowances.length ? allowances : undefined
    }

    return tokenAllowances
  }, [account, vaultStrategies])

  const vaultAssetTokenAllowancesData = useAllowances(
    vaultAssetTokenAllowancesInput,
    provider
  )

  const connectedAccountAssetTokenAllowances = useMemo(() => {
    if (!vaultAssetTokenAllowancesData || !vaultAssetTokenAllowancesInput) {
      return undefined
    }

    const result: {
      [chainId: string]: { [vaultAddress: Address]: FixedNumber }
    } = {}

    Object.entries(vaultAssetTokenAllowancesData).forEach(
      ([chainId, tokenAllowances]) => {
        // Ensure chainId entry exists
        if (!result[chainId]) {
          result[chainId] = {}
        }

        Object.entries(tokenAllowances).forEach(([vaultAddress, allowance]) => {
          // Ensure allowance is an array before accessing index 0
          if (Array.isArray(allowance) && allowance.length > 0) {
            const decimals =
              vaultAssetTokenAllowancesInput[chainId]?.find(
                (t) => t.termId === vaultAddress
              )?.decimals || 0

            // Assign the computed FixedNumber to the result
            result[chainId][vaultAddress] = bigToFixedNumber(
              allowance[0],
              decimals
            )
          }
        })
      }
    )

    return result
  }, [vaultAssetTokenAllowancesData, vaultAssetTokenAllowancesInput])

  const approveToken = useTokensApprove(signer)

  const mappedReceiptTokenBalances:
    | {
        [chainId: string]: { [address: string]: FixedNumber }
      }
    | undefined = useMemo(() => {
    return receiptTokenBalancesData
      ? Object.fromEntries(
          Object.entries(receiptTokenBalancesData).map(
            ([chainId, balances]) => {
              const currencies = mappedReceiptTokenCurrencies?.[chainId]
              if (!currencies) return [chainId, {}]

              const addressToBalanceMap = balances.reduce<{
                [address: string]: FixedNumber
              }>((acc, balance, index) => {
                const address = Object.keys(currencies)[index]
                acc[address] = balance.balance
                return acc
              }, {})

              return [chainId, addressToBalanceMap]
            }
          )
        )
      : undefined
  }, [receiptTokenBalancesData, mappedReceiptTokenCurrencies])

  const mappedVaultAssetTokenBalances:
    | {
        [chainId: string]: { [address: string]: FixedNumber }
      }
    | undefined = useMemo(() => {
    return vaultAssetTokenBalancesData
      ? Object.fromEntries(
          Object.entries(vaultAssetTokenBalancesData).map(
            ([chainId, balances]) => {
              const currencies = mappedVaultAssetTokenCurrencies?.[chainId]
              if (!currencies) return [chainId, {}]

              const addressToBalanceMap = balances.reduce<{
                [address: string]: FixedNumber
              }>((acc, balance, index) => {
                const address = Object.keys(currencies)[index]
                acc[address] = balance.balance
                return acc
              }, {})

              return [chainId, addressToBalanceMap]
            }
          )
        )
      : undefined
  }, [vaultAssetTokenBalancesData, mappedVaultAssetTokenCurrencies])

  const mappedSharesToConvert = useMemo(() => {
    if (!vaultStrategies || !mappedReceiptTokenBalances) return undefined

    const sharesToConvertByChain: {
      [chainId: string]: {
        strategyAddress: Address
        shares: FixedNumber
        assetCurrency: Currency
      }[]
    } = {}

    for (const [chainId, strategies] of Object.entries(vaultStrategies)) {
      sharesToConvertByChain[chainId] = strategies.map((strategy) => {
        return {
          strategyAddress: strategy.id,
          shares:
            mappedReceiptTokenBalances?.[chainId]?.[strategy.id] ??
            FixedNumber.fromString('0'),
          assetCurrency: {
            address: strategy.asset.id,
            symbol: strategy.asset.symbol,
            decimals: Number(strategy.asset.decimals),
            isRepoToken: false,
          },
        }
      })
    }

    return sharesToConvertByChain
  }, [vaultStrategies, mappedReceiptTokenBalances])

  const { assetBalances: assetBalancesFromShares } = useConvertToAssets(
    mappedSharesToConvert,
    provider
  )

  const {
    vaults,
    metaVaults,
    connectedAccountReceiptTokenBalances,
    connectedAccountVaultAssetTokenBalances,
    totalVaultsDepositedUSD,
    totalMetaVaultsDepositedUSD,
    connectedAccountPositions,
  } = useMemo(() => {
    return composeVaultsPageViewModel(
      vaultStrategies,
      vaultsMapping,
      combinedStrategyCurrentPricePerShare,
      mappedSentioResults,
      combinedStrategyTotalAssetValue,
      combinedStrategyAvailableDepositLimit,
      combinedStrategyAvailableWithdrawLimit,
      prices,
      mappedReceiptTokenBalances,
      mappedVaultAssetTokenBalances,
      mappedReceiptTokenCurrencies,
      assetBalancesFromShares
    )
  }, [
    combinedStrategyCurrentPricePerShare,
    prices,
    combinedStrategyTotalAssetValue,
    combinedStrategyAvailableDepositLimit,
    combinedStrategyAvailableWithdrawLimit,
    vaultStrategies,
    vaultsMapping,
    mappedSentioResults,
    mappedReceiptTokenBalances,
    mappedVaultAssetTokenBalances,
    mappedReceiptTokenCurrencies,
    assetBalancesFromShares,
  ])

  const areTotalAssetValueNull = useCallback(() => {
    return Object.values(combinedStrategyTotalAssetValue).every((assetValue) =>
      Object.values(assetValue).every((value) => value === null)
    )
  }, [combinedStrategyTotalAssetValue])

  return useMemo(
    () =>
      ({
        isLoading:
          queries.length > 0 ? fetching || areTotalAssetValueNull() : true,
        account,
        vaultsData: vaults,
        metaVaultsData: metaVaults,
        currencies: mappedCurrencies,
        assetPrices: prices,
        totalVaultsDepositedUSD,
        totalMetaVaultsDepositedUSD,
        connectedAccountAssetTokenAllowances,
        connectedAccountReceiptTokenBalances,
        connectedAccountVaultAssetTokenBalances,
        connectedAccountPositions,
        onViewVault: (vaultAddress: string, chainId: string) =>
          navigate(`/vaults/${vaultAddress}/${chainId}`),
        onViewMetaVault: (vaultAddress: string, chainId: string) =>
          navigate(`/vaults/meta/${vaultAddress}/${chainId}`),
        onViewPortfolio: () => navigate('/portfolio'),
        onConnect,
        onKytCheck,
        onApprove: async (
          chainId: string,
          token: Address,
          spender: Address,
          amount: FixedNumber
        ) => {
          await onCheckActiveNetwork(
            config.chains[chainId].chainId,
            config.chains[chainId].chainName
          )
          const amountBN = fixedToBigNumber(amount)
          await approveToken(chainId, token, spender, amountBN)
        },
        onDeposit: async (
          isMetaVault: boolean,
          chainId: string,
          strategyAddress: Address,
          amount: FixedNumber
        ) => {
          await onCheckActiveNetwork(
            config.chains[chainId].chainId,
            config.chains[chainId].chainName
          )
          if (isMetaVault) {
            await multiStrategyDeposit(chainId, strategyAddress, amount)
          } else {
            await singleStrategyDeposit(chainId, strategyAddress, amount)
          }
        },
        onWithdraw: async (
          isMetaVault: boolean,
          chainId: string,
          strategyAddress: Address,
          amount: FixedNumber
        ) => {
          await onCheckActiveNetwork(
            config.chains[chainId].chainId,
            config.chains[chainId].chainName
          )
          if (isMetaVault) {
            await multiStrategyWithdraw(chainId, strategyAddress, amount)
          } else {
            await singleStrategyWithdraw(chainId, strategyAddress, amount)
          }
        },
      }) as VaultsPageParams,
    [
      queries.length,
      fetching,
      areTotalAssetValueNull,
      account,
      config.chains,
      approveToken,
      onCheckActiveNetwork,
      vaults,
      prices,
      metaVaults,
      mappedCurrencies,
      totalVaultsDepositedUSD,
      totalMetaVaultsDepositedUSD,
      connectedAccountAssetTokenAllowances,
      connectedAccountReceiptTokenBalances,
      connectedAccountVaultAssetTokenBalances,
      connectedAccountPositions,
      onConnect,
      onKytCheck,
      navigate,
      singleStrategyDeposit,
      singleStrategyWithdraw,
      multiStrategyDeposit,
      multiStrategyWithdraw,
    ]
  )
}
