import { Box, HStack, VStack, Skeleton } from '@chakra-ui/react'
import RepoCardHeading from '../../../Lend/elements/RepoCardHeading'
import { Trans } from '@lingui/macro'
import RepoCardButton from '../../../Lend/elements/RepoCardButton'

export default function MetaVaultCardLoading() {
  return (
    <HStack
      minW={{
        base: '922px',
      }}
      position="relative"
      borderRadius="6px"
      overflow="visible"
      boxShadow={'0px 4px 4px rgba(49, 130, 206, 0.03)'}
      p={3}
      justifyContent="space-between"
      gap={14}
    >
      <HStack spacing={8}>
        <Skeleton h="48px" w="48px" />
        {/* Vault Name */}
        <Box
          width={{
            base: '179px',
            xl: '290px',
          }}
        >
          <Skeleton h="28px" w="80%" mb={2} />
          <Skeleton h="51px" w="100%" />
        </Box>
      </HStack>

      <HStack>
        <HStack spacing={8}>
          {/* Current Yield */}
          <Box
            h="100%"
            borderRight={{
              base: '1px solid',
            }}
            borderRightColor={{
              base: 'gray.3',
            }}
            w="138px"
          >
            <RepoCardHeading title="Current Yield" mb={2} />
            <HStack>
              <Skeleton h="32px" w="80px" />
            </HStack>
          </Box>

          {/* Collateral */}
          <VStack px={0} spacing={0} alignItems="start">
            <RepoCardHeading title="Collateral" mb={2} />
            <Skeleton h="32px" w="80px" />
          </VStack>
        </HStack>

        <HStack>
          {/* Total Asset Value */}
          <VStack
            mr={'52px'}
            py={2.5}
            spacing={0}
            alignItems="end"
            width={{
              base: '152px',
              xl: '283px',
            }}
          >
            <RepoCardHeading title="Total Asset Value" mb={2} />
            <Skeleton h="32px" w="120px" />
          </VStack>
          {/* Button */}
          <VStack
            minW={{
              base: 'max-content',
            }}
            px={{
              base: 0,
            }}
            gap={1}
            alignItems="end"
          >
            <HStack spacing={0.5} justifyContent="right" h="16px">
              <Skeleton h="16px" w="70px" />
            </HStack>
            <RepoCardButton handleClick={() => {}}>
              <Trans>View</Trans>
            </RepoCardButton>
          </VStack>
        </HStack>
      </HStack>
    </HStack>
  )
}
