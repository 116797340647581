import {
  Button,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
  HStack,
  Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { parseUserInput } from '../../../../helpers/utils'
import Chip from '../../../../components/elements/Chip'

export default function VaultInputField({
  label,
  rightLabel,
  type = 'text',
  placeholder,
  leftElement,
  value,
  helperText,
  onChange,
  onMax,
  onMaxRightInput,
  isDisabled,
  w,
  maxLabel = 'MAX',
  isError,

  tokenSymbol,
  tokenDecimals,
  selectVault,
}: {
  label: string | React.ReactNode
  rightLabel?: string | React.ReactNode
  type?: string
  placeholder?: string
  leftElement: string | ReactNode
  value: string
  helperText?: React.ReactNode | undefined
  onChange: (value: string) => void
  onChangeToken?: (symbol: string) => void
  onMax?: () => void
  onMaxRightInput?: () => void
  isTokenSelectionDisabled?: boolean
  isDisabled?: boolean
  w?: FormControlProps['w']
  maxLabel?: string

  isError?: boolean

  tokenSymbol?: string
  tokenDecimals: number
  selectVault?: React.ReactNode | undefined
}) {
  return (
    <FormControl as="fieldset" w={w} flexShrink={0}>
      <VStack spacing={2} align="start">
        <HStack justify="space-between" alignSelf="stretch">
          <FormLabel
            as="legend"
            variant="create-tender"
            mb={0}
            color="gray.700"
            fontSize="sm"
            fontWeight="500"
            lineHeight="5"
            mr={0}
          >
            {label}
          </FormLabel>
          {rightLabel && (
            <Text
              as="span"
              variant="body-xs/normal"
              color={isError ? 'red.5' : 'gray.5'}
            >
              {rightLabel}
            </Text>
          )}
        </HStack>
        <InputGroup w="100%">
          <InputLeftElement
            pointerEvents="none"
            color="gray.500"
            pl="4px"
            w={'fit-content'}
            children={leftElement}
            fontSize="sm"
          />
          <Input
            value={value}
            type={type}
            variant="flushed"
            isDisabled={isDisabled}
            placeholder={placeholder}
            color="blue.9"
            pl={'32px'}
            py={5}
            pr="2px"
            _focus={{
              borderColor: 'blue.5',
            }}
            onChange={(ev) =>
              onChange(parseUserInput(ev.target.value, true, tokenDecimals))
            }
          />
          {selectVault && (
            <InputRightElement w="fit-content">
              <Button variant="link" onClick={onMaxRightInput} px={2}>
                <Text variant="body-xs/semibold" color="blue.5">
                  {maxLabel}
                </Text>
              </Button>
              {selectVault}
            </InputRightElement>
          )}
          {tokenSymbol && (
            <InputRightElement
              width="auto"
              pointerEvents="none"
              color="blue.9"
              fontSize="xs"
              pr="4px"
              transform="translateX(-4px)"
            >
              <Chip>{tokenSymbol}</Chip>
            </InputRightElement>
          )}
        </InputGroup>
        <HStack
          justifyContent={helperText ? 'space-between' : 'flex-end'}
          w="full"
        >
          {helperText && (
            <FormHelperText
              color={isError ? 'red.5' : 'gray.5'}
              fontSize="xs"
              lineHeight="4"
              marginTop="0 !important"
            >
              {helperText}
            </FormHelperText>
          )}
          {onMax && (
            <Button variant="link" onClick={onMax} px={2}>
              <Text variant="body-xs/semibold" color="blue.5">
                {maxLabel}
              </Text>
            </Button>
          )}
        </HStack>
      </VStack>
    </FormControl>
  )
}
