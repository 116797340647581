import { Button, ButtonGroup as CButtonGroup, Text } from '@chakra-ui/react'

export default function ButtonGroup<T extends string>({
  isLeftDisabled,
  isRightDisabled,
  onChange,
  value,
  flexGrow,
  w,
  ...buttons
}: {
  isLeftDisabled?: boolean
  isRightDisabled?: boolean
  value: T
  flexGrow?: string | number
  w?: string
  onChange: (value: T) => void
  left: {
    label: string
    value: T
  }
  right: {
    label: string
    value: T
  }
}) {
  const styles = {
    left: {
      borderRadius: '6px 0px 0px 6px',
      borderLeftWidth: '1px',
    },
    right: {
      borderRadius: '0px 6px 6px 0px',
      borderLeftWidth: '0px',
    },
  }

  return (
    <CButtonGroup
      isAttached
      variant="tender-btn-group"
      flexGrow={flexGrow}
      w={w}
    >
      {['left' as const, 'right' as const].map((btn, i) => (
        <Button
          key={btn}
          isDisabled={i === 0 ? isLeftDisabled : isRightDisabled}
          onClick={() => onChange(buttons[btn].value)}
          isActive={value === buttons[btn].value}
          borderRadius={styles[btn].borderRadius}
          borderLeftWidth={styles[btn].borderLeftWidth}
          minWidth="200px"
          flexGrow={flexGrow}
        >
          <Text as="span" position="relative" zIndex={1}>
            {buttons[btn].label}
          </Text>
        </Button>
      ))}
    </CButtonGroup>
  )
}
