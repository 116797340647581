import { Box, Button, Flex, Heading, HStack, Skeleton } from '@chakra-ui/react'
import { BigNumber, FixedNumber } from 'ethers'
import VaultDepositWithdraw from '../../../Vault/elements/VaultDepositWithdraw'
import MetaVaultStrategyAllocation from '../../../Vault/elements/MetaVaultStrategyAllocation'
import { Trans } from '@lingui/macro'
import VaultCardLoading from '../../../Vaults/elements/VaultCard/VaultCardLoading'
import MetaVaultBanner from '../MetaVaultBanner'
import {
  gasTokenCurrency,
  purchaseCurrency,
} from '../../../../test-helpers/data-portfolio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function MetaVaultPageLoading({
  onViewVaults,
}: {
  onViewVaults: () => void
}) {
  return (
    <Box maxW="1400px" px={{ base: 8, xl: 10 }} mx="auto" pt={4}>
      <HStack mb={4}>
        <Button
          variant="body-xs/medium"
          height="auto"
          p={0}
          rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
          onClick={onViewVaults}
        >
          <Trans>Vaults</Trans>
        </Button>
        <Skeleton h="20px" w="150px" />
      </HStack>
      <MetaVaultBanner
        isDataLoaded={false}
        currentYield={FixedNumber.fromString('0', `fixed128x8`)}
        vaultReceiptBalance={FixedNumber.fromString('0', `fixed128x8`)}
        vaultReceiptCurrency={purchaseCurrency}
        totalDeposited={FixedNumber.fromString('0', `fixed128x8`)}
        pricePerShare={FixedNumber.fromString('0', `fixed128x8`)}
        connectedWalletDeposits={FixedNumber.fromString('0', `fixed128x8`)}
        metaVaultIcon=""
        metaVaultName={''}
        metaVaultAddress={''}
        chainId={'1'}
        vaultAssetCurrency={purchaseCurrency}
      />

      <HStack
        mt={6}
        spacing={6}
        alignItems="start"
        maxW="full"
        overflow="hidden"
      >
        <Box
          borderRadius={'8px'}
          p={6}
          bg="#fff"
          h="330px"
          w="50%"
          flexBasis="50%"
        >
          <MetaVaultStrategyAllocation holdings={{}} isDataLoaded={false} />
        </Box>
        <Box
          borderRadius={'8px'}
          p={6}
          bg="#fff"
          h="330px"
          w="50%"
          flexBasis="50%"
        >
          <VaultDepositWithdraw
            isDataLoaded={false}
            isDepositPaused={false}
            convertToAssetsRatio={FixedNumber.fromString('1')}
            vaultAssetCurrency={purchaseCurrency}
            availableDepositLimit={FixedNumber.fromString('0')}
            availableWithdrawLimit={FixedNumber.fromString('0')}
            vaultAssetBalance={FixedNumber.fromString('0')}
            vaultAssetAllowance={BigNumber.from(0)}
            vaultAssetPrice={FixedNumber.fromString('0')}
            vaultReceiptCurrency={purchaseCurrency}
            vaultReceiptBalance={FixedNumber.fromString('0')}
            gasTokenBalance={FixedNumber.fromString('0')}
            gasTokenCurrency={gasTokenCurrency}
            onConnect={() => {}}
            onWrapGasToken={async () => {}}
            onVaultAssetApprove={async () => {}}
            onKytCheck={async () => true}
            onDeposit={async () => {}}
            onWithdraw={async () => {}}
          />
        </Box>
      </HStack>

      <Heading
        variant="heading/h1"
        pt={6}
        mt={6}
        borderTop="1px solid #0000001A"
      >
        <Trans>Strategies</Trans>
      </Heading>

      <Flex direction="column" gap={5} mt={6}>
        {Array.from({ length: 5 }).map((_, idx) => (
          <VaultCardLoading key={idx} isMetaVaultView />
        ))}
      </Flex>
    </Box>
  )
}
