import { Divider } from '@chakra-ui/layout'
import { VaultSummaryItem } from '../../../../data/model'
import SummaryHeader from '../../../Auction/elements/TenderSummary/SummaryHeader'
import SummaryStat from '../../../Auction/elements/TenderSummary/SummaryStat'
import SummaryDetails from './SummaryDetails'

export default function LoanVaultSummary({
  isDataLoading,
  vaultSummary,
}: {
  isDataLoading?: boolean
  vaultSummary: VaultSummaryItem[]
}) {
  return (
    <>
      <Divider borderColor="gray.2" mt="8px" mb="8px" />
      {vaultSummary.map((item, index) => (
        <SummaryStat key={index}>
          <SummaryHeader
            text={item.text[0]}
            rightElement={item.text[1]}
            warning={item.warning}
          />
          <SummaryDetails
            isDataLoading={isDataLoading}
            startText={item.start}
            endText={item.end}
            warning={item.warning}
          />
        </SummaryStat>
      ))}
    </>
  )
}
