import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { Box, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { HistoricalPerformanceDataChart } from '../../../../models/metaVault'

export const LineChartVault = ({
  dataChart,
  metaVaultAssetCurrencySymbol,
}: {
  dataChart: HistoricalPerformanceDataChart[]
  metaVaultAssetCurrencySymbol: string
}) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={160}
      style={{ marginLeft: '-50px', marginTop: '-20px' }}
    >
      <LineChart data={dataChart}>
        <XAxis
          dataKey="timestamp"
          axisLine={{ stroke: '#0000001A' }}
          tickLine={false}
          tick={false}
        />
        <YAxis
          domain={[
            Math.min(...dataChart.map((d) => parseFloat(d.value.toString()))),
            Math.max(...dataChart.map((d) => parseFloat(d.value.toString()))),
          ]}
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const { timestamp, value } =
                payload[0] as unknown as HistoricalPerformanceDataChart
              return (
                <Box
                  bg="#1A365DE5"
                  color="white"
                  p={2}
                  borderRadius="4px"
                  mb="8px"
                >
                  <Text>{dayjs(timestamp).format('DD/MM/YYYY')}</Text>
                  <Text>
                    Price Per Share: {value.toString()}{' '}
                    {metaVaultAssetCurrencySymbol}
                  </Text>
                </Box>
              )
            }
            return null
          }}
        />
        <Line
          type="linear"
          dataKey="value"
          stroke="#9375FF"
          strokeWidth={3}
          dot={false}
          activeDot={{
            r: 6,
            fill: '#9375FF',
            stroke: 'white',
            strokeWidth: 2,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
