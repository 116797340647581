import { useMemo } from 'react'
import { Address, ExternalProject } from '../model'
import { ChainId } from '@usedapp/core'

type ExternalProjects = Record<
  Address,
  {
    label: string
    project: ExternalProject
    pointsMultiplier: number
  }
>

export const useExternalProjectPoints = (): Partial<
  Record<ChainId, ExternalProjects>
> => {
  // TODO Fetch from external source

  return useMemo(
    () => ({
      [ChainId.Mainnet]: {
        '0xbf5495efe5db9ce00f80364c8b423567e58d2110': {
          label: 'Renzo',
          project: 'renzo',
          pointsMultiplier: 1,
        },
        '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee': {
          label: 'EtherFi',
          project: 'etherfi',
          pointsMultiplier: 1,
        },
        '0x9d39a5de30e57443bff2a8307a4256c8797a3497': {
          label: 'Ethena',
          project: 'ethena',
          pointsMultiplier: 1,
        },
        '0x15700b564ca08d9439c58ca5053166e8317aa138': {
          label: 'Elixir',
          project: 'elixir',
          pointsMultiplier: 1.7,
        },
        '0xd11c452fc99cf405034ee446803b6f6c1f6d5ed8': {
          label: 'Treehouse',
          project: 'treehouse',
          pointsMultiplier: 1,
        },
        '0x8236a87084f8b84306f72007f36f2618a5634494': {
          label: 'Lombard',
          project: 'lombard',
          pointsMultiplier: 2,
        },
      },
      [ChainId.Sepolia]: {
        '0xf243b1edff0d00b0c3eb170511c3cae4de38cd55': {
          label: 'EtherFi',
          project: 'etherfi',
          pointsMultiplier: 1,
        },
      },
    }),
    []
  )
}
