import { Box, HStack, Text } from '@chakra-ui/react'

export default function VaultBanner() {
  return (
    <Box as="section" bg="blue.5" py={2} overflow="hidden">
      <HStack columnGap={1} w="max-content" margin="0 auto">
        <Text as="span" variant="body-md/medium" color="white">
          Vault curator view
        </Text>
      </HStack>
    </Box>
  )
}
