import { gql } from 'urql'

export const PageMetaVaultDocument = gql`
  query PageMetaVault($id: ID!) {
    termMultiStrat(id: $id) {
      id
      strategies {
        id
        asset {
          id
          name
          symbol
          decimals
        }
        collateralTokens {
          id
          name
          symbol
          decimals
        }
      }
      asset {
        id
        name
        symbol
        decimals
      }
      manager
      accountant
      dao
      delay
      ownerless
      proposer
      roles
    }
  }
`
