import { gql } from 'urql'

export const PageVaultsDocument = gql`
  query PageVaults {
    termVaultStrategies {
      id
      asset {
        id
        name
        symbol
        decimals
      }
      collateralTokens {
        id
        name
        symbol
        decimals
      }
    }
    termMultiStrats {
      id
      strategies {
        id
        asset {
          id
          name
          symbol
          decimals
        }
        collateralTokens {
          id
          name
          symbol
          decimals
        }
      }
      asset {
        id
        name
        symbol
        decimals
      }
      manager
      accountant
      dao
      delay
      ownerless
      proposer
      roles
    }
  }
`
