// MetaVault is 100% deployed

import { FixedNumber } from 'ethers'
import {
  MetaVaultStrategyAllocations,
  MetaVaultStrategyHoldings,
} from '../../data/model'
import { add, divide, multiply } from '../../helpers/math'
import { SingleStrategyExternalMapping } from '../../models/vaults'

// Calculate the weighted strategy allocation for the MetaVault
export const calculateMetaVaultStrategyAllocation = (
  strategyHoldings: MetaVaultStrategyHoldings,
  vaultsMapping?: SingleStrategyExternalMapping
): MetaVaultStrategyAllocations => {
  const totalHoldings = Object.values(strategyHoldings).reduce(
    (acc, holding) => add(acc, holding, holding.format.decimals),
    FixedNumber.fromString('0', `fixed128x18`)
  )

  return Object.fromEntries(
    Object.entries(strategyHoldings).map(([strategyAddress, holding]) => {
      const vaultInfo = vaultsMapping?.[strategyAddress] ?? {
        vaultName: 'Unknown',
      }

      const ratio = !totalHoldings.isZero()
        ? multiply(
            divide(holding, totalHoldings, holding.format.decimals),
            FixedNumber.fromString('100', holding.format.decimals)
          )
        : FixedNumber.fromString('0', holding.format.decimals)

      return [
        strategyAddress,
        {
          vaultName: vaultInfo.vaultName,
          ratio: ratio.toUnsafeFloat(),
          balance: holding,
        },
      ]
    })
  )
}
