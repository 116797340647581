import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { BigNumber, FixedNumber, Signer } from 'ethers'
import { Address, Currency, MetaVaultStrategyAllocations } from '../data/model'
import { MetaVaultPageParams } from '../models/metaVault'
import { useChainConfig, useConfig } from '../providers/config'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import { useGraphQuery, useSentioData } from '../data/hooks/helper-hooks'
import {
  PageMetaVaultQuery,
  PageMetaVaultQueryVariables,
} from '../gql/vaults/graphql'
import { useGlobalRefresher } from '../providers/refresher'
import { calculateCurrentYield, mapSentioAPIResults } from '../helpers/utils'
import { useMetaVault } from '../data/hooks/use-meta-vault'
import { useVaults } from '../data/hooks/use-vaults'
import { useVaultsMappings } from '../data/hooks/use-vaults-mappings'
import { mapVaultsAndMetaVaults } from '../pages/Vaults/utils'
import {
  MetaVaultData,
  VaultData,
  VaultsMapping,
  VaultStrategy,
} from '../models/vaults'
import { useTokenWrapper } from '../data/hooks/use-token-wrapper'
import { useNativeTokenBalance } from '../data/hooks/use-native-token-balance'
import { useBalances } from '../data/hooks/use-balances'
import { useCurrencies } from '../data/hooks/use-currencies'
import { usePrices } from '../data/hooks/use-prices'
import { bigToFixedNumber } from '../helpers/conversions'
import { useConvertToAssets } from '../data/hooks/use-convert-to-assets'
import { calculateMetaVaultStrategyAllocation } from '../pages/MetaVault/utils'
import { useTokenApprove } from '../data/hooks/use-token-approve'
import { useAllowances } from '../data/hooks/use-allowances'

export const composeMetaVaultPageViewModel = (
  chainId: string,
  metaVaultAddress: Address,
  vaultsMapping: VaultsMapping | undefined,
  rawVaults: VaultStrategy | undefined,
  sentioAPIResults: {
    [chainId: string]: {
      [strategyAddress: Address]: {
        price: FixedNumber | null | undefined
        timestamp: number
      }
    }
  },
  metaVaultConvertToAssetRatio: FixedNumber | null | undefined,
  singleStrategyCurrentPricePerShare: {
    [chainId: string]: {
      [strategyAddress: string]: FixedNumber | null | undefined
    }
  },
  singleStrategyTotalAssetValue: {
    [chainId: string]: {
      [strategyAddress: string]: FixedNumber | null | undefined
    }
  },
  multiStrategyCurrentPricePerShare: FixedNumber | null | undefined,
  multiStrategyTotalAssetValue: FixedNumber | null | undefined,
  metaVaultSingleStrategiesAssetBalances:
    | {
        [strategyAddress: string]:
          | {
              balance: FixedNumber
              asset: Address
            }
          | null
          | undefined
      }
    | undefined,
  connectedWalletConvertedAssetBalance:
    | {
        [strategyAddress: string]:
          | {
              balance: FixedNumber
              asset: Address
            }
          | null
          | undefined
      }
    | undefined,
  multiStrategyIsShutdown: boolean | null | undefined,
  multiStrategyAvailableDepositLimit: FixedNumber | null | undefined,
  multiStrategyAvailableWithdrawLimit: FixedNumber | null | undefined,
  deposit:
    | ((chainId: string, amount: FixedNumber) => Promise<void>)
    | null
    | undefined,
  withdrawal:
    | ((
        chainId: string,
        amount: FixedNumber,
        maxLoss?: FixedNumber
      ) => Promise<void>)
    | null
    | undefined
): {
  metaVault: MetaVaultData
  singleVaults: VaultData[]
  totalDeposited: FixedNumber | undefined
  userDepositedAssetCurrency: FixedNumber | undefined
  multiStrategyCurrentYield: FixedNumber | undefined
  metaVaultStrategyAllocations: MetaVaultStrategyAllocations
  metaVaultDeposit?: (chainId: string, amount: FixedNumber) => Promise<void>
  metaVaultWithdraw?: (
    chainId: string,
    amount: FixedNumber,
    maxLoss?: FixedNumber
  ) => Promise<void>
} => {
  const singleStrategiesCurrentYield = calculateCurrentYield(
    singleStrategyCurrentPricePerShare,
    sentioAPIResults
  )

  const multiStrategyCurrentYield = calculateCurrentYield(
    { [chainId]: { [metaVaultAddress]: multiStrategyCurrentPricePerShare } },
    sentioAPIResults
  )

  const transformedStrategiesBalances = Object.entries(
    metaVaultSingleStrategiesAssetBalances ?? {}
  ).reduce(
    (acc, [strategyAddress, value]) => {
      if (value && value.balance) {
        acc[strategyAddress] = value.balance
      }
      return acc
    },
    {} as { [strategyAddress: Address]: FixedNumber }
  )

  const metaVaultStrategyAllocations = calculateMetaVaultStrategyAllocation(
    transformedStrategiesBalances,
    vaultsMapping?.vaultDetails?.[chainId]
  )

  const { vaults, metaVaults } = mapVaultsAndMetaVaults(
    new Set([Number(chainId)]),
    rawVaults,
    vaultsMapping,
    {}, // TODO: add receipt token currencies
    singleStrategiesCurrentYield,
    singleStrategyTotalAssetValue,
    {},
    {},
    { [chainId]: metaVaultStrategyAllocations }
  )

  return {
    metaVault: metaVaults[0],
    singleVaults: vaults,
    totalDeposited: multiStrategyTotalAssetValue ?? undefined,
    userDepositedAssetCurrency:
      connectedWalletConvertedAssetBalance?.[metaVaultAddress]?.balance ??
      FixedNumber.fromString('0', `fixed128x18`),
    multiStrategyCurrentYield:
      multiStrategyCurrentYield[chainId][metaVaultAddress] ?? undefined,
    metaVaultStrategyAllocations,
    metaVaultDeposit: deposit ?? undefined,
    metaVaultWithdraw: withdrawal ?? undefined,
  }
}

export function useMetaVaultPage(
  account: Address | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  onCheckActiveNetwork: (
    chainId?: number,
    chainName?: string
  ) => Promise<boolean>,
  onConnect: () => void,
  onKytCheck: () => Promise<boolean>
) {
  const config = useConfig()
  const { metaVaultAddress, chainId } = useParams()
  const navigate = useNavigate()

  if (metaVaultAddress === undefined) {
    throw new Error(
      'Vault component may only be used within a route with id param'
    )
  }

  if (chainId === undefined) {
    throw new Error(
      'Vault component may only be used within a route with chain param'
    )
  }

  const parsedChainId = Number(chainId)
  if (isNaN(parsedChainId)) {
    throw new Error(`Invalid value provided for ${parsedChainId}`)
  }

  const chainData = useChainConfig(parsedChainId)

  if (!chainData) {
    throw new Error(`Invalid chain id ${parsedChainId} - no chain config found`)
  }

  const [vaultsSubgraphQuery, setVaultsSubgraphQuery] = useState<any>({})
  useEffect(() => {
    const subgraphVersion = chainData.getSubgraphVersion()
    const queryDoc = getQueryDocument(
      subgraphVersion,
      DocumentType.PAGE_META_VAULT
    )
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.PAGE_META_VAULT,
      variables: {
        id: metaVaultAddress,
      },
    })
    const query = {
      chainId: parsedChainId,
      url: chainData.vaultsSubgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setVaultsSubgraphQuery(query)
  }, [account, metaVaultAddress, chainData, parsedChainId])

  const {
    results: vaultSubgraphData,
    fetching,
    error,
    refresh: readFromVaultsSubgraph,
  } = useGraphQuery<PageMetaVaultQuery, PageMetaVaultQueryVariables>(
    vaultsSubgraphQuery
  )

  const { slow: autoRefresher } = useGlobalRefresher()
  useEffect(() => {
    readFromVaultsSubgraph()
  }, [readFromVaultsSubgraph, autoRefresher])

  const vaultsMapping = useVaultsMappings()

  const {
    // proposerAddress,
    // governorAddress,
    // delayModifierAddress,
    metaVaultAssetCurrency,
    assetDecimalsByChain,
    singleStrategyAddressesByChain,
    rawVaults,
  } = useMemo(() => {
    if (!vaultSubgraphData) {
      return {
        proposerAddress: undefined,
        governorAddress: undefined,
        delayModifierAddress: undefined,
        singleStrategyAddresses: [],
        metaVaultAssetCurrency: undefined,
        assetDecimalsByChain: undefined,
        singleStrategyAddressesByChain: undefined,
        rawVaults: { [chainId]: [] } as VaultStrategy,
        metaVaultInfo: undefined,
      }
    } else {
      const rawVaults = {
        [chainId]:
          vaultSubgraphData?.termMultiStrat?.strategies?.map((strat) => {
            return {
              isMetaVault: false,
              id: strat.id.toLowerCase(),
              assocMetaVaultAddress: metaVaultAddress,
              collateralTokens: strat.collateralTokens.map((token) => {
                return {
                  id: token.id,
                  name: token.name,
                  symbol: token.symbol,
                  decimals: token.decimals,
                }
              }),
              asset: {
                id: strat.asset.id,
                name: strat.asset.name,
                symbol: strat.asset.symbol,
                decimals: strat.asset.decimals,
              },
            }
          }) ?? [],
      } as VaultStrategy

      rawVaults?.[chainId]?.push({
        isMetaVault: true,
        id: vaultSubgraphData?.termMultiStrat?.id.toLowerCase() ?? '',
        collateralTokens: [],
        asset: {
          id: vaultSubgraphData?.termMultiStrat?.asset?.id ?? '',
          name: vaultSubgraphData?.termMultiStrat?.asset?.name ?? '',
          symbol: vaultSubgraphData?.termMultiStrat?.asset?.symbol ?? '',
          decimals: vaultSubgraphData?.termMultiStrat?.asset?.decimals ?? 18,
        },
      })

      return {
        proposerAddress:
          vaultSubgraphData?.termMultiStrat?.proposer?.toLowerCase(),
        // TODO confirm if governor is needed
        governorAddress: undefined,
        delayModifierAddress: vaultSubgraphData?.termMultiStrat?.delay ?? '',
        metaVaultAssetCurrency: {
          address: vaultSubgraphData?.termMultiStrat?.asset?.id ?? '',
          symbol: vaultSubgraphData?.termMultiStrat?.asset?.symbol ?? '',
          decimals:
            Number(vaultSubgraphData?.termMultiStrat?.asset?.decimals) ?? 18,
          isRepoToken: false,
        } as Currency,
        assetDecimalsByChain: {
          [chainId]: vaultSubgraphData?.termMultiStrat?.strategies?.map(
            (strategy) => Number(strategy.asset.decimals) ?? 18
          ),
        },
        singleStrategyAddressesByChain: {
          [chainId]:
            vaultSubgraphData?.termMultiStrat?.strategies?.map((strategy) =>
              strategy.id.toLowerCase()
            ) ?? [],
        },
        rawVaults,
      }
    }
  }, [chainId, metaVaultAddress, vaultSubgraphData])

  const { data: sentioData } = useSentioData()

  const mappedAssetDecimalsPerStrategy = useMemo(
    () => ({
      [chainId]: Object.fromEntries(
        rawVaults[chainId].map((vault) => {
          return [vault.id, Number(vault.asset.decimals)]
        })
      ),
    }),
    [rawVaults, chainId]
  )

  const mappedSentioResults = useMemo(() => {
    if (!sentioData) return {}
    return mapSentioAPIResults(sentioData, mappedAssetDecimalsPerStrategy)
  }, [sentioData, mappedAssetDecimalsPerStrategy])

  const {
    currentPricePerShare: multiStrategyCurrentPricePerShare,
    totalAssetValue: multiStrategyTotalAssetValue,
    isShutdown: multiStrategyIsShutdown,
    availableDepositLimit: multiStrategyAvailableDepositLimit,
    availableWithdrawLimit: multiStrategyAvailableWithdrawLimit,
    convertToAssetsRatio,
    deposit,
    withdraw,
  } = useMetaVault(
    account,
    metaVaultAddress,
    chainId,
    signer,
    metaVaultAssetCurrency?.decimals,
    provider,
    readFromVaultsSubgraph
  ) || {}

  const {
    currentPricePerShare: singleStrategyCurrentPricePerShare,
    totalAssetValue: singleStrategyTotalAssetValue,
  } = useVaults(
    account,
    singleStrategyAddressesByChain,
    assetDecimalsByChain,
    signer,
    provider,
    () => {}
  )

  // get metavault balance in all strategies
  // this is the receipt token
  // use convertToAssets to get the asset token
  // get prices for asset token
  // convert from asset token to USD

  const mappedSingleStrategiesCurrencyInput = useMemo(() => {
    if (!singleStrategyAddressesByChain) return undefined
    return {
      [chainId]: (singleStrategyAddressesByChain[chainId] ?? []).map(
        (strategyAddress) => {
          return {
            address: strategyAddress,
            version: 'x',
            isRepoToken: false,
          }
        }
      ),
    }
  }, [singleStrategyAddressesByChain, chainId])

  const singleStrategyReceiptCurrenciesByChain = useCurrencies(
    mappedSingleStrategiesCurrencyInput,
    provider
  )

  const mappedSingleStrategiesReceiptCurrenciesByAddress = useMemo(() => {
    if (
      !singleStrategyReceiptCurrenciesByChain ||
      !mappedSingleStrategiesCurrencyInput
    )
      return undefined

    const singleStrategyCurrencies =
      singleStrategyReceiptCurrenciesByChain[chainId]
    const singleStrategyAddresses = mappedSingleStrategiesCurrencyInput[chainId]

    return {
      [chainId]: Object.fromEntries(
        singleStrategyAddresses.map((address, index) => {
          const currency = singleStrategyCurrencies[index]
          return [address.address, currency]
        })
      ),
    }
  }, [
    singleStrategyReceiptCurrenciesByChain,
    mappedSingleStrategiesCurrencyInput,
    chainId,
  ])

  const singleStrategyReceiptBalancesByChain = useBalances(
    metaVaultAddress,
    mappedSingleStrategiesReceiptCurrenciesByAddress,
    provider
  )

  const metaVaultSingleStrategiesReceiptBalances = useMemo(() => {
    if (!singleStrategyReceiptBalancesByChain) return undefined

    const singleStrategyBalances = singleStrategyReceiptBalancesByChain[chainId]

    return Object.fromEntries(
      singleStrategyBalances.map((balance) => {
        return [balance.address, balance.balance]
      })
    ) as { [singleStrategyAddress: string]: FixedNumber }
  }, [singleStrategyReceiptBalancesByChain, chainId])

  const singleStrategyBalancesToConvertInput = useMemo(() => {
    if (!metaVaultSingleStrategiesReceiptBalances || !metaVaultAssetCurrency)
      return undefined

    return {
      [chainId]: Object.entries(metaVaultSingleStrategiesReceiptBalances).map(
        ([address, balance]) => {
          return {
            strategyAddress: address,
            shares: balance,
            assetCurrency: metaVaultAssetCurrency,
          }
        }
      ),
    }
  }, [
    metaVaultSingleStrategiesReceiptBalances,
    metaVaultAssetCurrency,
    chainId,
  ])

  const { assetBalances: metaVaultSingleStrategiesAssetBalances } =
    useConvertToAssets(singleStrategyBalancesToConvertInput, provider)

  // get current user meta vault balance

  const mappedMultiStrategyCurrencyInput = useMemo(() => {
    if (!metaVaultAddress) return undefined
    return {
      [chainId]: [
        {
          address: metaVaultAddress,
          version: 'x',
          isRepoToken: false,
        },
      ],
    }
  }, [metaVaultAddress, chainId])

  const multiStrategyReceiptCurrencyByChain = useCurrencies(
    mappedMultiStrategyCurrencyInput,
    provider
  )

  const {
    mappedMultiStrategyReceiptCurrencyByAddress,
    mappedMultiStrategyAssetCurrencyByAddress,
  } = useMemo(() => {
    if (
      !multiStrategyReceiptCurrencyByChain ||
      !mappedMultiStrategyCurrencyInput ||
      !metaVaultAssetCurrency
    )
      return {}

    return {
      mappedMultiStrategyReceiptCurrencyByAddress: {
        [chainId]: {
          [metaVaultAddress]: multiStrategyReceiptCurrencyByChain[chainId][0],
        },
      } as { [chainId: string]: { [metaVaultAddress: Address]: Currency } },
      mappedMultiStrategyAssetCurrencyByAddress: {
        [chainId]: {
          [metaVaultAssetCurrency.address]: metaVaultAssetCurrency,
        },
      } as { [chainId: string]: { [metaVaultAddress: Address]: Currency } },
    }
  }, [
    multiStrategyReceiptCurrencyByChain,
    mappedMultiStrategyCurrencyInput,
    metaVaultAssetCurrency,
    chainId,
    metaVaultAddress,
  ])

  const connectedWalletReceiptBalanceByChain = useBalances(
    account,
    mappedMultiStrategyReceiptCurrencyByAddress,
    provider
  )

  const connectedWalletAssetBalanceByChain = useBalances(
    account,
    mappedMultiStrategyAssetCurrencyByAddress,
    provider
  )

  const multiStrategyBalanceToConvertInput = useMemo(() => {
    if (
      !connectedWalletReceiptBalanceByChain ||
      !metaVaultAddress ||
      !metaVaultAssetCurrency
    )
      return undefined

    return {
      [chainId]: [
        {
          strategyAddress: metaVaultAddress,
          shares: connectedWalletReceiptBalanceByChain?.[chainId]?.[0]?.balance,
          assetCurrency: metaVaultAssetCurrency,
        },
      ],
    }
  }, [
    connectedWalletReceiptBalanceByChain,
    metaVaultAddress,
    metaVaultAssetCurrency,
    chainId,
  ])

  const { assetBalances: connectedWalletConvertedAssetBalanceByChain } =
    useConvertToAssets(multiStrategyBalanceToConvertInput, provider)

  const pricesInput = useMemo(() => {
    if (!metaVaultAssetCurrency) return undefined

    return {
      [chainId]: [
        {
          address: metaVaultAssetCurrency.address,
          decimals: metaVaultAssetCurrency.decimals,
        },
      ],
    }
  }, [metaVaultAssetCurrency, chainId])

  const pricesData = usePrices(pricesInput, undefined, provider)

  const metaVaultAssetPriceFN = useMemo(() => {
    if (!pricesData || !pricesData[chainId]?.[0]) {
      return undefined
    } else {
      return bigToFixedNumber(
        pricesData[chainId][0].price,
        pricesData[chainId][0].decimals
      )
    }
  }, [pricesData, chainId])

  const gasTokenCurrency = useMemo(
    () => chainData.nativeCurrency,
    [chainData.nativeCurrency]
  )

  const gasTokenBalance = useNativeTokenBalance(
    account,
    gasTokenCurrency,
    parsedChainId
  )

  const { wrapGasToken: wrapGasTokenCall } = useTokenWrapper(
    chainData.chainId,
    chainData.contracts.wrappedGasToken,
    provider
  )

  // approvals:
  const metaVaultAssetApprove = useTokenApprove(
    signer,
    metaVaultAssetCurrency,
    parsedChainId
  )

  const metaVaultAssetTokenAllowance = useMemo(() => {
    if (!account || !metaVaultAddress || !metaVaultAssetCurrency) {
      return undefined
    }
    const allowances = [
      {
        token: metaVaultAssetCurrency.address,
        spender: metaVaultAddress,
        owner: account,
        termId: 'x',
      },
    ]

    return {
      [chainId]: allowances,
    }
  }, [account, metaVaultAddress, metaVaultAssetCurrency, chainId])

  const allowances = useAllowances(metaVaultAssetTokenAllowance, provider)
  const metaVaultAssetAllowance = useMemo(() => {
    return allowances?.[chainId]?.['x']?.[0] ?? BigNumber.from(0)
  }, [allowances, chainId])

  const {
    metaVault,
    singleVaults,
    totalDeposited,
    userDepositedAssetCurrency,
    multiStrategyCurrentYield,
    metaVaultStrategyAllocations,
    metaVaultDeposit,
    metaVaultWithdraw,
  } = composeMetaVaultPageViewModel(
    chainId,
    metaVaultAddress,
    vaultsMapping,
    rawVaults,
    mappedSentioResults,
    FixedNumber.fromString('1.05', `fixed128x18`), // TODO: wire this to contract call
    singleStrategyCurrentPricePerShare,
    singleStrategyTotalAssetValue,
    multiStrategyCurrentPricePerShare,
    multiStrategyTotalAssetValue,
    metaVaultSingleStrategiesAssetBalances?.[chainId],
    connectedWalletConvertedAssetBalanceByChain?.[chainId],
    multiStrategyIsShutdown,
    multiStrategyAvailableDepositLimit,
    multiStrategyAvailableWithdrawLimit,
    deposit,
    withdraw
  )

  return useMemo(
    () =>
      ({
        isDataLoaded: true, // TODO: wire this up properly
        account,
        chainId,

        metaVaultDetails: metaVault,
        individualVaultDetails: singleVaults,
        metaVaultStrategyAllocations,

        totalDeposited,
        connectedWalletDeposits: userDepositedAssetCurrency,
        singleVaultPricePerShareMap: singleStrategyCurrentPricePerShare,
        metaVaultPricePerShare: multiStrategyCurrentPricePerShare,
        metaVaultConvertToAssetsRatio: convertToAssetsRatio,
        metaVaultCurrentYield: multiStrategyCurrentYield,

        connectedWalletAssetAllowance: metaVaultAssetAllowance,
        metaVaultAssetPrice: metaVaultAssetPriceFN,
        metaVaultReceiptCurrency:
          multiStrategyReceiptCurrencyByChain?.[chainId]?.[0],
        connectedWalletMetaVaultReceiptBalance:
          connectedWalletReceiptBalanceByChain?.[chainId]?.[0]?.balance ??
          FixedNumber.fromString('0', `fixed128x18`),
        connectedWalletAssetBalance:
          connectedWalletAssetBalanceByChain?.[chainId]?.[0]?.balance ??
          FixedNumber.fromString('0', `fixed128x18`),
        gasTokenBalance:
          gasTokenBalance?.balance ?? FixedNumber.fromString('0', 18),
        gasTokenCurrency,
        availableDepositLimit: multiStrategyAvailableDepositLimit,
        availableWithdrawLimit: multiStrategyAvailableWithdrawLimit,

        isShutdown: multiStrategyIsShutdown,

        hasHistoricalPerformance: false,
        historicalPerformanceDataChart: [],
        setSelectedTime: () => {},
        selectedTime: 0,

        onConnect,
        onKytCheck,
        onApproveMetaVaultAsset: async (amount?: string) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await metaVaultAssetApprove(
            metaVaultAddress,
            amount || config.approveAmount
          )
        },
        onMetaVaultDeposit: async (amount: string) => {
          if (!account) {
            throw new Error('Account is undefined')
          }
          if (!metaVaultDeposit) {
            throw new Error('Deposit function is undefined')
          }
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await metaVaultDeposit(
            chainId,
            FixedNumber.fromString(
              amount,
              `fixed128x${metaVaultAssetCurrency?.decimals}`
            )
          )
        },
        onMetaVaultWithdraw: async (amount: string, maxLoss?: number) => {
          if (!account) {
            throw new Error('Account is undefined')
          }
          if (!metaVaultWithdraw) {
            throw new Error('Deposit function is undefined')
          }
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await metaVaultWithdraw(
            chainId,
            FixedNumber.fromString(
              amount,
              `fixed128x${metaVaultAssetCurrency?.decimals}`
            ),
            maxLoss ? FixedNumber.fromString(maxLoss.toString(), 18) : undefined
          )
        },
        onWrapGasToken: wrapGasTokenCall,

        onViewSingleVault: (vaultAddress: string, chainId: string) =>
          navigate(`/vaults/${vaultAddress}/${chainId}`, {
            state: { metaVault: metaVault },
          }),
        onViewVaults: () => navigate(`/vaults`),
      }) as MetaVaultPageParams,
    [
      account,
      chainId,
      config.approveAmount,
      chainData,
      metaVault,
      metaVaultAddress,
      singleVaults,
      metaVaultStrategyAllocations,
      totalDeposited,
      userDepositedAssetCurrency,
      metaVaultAssetAllowance,
      gasTokenCurrency,
      gasTokenBalance,
      metaVaultAssetPriceFN,
      metaVaultAssetCurrency,
      convertToAssetsRatio,
      metaVaultAssetApprove,
      multiStrategyCurrentYield,
      singleStrategyCurrentPricePerShare,
      multiStrategyCurrentPricePerShare,
      multiStrategyIsShutdown,
      multiStrategyReceiptCurrencyByChain,
      multiStrategyAvailableDepositLimit,
      multiStrategyAvailableWithdrawLimit,
      connectedWalletReceiptBalanceByChain,
      connectedWalletAssetBalanceByChain,
      metaVaultDeposit,
      metaVaultWithdraw,
      wrapGasTokenCall,
      onCheckActiveNetwork,
      onConnect,
      onKytCheck,
      navigate,
    ]
  )
}
