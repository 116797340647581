import { Skeleton, Text } from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import { formatNumber } from '../../../Rewards/utils'
import Tooltip from '../../../../components/elements/Tooltip'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'

export default function BannerStat({
  isDataLoaded,
  title,
  value,
  symbol,
  maxW,
  isPercentage,
  overrideSymbol, // This is temporary until mapping for receipt token <-> purchase token is done
  overrideValue,
}: {
  isDataLoaded: boolean
  title: string
  value?: FixedNumber
  symbol: string
  maxW?: string
  isPercentage?: boolean
  overrideSymbol?: string
  overrideValue?: string
}) {
  let shortenedValue

  if (value) {
    shortenedValue = formatNumber(value.toUnsafeFloat(), true, 2, false, false)
  }

  // const formattedValue = formatFixedToken(value, overrideSymbol ?? symbol, true, true, undefined, true)

  return (
    <VStack
      spacing={'1px'}
      align="start"
      mt="auto"
      w={{ base: 'full', xl: '150px' }}
      maxWidth={maxW ?? '150px'}
    >
      <Text fontSize="12px" lineHeight="20px" style={{ textWrap: 'nowrap' }}>
        {title}
      </Text>
      <Skeleton isLoaded={isDataLoaded} h="32px" w="101px">
        {!value && overrideValue && (
          <Text
            fontSize="24px"
            fontWeight="400"
            lineHeight="32px"
            style={{ textWrap: 'nowrap' }}
          >
            {overrideValue}
          </Text>
        )}
        {value && (
          <Tooltip label={value.toString()}>
            <Text
              fontWeight="400"
              lineHeight="32px"
              style={{ textWrap: 'nowrap' }}
            >
              <Text as="span" fontSize="24px">
                {isPercentage
                  ? fixedToFormattedPercentage(value, 2, false, false)
                      .formattedPercentage
                  : shortenedValue}
              </Text>{' '}
              {!isPercentage && (
                <Text as="span" fontSize="14px">
                  {symbol}
                </Text>
              )}
            </Text>
          </Tooltip>
        )}
      </Skeleton>
    </VStack>
  )
}
