import { graphql } from '../gql'

export const VaultOpenOffersDocument = graphql(`
  query VaultOpenOffers($currentTime: BigInt!, $wallet: Bytes!) {
    termOffers(
      where: {
        auction_: {
          delisted: false
          auctionComplete: false
          auctionCancelled: false
          auctionCancelledForWithdrawal: false
        }
        offeror: $wallet
        locked: true
      }
    ) {
      id
      offeror
      locked
      amount

      auction {
        version
        term {
          version
          purchaseTokenMeta {
            decimals
          }
          termRepoTokenMeta {
            id
            symbol
            name
            decimals
          }
          collateralTokensMeta {
            id
            symbol
            name
            decimals
          }
          repurchaseTimestamp
          redemptionTimestamp
        }
      }

      lastTransaction
    }
  }
`)
