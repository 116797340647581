import { useState } from 'react'
import VaultModalBase from '..'
import { useTermToast } from '../../../../../hooks/toasts'
import { termToastMessages } from '../../../../../helpers/toasts'

export const EditMarkupModal = ({
  currentDiscountRateMarkup,
  onSetDiscountRateMarkup,
  isOpen,
  onClose,
}: {
  currentDiscountRateMarkup?: string
  onSetDiscountRateMarkup: (markup: string) => Promise<void>
  isOpen: boolean
  onClose: () => void
}) => {
  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)
  return (
    <VaultModalBase
      isOpen={isOpen}
      title="Edit Markup"
      titleInput="Markup %"
      placeholder="0.000%"
      textButton="Save"
      contentCheckbox="I confirm the markup rate above is correct. Please contact the team if you are unsure."
      onClose={onClose}
      actionButton={async (markup) => {
        setSubmitting(true)
        termToast.pending(termToastMessages.editDiscountRateMarkup.pending())
        try {
          await onSetDiscountRateMarkup(markup)
          termToast.success(
            termToastMessages.editDiscountRateMarkup.success(markup)
          )
        } catch (error) {
          if ((error as Error).message.includes('user rejected transaction')) {
            termToast.dismissed()
          } else {
            termToast.failure(
              termToastMessages.editDiscountRateMarkup.failure()
            )
          }
        } finally {
          setSubmitting(false)
        }
      }}
      defaultValue={currentDiscountRateMarkup}
      hasRevert
      submitting={submitting}
    />
  )
}

export default EditMarkupModal
