import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { BigNumber, Contract } from 'ethers'
import { Address } from '../model'
import { useEffect, useMemo } from 'react'
import { captureException } from '@sentry/react'
import { IERC20MetadataUpgradeable } from '../../abi-generated/abi/v0.2.4/IERC20MetadataUpgradeable'
import ERC20ABI from '../../abi/v0.2.4/IERC20MetadataUpgradeable.json'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'

export type TokenAllowance = {
  token: Address
  owner: Address
  spender: Address
  termId: string
  decimals?: number
}

export function useAllowances(
  tokenAllowancesByChain:
    | { [chainId: string]: TokenAllowance[] | undefined }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): { [chainId: string]: { [termId: string]: BigNumber[] } } | null | undefined {
  const contractGroups = useMemo(() => {
    const groups: {
      [chainId: string]: {
        contract: IERC20MetadataUpgradeable
        owner: Address
        spender: Address
      }[]
    } = {}

    if (tokenAllowancesByChain) {
      Object.entries(tokenAllowancesByChain).forEach(
        ([chainId, allowances]) => {
          if (!allowances) return
          groups[chainId] = allowances.map((allowance) => ({
            token: allowance.token,
            contract: new Contract(
              allowance.token,
              ERC20ABI,
              provider
            ) as IERC20MetadataUpgradeable,
            owner: allowance.owner,
            spender: allowance.spender,
          }))
        }
      )
    }

    return groups
  }, [tokenAllowancesByChain, provider])

  const allowancesCalls: MultichainCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    Object.entries(contractGroups).forEach(([chainId, contracts]) => {
      // ensure that all contract calls have owner and spender defined
      const validContracts = contracts.filter(
        ({ owner, spender }) => owner && spender
      )
      if (validContracts.length > 0) {
        calls[chainId] = contracts.map(({ contract, owner, spender }) => ({
          contract,
          method: 'allowance',
          args: [owner, spender],
        }))
      }
    })

    return calls
  }, [contractGroups])

  const allowancesAndErrorsGrouped = useMultichainCalls(allowancesCalls)

  const allowances = useMemo(() => {
    const result: Record<string, BigNumber[]> = {}
    allowancesAndErrorsGrouped &&
      Object.entries(allowancesAndErrorsGrouped).forEach(
        ([chainId, allowancesAndErrors]) => {
          result[chainId] = allowancesAndErrors
            .filter(
              (allowanceOrError) =>
                allowanceOrError?.error === undefined &&
                allowanceOrError?.value !== undefined
            )
            .map((allowanceOrError) =>
              BigNumber.from(allowanceOrError?.value?.[0] ?? 0)
            )
        }
      )
    return result
  }, [allowancesAndErrorsGrouped])

  const allowanceErrors = useMemo(() => {
    const errors: Record<string, any[]> = {}
    allowancesAndErrorsGrouped &&
      Object.entries(allowancesAndErrorsGrouped).forEach(
        ([chainId, allowancesAndErrors]) => {
          const chainErrors = allowancesAndErrors
            .filter((allowanceOrError) => !!allowanceOrError?.error)
            .map((allowanceOrError) => allowanceOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    return errors
  }, [allowancesAndErrorsGrouped])

  useEffect(() => {
    Object.entries(allowanceErrors).forEach(([chainId, errors]) => {
      errors.forEach((error) => {
        console.error(
          `Error on chain ${chainId} fetching allowances - calls size is `,
          allowancesCalls[chainId]?.length
        )
        console.error(`Error on chain ${chainId} fetching allowances:`, error)
        captureException(error)
      })
    })
  }, [allowanceErrors, allowancesCalls])

  return useMemo(() => {
    if (!tokenAllowancesByChain || !allowances) {
      return undefined
    }

    const result: { [chainId: string]: { [termId: string]: BigNumber[] } } = {}

    let allDataLoaded = true

    Object.entries(allowances).forEach(([chainId, allowanceValues]) => {
      const tokenAllowances = tokenAllowancesByChain[chainId]

      // data still loading
      if (
        !tokenAllowances ||
        tokenAllowances.length !== allowanceValues.length
      ) {
        allDataLoaded = false
        return
      }

      const chainResult: { [termId: string]: BigNumber[] } = {}

      allowanceValues.forEach((value, index) => {
        const termId = tokenAllowances[index].termId
        if (!chainResult[termId]) {
          chainResult[termId] = []
        }
        chainResult[termId].push(value)
      })

      result[chainId] = chainResult
    })

    return allDataLoaded
      ? Object.keys(result).length > 0
        ? result
        : undefined
      : undefined
  }, [allowances, tokenAllowancesByChain])
}
