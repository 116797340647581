import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  // Link,
  Text,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FixedNumber } from 'ethers'
import { useMemo } from 'react'
import sparkles_icon from '../../../../assets/icons/sparkle.svg'
import SubmitApproveButton from '../../../../components/elements/SubmitApproveButton'
import Tooltip from '../../../../components/elements/Tooltip'
import { useTokenIcon } from '../../../../data/hooks/use-token-icons'
import {
  fixedToBigNumber,
  fixedToFormattedPercentage,
  formatFixed,
} from '../../../../helpers/conversions'
import { fixedCompare, multiply } from '../../../../helpers/math'
import {
  formatFixedToken,
  formatFixedUsd,
  parseUserInput,
} from '../../../../helpers/utils'
import { useTermToast } from '../../../../hooks/toasts'
import { calculatePurchaseTokensRequired, LendBoxProps } from '../../utils'
import { ApyTooltipLabel } from '../ApyTooltipLabel'
import { termToastMessages } from '../../../../helpers/toasts'
import { VStack } from '../../../../components/elements/Stack'
import xIcon from '../../../../assets/icons/x.svg'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'

const Balances = ({
  walletBalance,
  availableLiquidity,
  tokenSymbol,
  connected,
  selectedApy,
  isBalanceError,
  isLiquidityError,
  onClickWalletBalance,
  onClickAvailableLiquidity,
}: {
  walletBalance: FixedNumber
  availableLiquidity: FixedNumber
  tokenSymbol: string
  connected: boolean
  selectedApy: boolean
  isBalanceError: boolean
  isLiquidityError: boolean
  onClickWalletBalance: () => void
  onClickAvailableLiquidity: () => void
}) => (
  <Grid
    templateColumns="auto 1fr"
    columnGap={2}
    rowGap={0}
    textAlign="right"
    alignItems="center"
    color="gray.5"
  >
    {/* Your Balance */}
    {connected && walletBalance && (
      <>
        <Text
          as="span"
          variant="body-xs/normal"
          color={isBalanceError ? 'red.5' : undefined}
        >
          Your Balance:
        </Text>
        <Text
          as="span"
          variant="body-xs/semibold"
          color={isBalanceError ? 'red.5' : undefined}
          _hover={{
            textDecoration: 'underline',
          }}
          cursor="pointer"
          onClick={onClickWalletBalance}
        >
          {formatFixedToken(walletBalance, tokenSymbol, true)}
        </Text>
      </>
    )}

    {/* Available Liquidity */}
    {selectedApy && availableLiquidity && (
      <>
        <Text
          as="span"
          variant="body-xs/normal"
          color={isLiquidityError ? 'orange.5' : undefined}
        >
          Earn Limit:
        </Text>
        <Text
          as="span"
          variant="body-xs/semibold"
          color={isLiquidityError ? 'orange.5' : undefined}
          _hover={{
            textDecoration: 'underline',
          }}
          cursor="pointer"
          onClick={onClickAvailableLiquidity}
        >
          {formatFixedToken(availableLiquidity, tokenSymbol, true)}
        </Text>
      </>
    )}
  </Grid>
)

const ButtonRow = ({
  label,
  leftText,
  rightText,
}: {
  label: string
  leftText: React.ReactNode
  rightText: React.ReactNode
}) => (
  <HStack justify="space-between">
    <Text variant="body-sm/medium" color="gray.5.5">
      {label}
    </Text>
    <Box>
      <Text as="span" variant="body-sm/semibold" color="blue.9">
        {leftText}
      </Text>{' '}
      <Text as="span" variant="body-sm/normal" color="blue.9">
        {rightText}
      </Text>
    </Box>
  </HStack>
)

function LendBox({
  isDataLoading,
  tokenSymbol,
  tokenDecimals,
  termPointsMultiplier,
  externalProjects,
  onChange,
  value,
  isDisabled,
  connected,
  onConnectModalOpen,
  onTriggerHoverEffect,
  selectedApy,
  auctionClearingRate,
  apy,
  discountRateMarkup,
  lendAmountUsd,
  amountToReceive,
  walletBalance,
  availableLiquidity,
  redemptionTimestamp,
  purchaseTokenAllowance,
  isPurchasingMax,
  onLend,
  onMax,
  onKytCheck,
  onApprovePurchaseToken,
}: LendBoxProps) {
  const icon = useTokenIcon(tokenSymbol)
  const month = dayjs.unix(redemptionTimestamp).format('MMM DD')
  const time = dayjs.unix(redemptionTimestamp).format('ha')

  const currentTime = useCurrentTime()

  const daysUntilRedemption = dayjs
    .unix(redemptionTimestamp)
    .diff(dayjs(), 'days')

  const termToast = useTermToast()

  const formattedApy = multiply(
    apy,
    FixedNumber.fromString('100', `fixed128x18`)
  )
  const formattedAuctionClearingRate = multiply(
    auctionClearingRate,
    FixedNumber.fromString('100', `fixed128x18`)
  )

  const approvalAmount = useMemo(() => {
    // need to check purchase token approval amount for max purchase
    if (isPurchasingMax) {
      return fixedToBigNumber(
        calculatePurchaseTokensRequired(
          availableLiquidity,
          redemptionTimestamp,
          discountRateMarkup,
          auctionClearingRate,
          currentTime.unix(),
          tokenDecimals
        )
      )
    } else {
      return fixedToBigNumber(
        FixedNumber.fromString(
          value.replace(',', '') || '0'.toString(),
          `fixed128x${tokenDecimals ?? 18}`
        )
      )
    }
  }, [
    isPurchasingMax,
    availableLiquidity,
    redemptionTimestamp,
    discountRateMarkup,
    auctionClearingRate,
    currentTime,
    tokenDecimals,
    value,
  ])

  const pointsReceived = useMemo(() => {
    const points =
      lendAmountUsd && !lendAmountUsd.isZero() && daysUntilRedemption
        ? multiply(
            FixedNumber.fromString(daysUntilRedemption.toString()),
            lendAmountUsd
          )
        : FixedNumber.fromString('0')
    return points
  }, [daysUntilRedemption, lendAmountUsd])

  const isBalanceError =
    Number(value) > 0 &&
    fixedCompare(
      FixedNumber.fromString(value, `fixed128x${tokenDecimals}`),
      'gt',
      walletBalance
    )
  const isLiquidityError =
    amountToReceive && fixedCompare(amountToReceive, 'gt', availableLiquidity)
  const isError = isBalanceError || isLiquidityError

  const getMessage = () => {
    if (isBalanceError) {
      return 'Insufficient wallet balance'
    }
    if (isLiquidityError) {
      return 'Insufficient liquidity'
    }
    return formatFixedUsd(lendAmountUsd, false, true)
  }

  const getMessageColor = () => {
    if (isBalanceError) {
      return 'red.5'
    }
    if (isLiquidityError) {
      return 'orange.5'
    }
    return 'gray.5'
  }

  return (
    <Box
      w={{ base: '330px', '1xl': '397px' }}
      borderRadius={1.5}
      padding={5}
      bg="white"
    >
      <VStack align="left" gap={6}>
        <Box mt={2}>
          <Text variant="body-l/bold" mb={2}>
            Supply {tokenSymbol}{' '}
            {selectedApy && (
              <Text as="span" fontWeight={400}>
                at
              </Text>
            )}
          </Text>
          {selectedApy && (
            <HStack>
              <Text variant="body-2xl/semibold">
                {
                  fixedToFormattedPercentage(formattedApy, 3)
                    .formattedPercentage
                }
              </Text>
              <Tooltip
                noDelay
                placement="bottom"
                label={ApyTooltipLabel(
                  formattedApy,
                  formattedAuctionClearingRate,
                  externalProjects?.[0]?.label,
                  externalProjects?.[0]?.pointsMultiplier,
                  termPointsMultiplier
                )}
              >
                <Image boxSize={6} src={sparkles_icon} />
              </Tooltip>
            </HStack>
          )}
        </Box>
        <Box mt={'0!important'}>
          <Flex justifyContent="space-between" alignItems="flex-start" mb={1}>
            <Text
              variant="body-sm/medium"
              color="gray.6"
              lineHeight="1"
              m={0}
              p={0}
            >
              Amount
            </Text>
            <Balances
              walletBalance={walletBalance}
              availableLiquidity={availableLiquidity}
              tokenSymbol={tokenSymbol}
              connected={connected}
              selectedApy={selectedApy}
              isBalanceError={isBalanceError}
              isLiquidityError={isLiquidityError}
              onClickAvailableLiquidity={() => {
                if (!selectedApy) {
                  onTriggerHoverEffect()
                  return
                }
                if (fixedCompare(availableLiquidity, 'gt', walletBalance)) {
                  onChange(
                    parseUserInput(
                      walletBalance.toString(),
                      true,
                      tokenDecimals
                    )
                  )
                } else {
                  onChange(parseUserInput('', true, tokenDecimals))
                  onMax(true)
                }
              }}
              onClickWalletBalance={() => {
                if (!selectedApy) {
                  onTriggerHoverEffect()
                } else {
                  if (fixedCompare(walletBalance, 'gte', availableLiquidity)) {
                    onMax(true)
                  } else {
                    if (isPurchasingMax) {
                      onMax(false)
                    }
                    onChange(
                      parseUserInput(
                        walletBalance.toString(),
                        true,
                        tokenDecimals
                      )
                    )
                  }
                }
              }}
            />
          </Flex>

          {!isPurchasingMax ? (
            <>
              <InputGroup my={2}>
                {!isDataLoading ? (
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.500"
                    w={6}
                    children={<Image boxSize={6} src={icon} />}
                  />
                ) : undefined}
                <Input
                  aria-label={`Lend`}
                  variant="flushed"
                  type="text"
                  value={value}
                  isDisabled={isDisabled || !connected}
                  placeholder={'0'}
                  color="blue.9"
                  pl={!isDataLoading ? '32px' : '0'}
                  pr="2px"
                  borderBottom="1px solid"
                  borderBottomColor={isError ? 'red.5' : 'inherit'}
                  _focus={{
                    borderBottomColor: isError ? 'red.5' : 'blue.5',
                  }}
                  _invalid={{
                    borderBottomColor: 'red.5',
                  }}
                  onChange={(ev) =>
                    onChange(
                      parseUserInput(ev.target.value, true, tokenDecimals)
                    )
                  }
                />
              </InputGroup>
              <HStack justify="space-between">
                <Text variant="body-xs/normal" color={getMessageColor()}>
                  {getMessage()}
                </Text>
                <Button
                  variant="link"
                  isDisabled={isDataLoading}
                  onClick={() => {
                    if (!selectedApy) {
                      onTriggerHoverEffect()
                      return
                    }
                    if (fixedCompare(availableLiquidity, 'gt', walletBalance)) {
                      onChange(
                        parseUserInput(
                          walletBalance.toString(),
                          true,
                          tokenDecimals
                        )
                      )
                    } else {
                      onChange(parseUserInput('', true, tokenDecimals))
                      onMax(true)
                    }
                  }}
                  px={2}
                >
                  <Text variant="body-xs/semibold" color="blue.5">
                    MAX
                  </Text>
                </Button>
              </HStack>
            </>
          ) : (
            <Flex
              alignItems="center"
              my={2}
              width="100%"
              justifyContent="space-between"
            >
              <Text variant="body-sm/semibold" color="blue.9">
                You are buying the entire listing.
              </Text>
              <IconButton
                aria-label="Cancel MAX purchase"
                icon={<Image src={xIcon} mr="6px" boxSize="12px" />}
                onClick={() => {
                  onMax(false)
                  onChange(parseUserInput('', true, tokenDecimals))
                }}
                variant="ghost"
                size="sm"
                ml={2}
              />
            </Flex>
          )}
        </Box>

        {isDataLoading ? (
          <Button
            variant="tertiary"
            color="white"
            bg="blue.5"
            isDisabled
            leftIcon={<Spinner size="xs" />}
          />
        ) : !connected ? (
          <Button variant="primary" color="white" onClick={onConnectModalOpen}>
            Connect Wallet
          </Button>
        ) : (
          <>
            {!selectedApy && (
              <Button w="full" variant="primary" onClick={onTriggerHoverEffect}>
                Select a fixed APY on the left
              </Button>
            )}
            {selectedApy && (
              <Box>
                <Box bg="blue.0" pt={2} px={3} pb={3.5}>
                  <ButtonRow
                    label={"I'll receive"}
                    leftText={formatFixedToken(
                      amountToReceive,
                      tokenSymbol,
                      true
                    )}
                    rightText={tokenSymbol}
                  />
                  <ButtonRow
                    label={'At maturity on'}
                    leftText={month}
                    rightText={time}
                  />
                  <ButtonRow
                    label={'Term points earned:'}
                    leftText={formatFixed(pointsReceived, {
                      displayDecimals: 0,
                    })}
                    rightText={''}
                  />
                </Box>
                <SubmitApproveButton
                  step={1}
                  approvingCurrencySymbol={tokenSymbol}
                  onApprove={onApprovePurchaseToken}
                  onSubmit={async () => {
                    termToast.pending(termToastMessages.lending.pending())

                    try {
                      await onLend(value)
                      termToast.success(
                        termToastMessages.lending.success(
                          formatFixedToken(amountToReceive, tokenSymbol, true)
                        )
                      )
                    } catch (error) {
                      if (
                        (error as Error).message.includes(
                          'user rejected transaction'
                        )
                      ) {
                        termToast.dismissed()
                      } else {
                        termToast.failure(termToastMessages.lending.failure)
                      }
                    }
                  }}
                  isDisabled={
                    isBalanceError ||
                    isLiquidityError ||
                    (!isPurchasingMax && Number(value) === 0)
                  }
                  approvalAmount={approvalAmount}
                  submitApprovalAmount={true}
                  approvedAmount={purchaseTokenAllowance}
                  submitLabel={'Supply'}
                  onKytCheck={onKytCheck}
                />
              </Box>
            )}
          </>
        )}
      </VStack>
    </Box>
  )
}

export default LendBox
