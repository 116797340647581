import { useState } from 'react'
import VaultModalBase from '..'
import { Address } from '../../../../../data/model'
import { termToastMessages } from '../../../../../helpers/toasts'
import { useTermToast } from '../../../../../hooks/toasts'

export const UpdateTermControllerModal = ({
  currentTermController,
  onSetTermController,
  isOpen,
  onClose,
}: {
  currentTermController: Address
  onSetTermController: (newTermController: string) => Promise<void>
  isOpen: boolean
  onClose: () => void
}) => {
  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)
  return (
    <VaultModalBase
      isOpen={isOpen}
      title="Update Term Controller"
      titleInput="New Term Controller"
      placeholder="Enter the contract address"
      textButton="Update"
      contentCheckbox="I confirm the Term Controller address above is correct. Please contact the team if you are unsure."
      onClose={onClose}
      actionButton={async (newTermController) => {
        setSubmitting(true)
        termToast.pending(termToastMessages.setTermController.pending())
        try {
          await onSetTermController(newTermController)
          termToast.success(
            termToastMessages.setTermController.success(newTermController)
          )
        } catch (error) {
          if ((error as Error).message.includes('user rejected transaction')) {
            termToast.dismissed()
          } else {
            termToast.failure(termToastMessages.setTermController.failure())
          }
        } finally {
          setSubmitting(false)
        }
      }}
      defaultValue={currentTermController}
      isAddressInput
      submitting={submitting}
    />
  )
}

export default UpdateTermControllerModal
